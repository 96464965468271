import React, { useState, useEffect } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import Heading from '../../../../components/fragments/heading';
import InputBox from '../../../../components/fragments/input-box';
import SelectBox from '../../../../components/fragments/select-box';
import Button from '../../../../components/fragments/button';

import { StateActions } from '../../../../states/actions';

const formData = {
  productId: '',
  product_name: '',
  photo: '',
  featured: false,
  batch_num: '',
  expiry_date: '',
  production_date: '',
  survey_id: '',
  reward_id: '',
  user_id: '',
};

const AddBatch = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    clients,
    selectedClient,
    selectedProduct,
    selectedSurvey,
    selectedSurveyReward,
    currentClientProducts,
    currentClientSurveys,
    currentClientSurveyRewards,
  } = useSelector(state => state.state);
  const [data, setData] = useState(formData);

  useEffect(() => {
    if (selectedClient) {
      dispatch(StateActions.fetchClientProducts(selectedClient.id));
      dispatch(StateActions.fetchClientSurveys(selectedClient.id));
      dispatch(StateActions.fetchClientSurveyRewards(selectedClient.id));
    }
    // eslint-disable-next-line
  }, [selectedClient]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleCheckChange = e => {
    const { name, checked } = e.target;
    setData({ ...data, [name]: checked });
  };

  const handleSubmit = () => {
    let d = { ...data, user_id: selectedClient.id, productId: selectedProduct.id, survey_id: selectedSurvey.id, reward_id: selectedSurveyReward.id };
    dispatch(StateActions.addBatch(d)).then(_ => {
      setData(formData);
      history.push('/products/all-batches');
    });
  };

  return (
    <FadeIn
      className={`flex-1 flex flex-col justify-start
     h-full w-full space-y-4 overflow-auto flex-shrink-0`}
    >
      <Heading className={`text-gray-700`} title={`Add Batch`} size={3} />

      <FadeIn className='space-y-6 mt-4'>
        <SelectBox
          onValueChange={e => dispatch(StateActions.setSelectedClient(e.target.value))}
          options={['Select option', ...clients.map(d => d.company_rep + ' ( ' + d.username + ' )')]}
          variant={2}
          value={selectedClient ? selectedClient.company_rep + ' ( ' + selectedClient.username + ' )' : ''}
          label='Client'
        />

        <SelectBox
          onValueChange={e => dispatch(StateActions.setSelectedProduct(e.target.value))}
          options={['Select option', ...currentClientProducts.map(d => d.product_name)]}
          variant={2}
          value={selectedProduct ? selectedProduct.product_name : ''}
          label='Client Products'
        />

        <InputBox
          className={'w-full flex-1'}
          value={data.product_name}
          onValueChange={handleInputChange}
          name='product_name'
          variant={2}
          label='Product Name'
          placeholder='Product Name'
        />

        <InputBox
          className={'w-full flex-1'}
          value={data.production_date}
          onValueChange={handleInputChange}
          name='production_date'
          variant={2}
          type='date'
          label='Production Date'
          placeholder='Production Date'
        />

        <InputBox
          className={'w-full flex-1'}
          value={data.expiry_date}
          onValueChange={handleInputChange}
          name='expiry_date'
          variant={2}
          type='date'
          label='Expiry Date'
          placeholder='Expiry Date'
        />

        <InputBox
          className={'w-full flex-1'}
          value={data.batch_num}
          onValueChange={handleInputChange}
          name='batch_num'
          variant={2}
          type='number'
          label='Batch Number'
          placeholder='Batch Number'
        />

        <SelectBox
          onValueChange={e => dispatch(StateActions.setSelectedSurvey(e.target.value))}
          options={['Select option', ...currentClientSurveys.map(d => d.title)]}
          variant={2}
          value={selectedSurvey ? selectedSurvey.title : ''}
          label='Surveys'
        />

        <SelectBox
          onValueChange={e => dispatch(StateActions.setSelectedSurveyReward(e.target.value))}
          options={['Select option', ...currentClientSurveyRewards.map(d => d.reward_type + ' ( ' + d.reward_value + ' )')]}
          variant={2}
          value={selectedSurveyReward ? selectedSurveyReward.reward_type + ' ( ' + selectedSurveyReward.reward_value + ' )' : ''}
          label='Survey Rewards'
        />

        <label className='font-semibold flex items-center space-x-2'>
          <span>Featured</span>
          <input type='checkbox' checked={data.featured} onChange={handleCheckChange} name='featured' />
        </label>

        <Button
          className={`${
            !selectedClient ||
            // !selectedProduct ||
            !selectedSurvey ||
            !selectedSurveyReward ||
            !data.product_name ||
            !data.expiry_date ||
            !data.production_date ||
            !data.batch_num
              ? `pointer-events-none opacity-50`
              : ''
          } w-full relative top-8`}
          text={'Add Batch'}
          onClick={handleSubmit}
        />
      </FadeIn>
    </FadeIn>
  );
};

export default AddBatch;
