import React, { useState } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useDispatch, useSelector } from 'react-redux';

import Heading from '../../../../components/fragments/heading';
import { Tabs } from '../../../../components/fragments/tabs/tabs';
import InputBox from '../../../../components/fragments/input-box/input-box';
import SelectBox from '../../../../components/fragments/select-box/select-box';
import Button from '../../../../components/fragments/button/button';

import { StateActions } from '../../../../states/actions';

const GetPinStVa = () => {
  const user = useSelector(state => state.user);
  return (
    <FadeIn
      className={`flex-1 flex flex-col justify-start
     h-full w-full space-y-4 overflow-auto flex-shrink-0`}
    >
      <Heading className={`text-gray-700`} title={`Check Pin Validity & Update Status`} size={3} />

      <div className={'mt-2'}>
        {user?.membership_type !== 'admin' ? (
          <FadeIn>
            <CheckValidity />
          </FadeIn>
        ) : (
          <Tabs variant={2}>
            <FadeIn title='Check Validity'>
              <CheckValidity />
            </FadeIn>
            <FadeIn title='Update Status'>
              <UpdateStatus />
            </FadeIn>
          </Tabs>
        )}
      </div>
    </FadeIn>
  );
};

const CheckValidity = () => {
  const dispatch = useDispatch();
  const [pin, setPin] = useState('');

  const handleSubmit = () => {
    dispatch(StateActions.checkPinValidity({ pin_value: pin }));
  };

  return (
    <FadeIn className='space-y-6'>
      <InputBox type={'number'} value={pin} onValueChange={e => setPin(e.target.value)} variant={2} placeholder='Pin Value' />
      <Button className={`${!pin ? `pointer-events-none opacity-50` : ``} w-full relative top-2`} text={'Check'} onClick={handleSubmit} />
    </FadeIn>
  );
};

const UpdateStatus = () => {
  const dispatch = useDispatch();
  const [pin, setPin] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = () => {
    dispatch(StateActions.updatePinStatus({ pin_value: pin, status }));
  };

  return (
    <FadeIn className='space-y-6'>
      <InputBox type={'number'} value={pin} onValueChange={e => setPin(e.target.value)} variant={2} placeholder='Pin Value' />
      <SelectBox options={['Select status', '0', '1', '2']} variant={2} value={status} onValueChange={e => setStatus(e.target.value)} />
      <Button className={`${!pin ? `pointer-events-none opacity-50` : ``} w-full relative top-2`} text={'Update'} onClick={handleSubmit} />
    </FadeIn>
  );
};

export default GetPinStVa;
