import React from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector } from 'react-redux';

import Heading from '../../../../components/fragments/heading';

import ClientTable from '../../../../components/fragments/data-table';

const ClientsBase = () => {
  const { clients } = useSelector(state => state.state);
  return (
    <FadeIn
      className={`flex-1 flex flex-col justify-start
      h-full w-full space-y-4 overflow-auto flex-shrink-0 `}
    >
      <Heading className={`text-gray-700`} title={`All Clients`} size={3} />
      <ClientTable data={clients} perPage={6} />
    </FadeIn>
  );
};

export default ClientsBase;
