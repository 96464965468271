import React, { useState, useEffect } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { Pagination } from 'rsuite';
import { useDispatch } from 'react-redux';

import Heading from '../../../../components/fragments/heading';
import { Tabs } from '../../../../components/fragments/tabs/tabs';
import InputBox from '../../../../components/fragments/input-box/input-box';
import Button from '../../../../components/fragments/button/button';

import { StateActions } from '../../../../states/actions';

const GetPinSnSq = () => {
  return (
    <FadeIn
      className={`flex-1 flex flex-col justify-start
     h-full w-full space-y-4 overflow-auto flex-shrink-0`}
    >
      <Heading className={`text-gray-700`} title={`Check Pin by Serial Number or by Part of Pin`} size={3} />

      <div className={'mt-2'}>
        <Tabs variant={2}>
          <FadeIn title='Serial Number'>
            <CheckBySN />
          </FadeIn>
          <FadeIn title='Part of Pin'>
            <CheckByPart />
          </FadeIn>
        </Tabs>
      </div>
    </FadeIn>
  );
};

const CheckBySN = () => {
  const dispatch = useDispatch();
  const perPage = 5;
  const [activePage, setActivePage] = useState(1);
  const [pin, setPin] = useState('');
  const [result, setResult] = useState([]);
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    if (result) {
      const indexOfLast = activePage * perPage;
      const indexOfFirst = indexOfLast - perPage;
      const activeData = result.slice(indexOfFirst, indexOfLast);
      setAllData(activeData);
    } // eslint-disable-next-line
  }, [result, activePage]);

  const handleSubmit = () => {
    setResult([]);
    dispatch(StateActions.fetchPinBySn(pin)).then(res => {
      if (res.data) {
        setResult([{ product_name: res?.data?.SubProduct?.product_name, serial_number: res?.data?.id, pin: res?.data?.pin_value }]);
      }
    });
  };

  return (
    <FadeIn className='space-y-6'>
      <InputBox type={'number'} value={pin} onValueChange={e => setPin(e.target.value)} variant={2} placeholder='Pin Value' />
      <Button className={`${!pin ? `pointer-events-none opacity-50` : ``} w-full relative top-2`} text={'Check'} onClick={handleSubmit} />
      {allData.length > 0 && (
        <FadeIn className='relative top-6 space-y-6 divide-y'>
          {allData.map((item, i) => {
            return (
              <div className='pt-4 space-y-2' key={i}>
                <div className='flex items-center space-x-4'>
                  <span className='font-semibold'>Product Name</span>
                  <span>{item.product_name}</span>
                </div>
                <div className='flex items-center space-x-4'>
                  <span className='font-semibold'>Serial Number</span>
                  <span>{item.serial_number}</span>
                </div>
                <div className='flex items-center space-x-4'>
                  <span className='font-semibold'>Pin</span>
                  <span>{item.pin}</span>
                </div>
              </div>
            );
          })}
          <div className='my-6'>
            <Pagination
              prev
              last
              next
              first
              ellipsis={true}
              size='sm'
              total={result.length}
              limit={perPage}
              activePage={activePage}
              onChangePage={setActivePage}
            />
          </div>
        </FadeIn>
      )}
    </FadeIn>
  );
};

const CheckByPart = () => {
  const dispatch = useDispatch();
  const perPage = 5;
  const [activePage, setActivePage] = useState(1);
  const [pin, setPin] = useState('');
  const [result, setResult] = useState([]);
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    if (result) {
      const indexOfLast = activePage * perPage;
      const indexOfFirst = indexOfLast - perPage;
      const activeData = result.slice(indexOfFirst, indexOfLast);
      setAllData(activeData);
    } // eslint-disable-next-line
  }, [result, activePage]);

  const handleSubmit = () => {
    setResult([]);
    dispatch(StateActions.fetchPinByPart(pin)).then(res => {
      if (res.data) {
        setResult(
          res.data?.pins?.map(d => {
            return { product_name: d?.SubProduct?.product_name, serial_number: d?.id, pin: d?.pin_value };
          }),
        );
      }
    });
  };

  return (
    <FadeIn className='space-y-6'>
      <InputBox type={'number'} value={pin} onValueChange={e => setPin(e.target.value)} variant={2} placeholder='Pin Sequence' />
      <Button className={`${!pin ? `pointer-events-none opacity-50` : ``} w-full relative top-2`} text={'Check'} onClick={handleSubmit} />
      {allData.length > 0 && (
        <FadeIn className='relative top-6 space-y-6 divide-y'>
          {allData.map((item, i) => {
            return (
              <div className='pt-4 space-y-2' key={i}>
                <div className='flex items-center space-x-4'>
                  <span className='font-semibold'>Product Name</span>
                  <span>{item.product_name}</span>
                </div>
                <div className='flex items-center space-x-4'>
                  <span className='font-semibold'>Serial Number</span>
                  <span>{item.serial_number}</span>
                </div>
                <div className='flex items-center space-x-4'>
                  <span className='font-semibold'>Pin</span>
                  <span>{item.pin}</span>
                </div>
              </div>
            );
          })}
          <div className='my-6'>
            <Pagination
              prev
              last
              next
              first
              ellipsis={true}
              size='sm'
              total={result.length}
              limit={perPage}
              activePage={activePage}
              onChangePage={setActivePage}
            />
          </div>
        </FadeIn>
      )}
    </FadeIn>
  );
};

export default GetPinSnSq;
