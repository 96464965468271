import React, { useEffect, useState } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import Heading from '../../../../components/fragments/heading';
import InputBox from '../../../../components/fragments/input-box';
import SelectBox from '../../../../components/fragments/select-box';
import Button from '../../../../components/fragments/button';

import { StateActions } from '../../../../states/actions';

const ReassignPins = () => {
  const dispatch = useDispatch();
  const { clients, selectedClient, selectedProduct, selectedBatch, currentClientProducts, currentProductBatches } = useSelector(state => state.state);
  const [data, setData] = useState({ start: '', end: '' });

  useEffect(() => {
    if (selectedClient) {
      dispatch(StateActions.fetchClientProducts(selectedClient.id));
    }
    // eslint-disable-next-line
  }, [selectedClient]);

  useEffect(() => {
    if (selectedProduct) {
      dispatch(StateActions.fetchProductBatches(selectedProduct.id));
    }
    // eslint-disable-next-line
  }, [selectedProduct]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = () => {
    let d = { ...data, selectedUserId: selectedClient.id, selectedProdId: selectedProduct.id, selectedBatchId: selectedBatch.id };
    dispatch(StateActions.reassignPins(d)).then(_ => setData({ start: '', end: '' }));
  };

  return (
    <FadeIn
      className={`flex-1 flex flex-col justify-start
     h-full w-full space-y-4 overflow-auto flex-shrink-0`}
    >
      <Heading className={`text-gray-700`} title={`Reassign Pins`} size={3} />
      <FadeIn className='space-y-6 mt-4'>
        <SelectBox
          onValueChange={e => {
            console.log('clientsss', e.target.value);
            dispatch(StateActions.setSelectedClient(e.target.value));
          }}
          options={['Select option', ...clients.map(d => d.company_rep + ' ( ' + d.username + ' )')]}
          variant={2}
          value={selectedClient ? selectedClient.company_rep + ' ( ' + selectedClient.username + ' )' : ''}
          label='Client'
        />

        <SelectBox
          onValueChange={e => dispatch(StateActions.setSelectedProduct(e.target.value))}
          options={['Select option', ...currentClientProducts.map(d => d.product_name)]}
          variant={2}
          value={selectedProduct ? selectedProduct.product_name : ''}
          label='Client Products'
        />

        <SelectBox
          onValueChange={e => dispatch(StateActions.setSelectedBatch(e.target.value))}
          options={['Select option', ...currentProductBatches.map(d => d.product_name + ' ( ' + d.batch_num + ' )')]}
          variant={2}
          value={selectedBatch ? selectedBatch.product_name + ' ( ' + selectedBatch.batch_num + ' )' : ''}
          label='Product Batches'
        />

        <div className='flex justify-between items-center space-x-4'>
          <InputBox
            className={'w-full flex-1'}
            value={data.start}
            onValueChange={handleInputChange}
            name='start'
            variant={2}
            type='number'
            label='Start Index'
            placeholder='Start'
          />
          <div className='relative top-4'>-</div>
          <InputBox
            className={'w-full flex-1'}
            value={data.end}
            onValueChange={handleInputChange}
            name='end'
            variant={2}
            type='number'
            label='End Index'
            placeholder='End'
          />
        </div>
        <Button
          className={`${
            !selectedClient || !selectedProduct || !selectedBatch || !data.start || !data.end ? `pointer-events-none opacity-50` : ''
          } w-full relative top-8`}
          text={'Reassign'}
          onClick={handleSubmit}
        />
      </FadeIn>
    </FadeIn>
  );
};

export default ReassignPins;
