import React, { useEffect, useState } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector, useDispatch } from 'react-redux';

import Heading from '../../../../components/fragments/heading';
import { BiSlider } from 'react-icons/bi'; 

import BrandTable from '../../../../components/fragments/data-table';

import { StateActions } from '../../../../states/actions';

const ChekkitRms = () => {
  const perPage = 6; 
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(1);  
  const dispatch = useDispatch();
  const { all_chekkit_reports } = useSelector(state => state.state);
  const [searchInput, setSearchInput] = useState("");
  const [selectedRowIds, setSelectedRowIds] = useState([]); // Updated prop name
  const isBulkActionsActive = selectedRowIds.length > 0;

  
  console.log('totalCount', totalCount)
  console.log('setActivePage', setActivePage)

  const bulkData = selectedRowIds.map(slugValue => ({
    slug: slugValue,
    comment: ''
  }));

  // const JSONBulkData = JSON.stringify(bulkData);

  const handleBulkApprove = () => {
    const JSONBulkData = JSON.stringify(bulkData);

    dispatch(StateActions.bulkApprove(JSONBulkData))
  };

  const handleSelectedRowIdsChange = (newSelectedRowIds) => {
    setSelectedRowIds(newSelectedRowIds); // Update selectedRowIds in the parent component
    // console.log('Selected row ids in NafdacRms:', newSelectedRowIds);
  };

 


  // Continue here
  const handleBulkReject = () => {
    dispatch(StateActions.bulkReject(bulkData))
  };

  const handleChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  useEffect(() => {
    dispatch(StateActions.fetchAllChekkitReports(activePage, perPage)).then(res => {
      setTotalCount(res?.data?.metadata?.totalItems);
    });
  }, [activePage, dispatch]);


   
  if (searchInput.length > 0) {
    all_chekkit_reports.filter((report) => {
      return report.product_name.match(searchInput);
  });
  }
 
    const filteredReports =
    searchInput === ""
      ? all_chekkit_reports
      : all_chekkit_reports.filter((item) =>
          item.product_name.toLowerCase().includes(searchInput.toLowerCase().replace(/\s+/g, ''))
      );

    const total = filteredReports.length 

      // console.log('filteredreports', filteredReports)

  return (
    <> 
      <FadeIn
        className={`flex-1 flex flex-col justify-start
       h-full w-full space-y-4 overflow-auto flex-shrink-0`}
      >
        <div className='flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4 justify-between items-center'>
            <Heading className={`text-black font-medium capitalize`} title={`report Management system`} size={3} />
            <div className='flex flex-row space-x-4'>
                <button className={`capitalize rounded-lg px-6 py-3 ${isBulkActionsActive ? 'text-blue-900 border-2 border-blue-900 bg-white' : 'bg-white text-black capitalize rounded-lg px-6 py-3 border-2 border-gray-400'} ${isBulkActionsActive ? '' : 'opacity-50 cursor-not-allowed'}`}   disabled={!isBulkActionsActive} onClick={handleBulkReject}  style={{ minHeight: '24px', minWidth: '102px' }}>bulk reject</button>
                <button className={`capitalize rounded-lg px-6 py-3 ${isBulkActionsActive ? 'bg-blue-900 border-2 border-blue-900 text-white' : 'bg-gray-500 text-white capitalize rounded-lg px-6 py-3 '} ${isBulkActionsActive ? '' : 'opacity-50 cursor-not-allowed'}`}   onClick={handleBulkApprove}  style={{ minHeight: '24px', minWidth: '102px',  }}>bulk approve</button>
            </div>
        </div>
        <div>
          <p>Overview of all report that you are currently managing.</p>
        </div>
        
        <div className='flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4 justify-between items-center'>
            <div className={`space-y-2 w-full`}>
                <div className={`w-full p-6 rounded-md shadow bg-white text-gray-600 flex items-center space-x-4 overflow-hidden`}>
                    <input
                      type="text"
                      className={'bg-transparent focus:outline-none w-full h-full'}
                      placeholder="Search for Name"
                      onChange={handleChange}
                      value={searchInput} 
                    />
                </div>
            </div>
            <div className='flex space-x-2 items-center p-2 px-4 rounded-md border cursor-pointer'>
                  <span>Filter</span>
                  <BiSlider />
            </div>
        </div>


        <div className='my-3' style={{ color: '#1D2433A6', fontSize: '14px', fontWeight: 400 }}>
          Total {total} report
        </div>

        <BrandTable 
        data={filteredReports}  
        variant='chekkit-r-m-s' perPage={10}  onSelectedRowIdsChange={handleSelectedRowIdsChange} />
      </FadeIn>
    </>
  );
};

export default ChekkitRms;
