import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as LogoSingle } from '../../../assets/logo-single.svg';
import { useSelector } from 'react-redux';
import routes from '../../../routes';

const Logo = ({ size }) => {
  const history = useHistory();
  const user = useSelector(state => state.user);
  const [path, setPath] = useState('/');

  useEffect(() => {
    if (user?.access_token) {
      if (user?.membership_type === 'agent') {
        let r = routes.filter(d => d.name === 'Pins');
        setPath(r[0].href);
      } else if (user?.membership_type === 'field-agent') {
        let r = routes.filter(d => d.name === 'Validate Reward');
        setPath(r[0].href);
      } else {
        setPath(routes[0].href);
      }
    } else {
      history.push('/');
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <div onClick={() => history.push(path)} className={`cursor-pointer flex justify-center items-center relative`}>
      <LogoSingle width={size} />
      <div className='absolute top-10 right-0 font-extralight text-brand_blue'>Admin</div>
    </div>
  );
};

export default Logo;
