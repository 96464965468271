import React, { useState, useEffect } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { Route, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Heading from '../../../../components/fragments/heading';
import InputBox from '../../../../components/fragments/input-box';
import Button from '../../../../components/fragments/button';
import Dialog from '../../../../components/fragments/dialog';

import RewardNotFound from '../../../../assets/reward-not-found.png';

import RewardTable from '../../../../components/fragments/data-table';

import { StateActions } from '../../../../states/actions';

const ValidateReward = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [phrase, setPhrase] = useState('');
  const [open, setOpen] = useState(false);
  const [result, setResult] = useState();
  const [selectedReward, setSelectedRewarrd] = useState();

  useEffect(() => {
    if (result) {
      history.push('/validate-reward/reward-found');
    } else {
      setPhrase('');
      history.push('/validate-reward');
    }
    // eslint-disable-next-line
  }, [result]);

  const searchNumber = () => {
    dispatch(StateActions.validateWinner(phrase)).then(res => {
      if (res.data) {
        if (res.data.filter) {
          setResult(res.data);
        } else {
          setResult([res.data]);
        }
      } else {
        history.push('/validate-reward/reward-not-found');
      }
    });
  };

  const redeemReward = () => {
    dispatch(StateActions.redeemReward(selectedReward.id, { pin: selectedReward.pin, phoneNumber: selectedReward.phone_number })).then(res => {
      setOpen(true);
    });
  };

  return (
    <FadeIn
      className={`flex-1 flex flex-col justify-start
      h-full w-full overflow-auto flex-shrink-0 `}
    >
      <Route exact path='/validate-reward'>
        <FadeIn className='space-y-4'>
          <Heading className={`text-gray-700`} title={`Validate Reward`} size={3} />
          <div className={`text-gray-600`}>Welcome, kindly enter the phone number you want to validate</div>
          <div className='flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:items-center lg:space-x-4 w-full max-w-2xl'>
            <InputBox
              className={'flex-1 w-full'}
              placeholder={'09030303030'}
              variant={2}
              value={phrase}
              onValueChange={e => setPhrase(e.target.value)}
              type='text'
              maxLength={12}
            />
            <Button className={`${!phrase || phrase.length < 11 ? 'pointer-events-none opacity-50' : ''} w-48`} onClick={searchNumber} text='Search' />
          </div>
        </FadeIn>
      </Route>

      <Route exact path='/validate-reward/reward-found'>
        {result && result.length > 0 && (
          <FadeIn className='space-y-4'>
            <Heading className={`text-gray-700`} title={`Reward Found`} size={3} />
            <div className={`text-gray-600`}>Below is the result</div>
            <RewardTable data={result} variant='all-reward-log' totalCount={result?.length} perPage={result?.length} onSelected={setSelectedRewarrd} />
            <div className='flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:items-center lg:space-x-4 w-full justify-between'>
              <div className={`text-gray-600 font-semibold`}>Are the details correct?</div>
              <Button className={`${!selectedReward ? 'pointer-events-none opacity-50' : ''}`} text='Redeem Reward' onClick={redeemReward} />
            </div>
          </FadeIn>
        )}
      </Route>

      <Route exact path='/validate-reward/reward-not-found'>
        <FadeIn className='space-y-4'>
          <Heading className={`text-gray-700`} title={`Reward Not Found`} size={3} />
          <div className={`text-gray-600`}>Ooops! data could not be fetched</div>
          <img src={RewardNotFound} alt='Reward not found' />
          <Button text='Return To Dashboard' onClick={() => setResult('')} />
        </FadeIn>
      </Route>

      <Dialog open={open} setOpen={setOpen} callBack={setResult} variant='reward-claimed' />
    </FadeIn>
  );
};

export default ValidateReward;
