import React, { useEffect, useState } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector, useDispatch } from 'react-redux';
import { CSVLink } from 'react-csv';

import Heading from '../../../../components/fragments/heading';
import InputBox from '../../../../components/fragments/input-box';
import SelectBox from '../../../../components/fragments/select-box';
import Button from '../../../../components/fragments/button';

import { StateActions } from '../../../../states/actions';

const Report = () => {
  const dispatch = useDispatch();
  const { clients, selectedClient, selectedProduct, selectedBatch, currentClientProducts, currentProductBatches } = useSelector(state => state.state);
  const [data, setData] = useState({ from: '', to: '' });
  const [report, setReport] = useState();
  const [quest, setQuest] = useState();

  useEffect(() => {
    if (selectedClient) {
      dispatch(StateActions.fetchClientProducts(selectedClient.id));
    }
    // eslint-disable-next-line
  }, [selectedClient]);

  useEffect(() => {
    if (selectedProduct) {
      dispatch(StateActions.fetchProductBatches(selectedProduct.id));
    }
    // eslint-disable-next-line
  }, [selectedProduct]);

  useEffect(() => {
    if (report) {
      let x = report.quest_response.map((d, i) => {
        d.option_count.map((e, j) => {
          d[`answer${j + 1}`] = e.answer;
          d[`count${j + 1}`] = e.count;
          return e;
        });
        return d;
      });
      setQuest(x);
    }
    // eslint-disable-next-line
  }, [report]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = () => {
    dispatch(StateActions.generateReport(data.from, data.to, selectedBatch.id)).then(res => {
      setData({ from: '', to: '' });
      setReport(res.data);
    });
  };

  return (
    <FadeIn
      className={`flex-1 flex flex-col justify-start
     h-full w-full space-y-4 overflow-auto flex-shrink-0`}
    >
      <Heading className={`text-gray-700`} title={`Report`} size={3} />
      <FadeIn className='space-y-6 mt-4'>
        <SelectBox
          onValueChange={e => dispatch(StateActions.setSelectedClient(e.target.value))}
          options={['Select option', ...clients.map(d => d.company_rep + ' ( ' + d.username + ' )')]}
          variant={2}
          value={selectedClient ? selectedClient.company_rep + ' ( ' + selectedClient.username + ' )' : ''}
          label='Client'
        />

        <SelectBox
          onValueChange={e => dispatch(StateActions.setSelectedProduct(e.target.value))}
          options={['Select option', ...currentClientProducts.map(d => d.product_name)]}
          variant={2}
          value={selectedProduct ? selectedProduct.product_name : ''}
          label='Client Products'
        />

        <SelectBox
          onValueChange={e => dispatch(StateActions.setSelectedBatch(e.target.value))}
          options={['Select option', ...currentProductBatches.map(d => d.product_name + ' ( ' + d.batch_num + ' )')]}
          variant={2}
          value={selectedBatch ? selectedBatch.product_name + ' ( ' + selectedBatch.batch_num + ' )' : ''}
          label='Product Batches'
        />

        <div className='flex justify-between items-center space-x-4'>
          <InputBox
            className={'w-full flex-1'}
            value={data.from}
            onValueChange={handleInputChange}
            name='from'
            variant={2}
            type='date'
            label='From Date'
            placeholder='From Date'
          />
          <div className='relative top-4'>-</div>
          <InputBox
            className={'w-full flex-1'}
            value={data.to}
            onValueChange={handleInputChange}
            name='to'
            variant={2}
            type='date'
            label='To Date'
            placeholder='To Date'
          />
        </div>
        <Button
          className={`${
            !selectedClient || !selectedProduct || !selectedBatch || !data.from || !data.to ? `pointer-events-none opacity-50` : ''
          } w-full relative top-4`}
          text={'Generate Report'}
          onClick={handleSubmit}
        />
      </FadeIn>

      {report && quest && (
        <div className='flex flex-col space-y-6 relative top-10'>
          <CSVLink
            className='underline text-brand_blue'
            headers={[
              { label: 'PRODUCT NAME', key: 'product_name' },
              { label: 'BATCH NUMBER', key: 'batch_num' },
              { label: 'DATE', key: 'date' },
              { label: 'COUNT', key: 'count' },
            ]}
            data={report.stats.map(d => {
              d.product_name = report.product.product_name;
              d.batch_num = report.batch.batch_num;
              return d;
            })}
          >
            Download Scan Report
          </CSVLink>
          {quest.length > 0 && (
            <CSVLink
              className='underline text-brand_blue'
              headers={[
                { label: 'PRODUCT NAME', key: 'product_name' },
                { label: 'BATCH NUMBER', key: 'batch_num' },
                { label: 'QUESTION', key: 'question' },
                { label: `ANSWER 1`, key: `answer1` },
                { label: `COUNT 1`, key: `count1` },
                { label: `ANSWER 2`, key: `answer2` },
                { label: `COUNT 2`, key: `count2` },
                { label: `ANSWER 3`, key: `answer3` },
                { label: `COUNT 3`, key: `count3` },
                { label: `ANSWER 4`, key: `answer4` },
                { label: `COUNT 4`, key: `count4` },
              ]}
              data={quest.map(d => {
                d.product_name = report.product.product_name;
                d.batch_num = report.batch.batch_num;
                return d;
              })}
            >
              Download Survey Report
            </CSVLink>
          )}
          <CSVLink
            className='underline text-brand_blue'
            headers={[
              { label: 'PRODUCT NAME', key: 'product_name' },
              { label: 'BATCH NUMBER', key: 'batch_num' },
              { label: 'DATE', key: 'date' },
              { label: 'COUNT', key: 'count' },
            ]}
            data={report.reward_count.map(d => {
              d.product_name = report.product.product_name;
              d.batch_num = report.batch.batch_num;
              return d;
            })}
          >
            Download Reward Report
          </CSVLink>
        </div>
      )}
    </FadeIn>
  );
};

export default Report;
