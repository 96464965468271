import React, { useState, useEffect } from 'react';
import { MdDelete } from 'react-icons/md';
import FadeIn from 'react-fade-in/lib/FadeIn';

import InputBox from '../input-box';

const OptionList = ({ data, onDataChange }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (data) {
      if (typeof data === 'string') {
        setOptions(JSON.parse(data));
      } else {
        setOptions(data);
      }
    }
  }, [data]);

  useEffect(() => {
    if (options) {
      onDataChange(options);
    } // eslint-disable-next-line
  }, [options]);

  const onOptionChange = (index, item) => {
    const optionsList = options.map((option, idx) => {
      if (index === idx) {
        return item;
      }
      return option;
    });
    setOptions(optionsList);
  };

  const addOption = () => {
    setOptions([...options, { text: '' }]);
  };

  const deleteOption = idx => {
    const optionsList = options.filter((d, i) => {
      return i !== idx;
    });
    setOptions(optionsList);
  };

  return (
    <div className={`w-full flex flex-col items-center space-y-6`}>
      <div className={`w-full text-gray-500 font-semibold`}>Options</div>
      <FadeIn className={`w-full space-y-6`}>
        {options.length > 0 &&
          options.map((option, idx) => {
            return <Item key={idx} item={option} idx={idx} onOptionChange={onOptionChange} onDelete={deleteOption} />;
          })}
      </FadeIn>
      <div className={`flex items-center justify-end w-full`}>
        <div
          onClick={addOption}
          className={`text-blue font-medium text-sm
              bg-blue-200 hover:bg-blue-300 cursor-pointer
           rounded-md p-2`}
        >
          + Add Option
        </div>
      </div>
    </div>
  );
};

const Item = ({ item, idx, onOptionChange, onDelete }) => {
  const [option, setOption] = useState();

  useEffect(() => {
    if (item) {
      setOption(item);
    }
  }, [item]);

  useEffect(() => {
    if (option) {
      onOptionChange(idx, option);
    } // eslint-disable-next-line
  }, [option]);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setOption({ ...option, [name]: value });
  };

  return (
    <div className={`flex items-center space-x-4 p-2`}>
      <InputBox placeholder={`Type option here...`} variant={2} value={option?.text} name={`text`} onValueChange={handleInputChange} />
      {idx > 1 && <MdDelete onClick={() => onDelete(idx)} className={`text-gray-300 hover:text-gray-600 cursor-pointer`} size={24} />}
    </div>
  );
};

export default OptionList;
