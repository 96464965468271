import React, { useEffect, useState } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Heading from '../../../../components/fragments/heading';
import InputBox from '../../../../components/fragments/input-box';
import Button from '../../../../components/fragments/button';
import DragDropZone from '../../../../components/fragments/drag-drop-zone';
import Text from '../../../../components/fragments/text';

import { ReactComponent as DownloadIcon } from '../../../../assets/download.svg';
import CsvTemplate from '../../../../assets/csv-template.csv';

import { StateActions, ResponseActions } from '../../../../states/actions';

const CreateGroup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [data, setData] = useState({ name: '' });
  const [file, setFile] = useState('');
  const [uploadList, setUploadList] = useState();

  useEffect(() => {
    if (file) {
      const reader = new FileReader();

      reader.onload = function (e) {
        const text = e.target.result;
        processCSV(text);
      };

      reader.readAsText(file);
    } // eslint-disable-next-line
  }, [file]);

  const processCSV = (str, delim = ',') => {
    let hds = str.slice(0, str.indexOf('\n')).split(delim);

    hds = hds.map(d => {
      let r = d.replaceAll(/(\r\n|\n|\r)/gm, '');
      return r;
    });

    const headers = hds.map(h => h.split(' ').join('_').toLowerCase());

    let rows = str.slice(str.indexOf('\n') + 1).split('\n');

    rows = rows
      .map(d => {
        let r = d.replaceAll(/(\r\n|\n|\r)/gm, '');
        return r;
      })
      .filter(d => {
        return d.replaceAll(',', '');
      })
      .filter(d => {
        return d.split(',').every(e => e);
      });

    const newArray = rows
      .filter(n => n)
      .map(row => {
        const values = row.split(delim);
        const eachObject = headers.reduce((obj, header, i) => {
          if (header === 'name') {
            obj[header] = values[i];
            return obj;
          }

          if (header === 'phone_number') {
            obj[header] = values[i];
            return obj;
          }

          obj[header] = values[i].replaceAll(/(\r\n|\n|\r)/gm, '');
          return obj;
        }, {});
        return eachObject;
      });
    if (newArray && newArray.length > 0 && newArray.every(d => d?.name && d?.phone_number)) {
      setUploadList(newArray);
    } else {
      setUploadList(null);
      dispatch(ResponseActions.notify({ title: 'Incomplete', message: 'Name and/or phone number missing', type: 'default' }));
    }
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = () => {
    dispatch(StateActions.createGroup(data)).then(res => {
      if (res) {
        let fullData = uploadList.map(d => {
          d.group_id = res?.data?.id + '';
          return d;
        });
        dispatch(StateActions.bulkUploadContacts(fullData)).then(res => {
          if (res) {
            history.push('/retargeting/groups');
          }
        });
      }
    });
  };

  return (
    <FadeIn
      className={`flex-1 flex flex-col justify-start
     h-full w-full space-y-4 overflow-auto flex-shrink-0`}
    >
      <Heading className={`text-gray-700`} title={`Create Group`} size={3} />
      <FadeIn className='space-y-6 mt-4'>
        <InputBox
          className={'w-full flex-1'}
          value={data.name}
          onValueChange={handleInputChange}
          name='name'
          variant={2}
          label='Group Name'
          placeholder='Group Name'
        />

        <div className={`flex flex-col space-y-2`}>
          <div className={`font-semibold`}>Upload Contacts CSV</div>
          <DragDropZone file={file} onChange={setFile}>
            <Text className={`text-lg font-medium text-brand_blue underline`} value={file && file.name ? file.name : `Select CSV file`} />
            <Text className={`text-sm text-gray-400`} value={`or drop your file here`} />
            <Text className={`text-sm text-gray-400`} value={`(.csv file)`} />
          </DragDropZone>
          <div className={`flex items-center space-x-2`}>
            <DownloadIcon />
            <a
              href={CsvTemplate}
              className={`flex items-center text-brand_blue hover:text-brand_blue font-semibold text-sm ml-4 underline cursor-pointer`}
              download={`CSV Template`}
            >
              Download CSV Template
            </a>
          </div>
        </div>

        <Button
          className={`${!data.name || !uploadList ? `pointer-events-none opacity-50` : ''} w-full relative top-8`}
          text={'Create Group'}
          onClick={handleSubmit}
        />
      </FadeIn>
    </FadeIn>
  );
};

export default CreateGroup;
