import React from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector } from 'react-redux';

import Heading from '../../../../components/fragments/heading';

import LabelRequestTable from '../../../../components/fragments/data-table';

const LabelModuleBase = () => {
  const { labelRequests } = useSelector(state => state.state);
  return (
    <FadeIn
      className={`flex-1 flex flex-col justify-start
      h-full w-full space-y-4 overflow-auto flex-shrink-0 `}
    >
      <Heading className={`text-gray-700`} title={`Label Request`} size={3} />
      <LabelRequestTable data={labelRequests} perPage={6} variant='label-request' />
    </FadeIn>
  ); 
};

export default LabelModuleBase;
