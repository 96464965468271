import React, { useState, useEffect } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Heading from '../../../../components/fragments/heading';
import InputBox from '../../../../components/fragments/input-box';
import SelectBox from '../../../../components/fragments/select-box/select-box';
import Button from '../../../../components/fragments/button';

import { StateActions } from '../../../../states/actions';

const formData = {
  full_name: '',
  first_name: '',
  last_name: '',
  username: '',
  membership_type: '',
  email: '',
  phone_number: '',
  location: '',
};

const EditAgent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { agents } = useSelector(state => state.state);
  const [data, setData] = useState(formData);

  useEffect(() => {
    if (id && agents.length > 0) {
      let agent = agents.find(d => d.id === Number(id));
      setData(agent);
    }
    // eslint-disable-next-line
  }, [id, agents]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = () => {
    dispatch(StateActions.updateAgent(data.id, data)).then(_ => {
      setData(formData);
      history.push('/agents/all-agents');
    });
  };

  return (
    <FadeIn
      className={`flex-1 flex flex-col justify-start
     h-full w-full space-y-4 overflow-auto flex-shrink-0`}
    >
      <Heading className={`text-gray-700`} title={`Edit Agent`} size={3} />

      <FadeIn className='space-y-6 mt-4'>
        {data.full_name && (
          <InputBox
            className={'w-full flex-1'}
            value={data.full_name}
            onValueChange={handleInputChange}
            name='full_name'
            variant={2}
            label='Full Name'
            placeholder='Full Name'
          />
        )}

        <InputBox
          className={'w-full flex-1'}
          value={data.first_name}
          onValueChange={handleInputChange}
          name='first_name'
          variant={2}
          label='First Name'
          placeholder='First Name'
        />

        <InputBox
          className={'w-full flex-1'}
          value={data.last_name}
          onValueChange={handleInputChange}
          name='last_name'
          variant={2}
          label='Last Name'
          placeholder='Last Name'
        />

        <InputBox
          className={'w-full flex-1'}
          value={data.username}
          onValueChange={handleInputChange}
          name='username'
          variant={2}
          label='Username'
          placeholder='Username'
        />

        <InputBox
          className={'w-full flex-1'}
          value={data.email}
          onValueChange={handleInputChange}
          name='email'
          type={'email'}
          variant={2}
          label='Email'
          placeholder='Email'
        />

        <InputBox
          className={'w-full flex-1'}
          value={data.phone_number}
          onValueChange={handleInputChange}
          name='phone_number'
          variant={2}
          label='Phone Number'
          placeholder='Phone Number'
        />

        {data.location && (
          <InputBox
            className={'w-full flex-1'}
            value={data.location}
            onValueChange={handleInputChange}
            name='location'
            variant={2}
            label='Location'
            placeholder='Location'
          />
        )}

        <SelectBox
          className={'w-full flex-1'}
          value={data.membership_type}
          options={['Select option', 'agent', 'field-agent']}
          onValueChange={handleInputChange}
          name='membership_type'
          variant={2}
          label='Membership Type'
        />

        <Button
          className={`${
            !data.first_name || !data.last_name || !data.username || !data.email || !data.phone_number ? `pointer-events-none opacity-50` : ''
          } w-full relative top-8`}
          text={'Edit Agent'}
          onClick={handleSubmit}
        />
      </FadeIn>
    </FadeIn>
  );
};

export default EditAgent;
