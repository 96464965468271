import axios from 'axios';
import store from './states/store';
import { apiUrl, apiUrlAuth, apiUrlNew } from './config';
axios.defaults.headers.common['source'] = 'web';

const http = () => {
  let token = store.getState().user?.access_token;

  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  return axios.create({
    baseURL: apiUrl,
  });
};

const httpRms = () => {
  let token = store.getState().user?.access_token;

  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  return axios.create({
    baseURL: apiUrlNew,
  });
};

const httpAuth = () => {
  return axios.create({
    baseURL: apiUrlAuth,
  });
};
export { httpAuth };
export default http;
export { httpRms }
