import { CREATE_ADMIN, SIGNIN, UPDATE_USER, SIGNOUT } from '../type';

const initialState = {};

const userReducer = (user = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_ADMIN:
      return user;
    case SIGNIN:
      return payload;
    case UPDATE_USER:
      return { ...user, ...payload };
    case SIGNOUT:
      return initialState;
    default:
      return user;
  }
};

export default userReducer;
