import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { MdMenu, MdClose } from 'react-icons/md';
import { Link, useLocation, useHistory, Route } from 'react-router-dom';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useDispatch, useSelector } from 'react-redux';

import routes from '../../routes';
import { UserActions } from '../../states/actions';

import Logo from '../fragments/logo';

import Avatar from '../../assets/avatar.jpg';
import { ReactComponent as OutIcon } from '../../assets/logout.svg';

import Content from './content';
import Heading from '../fragments/heading';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Base = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [navItems, setNavItems] = useState(routes);

  const signout = () => {
    dispatch(UserActions.signOut());
    localStorage.removeItem('user');
    history.push('/signin');
  };

  useEffect(() => {
    console.log('this user', user);
    if (!user?.access_token) history.push('/signIn');
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (user?.membership_type === 'agent') {
      setNavItems(
        navItems
          .filter(d => d.name === 'Pins')
          .map(item => {
            item.current = item.name
              .replaceAll(' ', '-')
              .replaceAll('&', '')
              .replaceAll(' ', '')
              .toLocaleLowerCase()
              .includes(location.pathname.split('/')[1].toLocaleLowerCase());
            return item;
          }),
      );
    } else if (user?.membership_type === 'field-agent') {
      setNavItems(
        navItems
          .filter(d => d.name === 'Validate Reward')
          .map(item => {
            item.current = item.name
              .replaceAll(' ', '-')
              .replaceAll('&', '')
              .replaceAll(' ', '')
              .toLocaleLowerCase()
              .includes(location.pathname.split('/')[1].toLocaleLowerCase());
            return item;
          }),
      );
    } else {
      setNavItems(
        navItems.map(item => {
          item.current = item.name
            .replaceAll(' ', '-')
            .replaceAll('&', '')
            .replaceAll(' ', '')
            .toLocaleLowerCase()
            .includes(location.pathname.split('/')[1].toLocaleLowerCase());
          return item;
        }),
      );
    }

    setSidebarOpen(false);
    // eslint-disable-next-line
  }, [location]);

  return (
    <div className='h-screen flex overflow-hidden'>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as='div' className='fixed inset-0 flex z-40 md:hidden' onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-600 bg-opacity-75' />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='-translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='-translate-x-full'
          >
            <div className='relative flex-1 flex flex-col max-w-xs w-full bg-blue-50'>
              <Transition.Child
                as={Fragment}
                enter='ease-in-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in-out duration-300'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='absolute top-0 right-0 -mr-12 pt-2'>
                  <button
                    type='button'
                    className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className='sr-only'>Close sidebar</span>
                    <MdClose className='h-6 w-6 text-white' aria-hidden='true' />
                  </button>
                </div>
              </Transition.Child>
              <div className='flex-1 flex flex-col justify-between py-6 overflow-y-auto'>
                <div className='space-y-6'>
                  <div className='flex items-center flex-shrink-0 px-6'>
                    <Logo size={150} />
                  </div>
                  <FadeIn className='bg-blue-50 flex flex-col justify-between'>
                    {navItems.map(item => (
                      <Link
                        style={{ textDecoration: 'none' }}
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current
                            ? 'bg-brand_blue_light text-white focus:text-white hover:text-white'
                            : 'text-gray-700 hover:bg-brand_blue_light hover:opacity-25 focus:text-white hover:text-white',
                          'group flex items-center p-6 text-xs',
                        )}
                      >
                        <item.icon
                          className={classNames(item.current ? 'text-white ' : 'text-gray-700 group-hover:text-white', 'mr-4 flex-shrink-0 h-4 w-4')}
                          aria-hidden='true'
                        />
                        {item.name}
                      </Link>
                    ))}
                  </FadeIn>
                </div>
                <FadeIn className=''>
                  <span
                    onClick={signout}
                    className={classNames(
                      'text-gray-700 hover:bg-brand_blue_light hover:opacity-25 hover:text-white',
                      'group flex items-center p-6 text-xs cursor-pointer',
                    )}
                  >
                    <OutIcon className={classNames('text-gray-700 group-hover:text-white', 'mr-4 flex-shrink-0 h-4 w-4')} aria-hidden='true' />
                    Logout
                  </span>
                </FadeIn>
              </div>
            </div>
          </Transition.Child>
          <div className='flex-shrink-0 w-14'>{/* Force sidebar to shrink to fit close icon */}</div>
        </Dialog>
      </Transition.Root>

      <div className='hidden md:flex md:flex-shrink-0'>
        <div className='flex flex-col w-64'>
          <div className='flex-1 flex flex-col min-h-0 bg-blue-50'>
            <div className='flex-1 flex flex-col justify-between py-6 overflow-y-auto'>
              <div className='space-y-6'>
                <div className='flex items-center flex-shrink-0 px-6'>
                  <Logo size={150} />
                </div>
                <FadeIn className='bg-blue-50 flex flex-col justify-between'>
                  {navItems.map(item => (
                    <Link
                      style={{ textDecoration: 'none' }}
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.current
                          ? 'bg-brand_blue_light text-white focus:text-white hover:text-white'
                          : 'text-gray-700 hover:bg-brand_blue_light hover:opacity-25 focus:text-white hover:text-white',
                        'group flex items-center p-6 text-xs',
                      )}
                    >
                      <item.icon
                        className={classNames(item.current ? 'text-white ' : 'text-gray-700 group-hover:text-white', 'mr-4 flex-shrink-0 h-4 w-4')}
                        aria-hidden='true'
                      />
                      {item.name}
                    </Link>
                  ))}
                </FadeIn>
              </div>
              <FadeIn className=''>
                <span
                  onClick={signout}
                  className={classNames(
                    'text-gray-700 hover:bg-brand_blue_light hover:opacity-25 hover:text-white',
                    'group flex items-center p-6 text-xs cursor-pointer',
                  )}
                >
                  <OutIcon className={classNames('text-gray-700 group-hover:text-white', 'mr-4 flex-shrink-0 h-4 w-4')} aria-hidden='true' />
                  Logout
                </span>
              </FadeIn>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col w-0 flex-1 overflow-hidden'>
        <div className='md:hidden pl-1 sm:pl-3 py-3 flex items-center'>
          <button
            type='button'
            className='-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none '
            onClick={() => setSidebarOpen(true)}
          >
            <span className='sr-only'>Open sidebar</span>
            <MdMenu className='h-6 w-6' aria-hidden='true' />
          </button>
          <Logo size={150} />
        </div>
        <main className='flex-1 relative z-0 overflow-y-auto focus:outline-none'>
          <Route path={[...navItems.map(d => d?.href), '/settings']}>
            <div className={`flex items-center justify-between px-4 md:px-12 py-6 shadow-sm bg-white z-10`}>
              <div className='flex space-x-4 items-center'>
                {location.pathname.split('/').length > 3 && (
                  <div onClick={() => history.goBack()} className='cursor-pointer text-lg text-brand_blue'>
                    &larr;
                  </div>
                )}
                <Heading className={`text-brand_blue font-semibold text-left capitalize`} title={location.pathname.split('/')[1].replaceAll('-', ' ')} />
              </div>

              <div className='ml-4 text-gray-400 flex items-center justify-end md:ml-6 flex-shrink-0'>
                <div
                  onClick={() => history.push('/settings')}
                  type='button'
                  className='ml-3 relative max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none lg:p-2 lg:rounded-md lg:hover:bg-gray-50 cursor-pointer border'
                >
                  <div className='h-8 w-8 rounded-full overflow-hidden'>
                    <img className='h-full w-full object-cover' src={user?.image ? URL.createObjectURL(user?.image) : Avatar} alt='' />
                  </div>
                  <span className='hidden ml-3 text-gray-700 text-sm font-medium lg:block capitalize'>
                    <span className='sr-only'>Open user menu for </span>
                    {`${user?.first_name} ${user?.last_name}`}
                  </span>
                </div>
              </div>
            </div>
          </Route>
          {/* Content */}
          <Content />
          {/* /End of Content */}
        </main>
      </div>
    </div>
  );
};

export default Base;
