import React, { useState, useEffect } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { Route, Switch, Redirect, useLocation, useHistory } from 'react-router-dom';

import Stats from './stats';

const routes = [{ name: 'Stats', path: '/dashboard/stats', component: Stats, current: false }];

const Dashboard = () => {
  const location = useLocation();
  const history = useHistory();
  const [items, setItems] = useState(routes);
  // const user = useSelector(state => state.user);

  useEffect(() => {
    if (routes.length > 0 && location.pathname.split('/').length <= 2) {
      history.push(routes[0].path);
    }
    setItems(
      items.map(item => {
        item.current = item.name
          .replace(' ', '-')
          .replace('&', '')
          .replace(' ', '')
          .toLocaleLowerCase()
          .includes(location.pathname.split('/')[location.pathname.split('/').length - 1].toLocaleLowerCase());
        return item;
      }),
    );
    // }
    // eslint-disable-next-line
  }, [location]);

  return (
    <div className='flex justify-between h-full w-full items-center space-x-12 px-4 md:px-12 py-6 min-h-screen'>
      <Switch>
        {items.map((route, i) => {
          return (
            <Route exact key={i} path={route.path}>
              <route.component />
            </Route>
          );
        })}
        <Redirect to='/404' />
      </Switch>
      {items.length > 1 && (
        <FadeIn
          className={`hidden w-48 md:flex flex-col justify-between items-start
        py-4 h-full border-l border-gray-200`}
        >
          <div className='space-y-6'>
            {items.map((item, idx) => {
              return (
                <div
                  onClick={() => history.push(typeof item.path === 'string' ? item.path : item.path[0])}
                  key={idx}
                  style={{ marginLeft: '-1px' }}
                  className={`${item.current ? `border-l-2 border-gray-800` : ``} text-gray-600 px-4 text-sm cursor-pointer`}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
        </FadeIn>
      )}
    </div>
  );
};

export default Dashboard;
