import React from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector } from 'react-redux';
import Heading from '../../../../components/fragments/heading';

const AppInfo = () => {
  const { settings } = useSelector(state => state.state);

  return (
    <FadeIn
      className={`flex-1 flex flex-col justify-start
       h-full w-full space-y-4 overflow-auto flex-shrink-0`}
    >
      <Heading className={`text-gray-700`} title={`App Information`} size={3} />

      <FadeIn className='space-y-6 mt-2'>
        {settings &&
          settings.length > 0 &&
          settings.map(d => {
            return (
              <div key={d.id} className='flex items-center space-x-2'>
                <div className='font-semibold capitalize'>{d?.name.replaceAll('_', ' ')}:</div>
                <div>{d.name === 'api_key' ? d?.value.slice(0, 20) + '**********' + d?.value.slice(d?.value.length - 6, d?.value.length) : d?.value}</div>
              </div>
            );
          })}
      </FadeIn>
    </FadeIn>
  );
};

export default AppInfo;
