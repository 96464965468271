import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import routes from '../../../routes';
import InputBox from '../../../components/fragments/input-box';
import Button from '../../../components/fragments/button';
import Logo from '../../../components/fragments/logo/logo';

import { UserActions } from '../../../states/actions';

const detail = {
  username: '',
  password: '',
};

const SignIn = () => {
  const user = useSelector(state => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const [userDetail, setUserDetail] = useState(detail);

  useEffect(() => {
    if (user?.access_token) {
      localStorage.setItem('user', JSON.stringify(user));
      if (user?.membership_type === 'agent') {
        let r = routes.filter(d => d.name === 'Pins');
        history.push(r[0].href);
      } else if (user?.membership_type === 'field-agent') {
        let r = routes.filter(d => d.name === 'Validate Reward');
        history.push(r[0].href);
      } else {
        history.push(routes[0].href);
      }
    } else {
      localStorage.removeItem('user');
    }
    // eslint-disable-next-line
  }, [user]);

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(UserActions.signIn(userDetail)).catch(console.log);
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setUserDetail({ ...userDetail, [name]: value });
  };

  return (
    <form className='w-full flex' onSubmit={handleSubmit}>
      <div className='h-full flex-1 px-6 py-16 flex flex-col justify-start space-y-6'>
        <div className='max-w-md w-full mx-auto'>
          <div className='flex items-center flex-shrink-0'>
            <Logo size={150} />
          </div>
        </div>

        <FadeIn className={`space-y-8 max-w-md w-full mx-auto`}>
          <h1 className='text-2xl text-gray-700'>Sign in</h1>
          <InputBox
            label={`Username`}
            labelColor={`text-gray-700`}
            placeholder={`Enter your username`}
            name={`username`}
            onValueChange={handleInputChange}
            value={userDetail.username}
            required={true}
            variant={3}
          />
          <InputBox
            label={`Password`}
            labelColor={`text-gray-700`}
            type={`password`}
            placeholder={`Enter your password`}
            name={`password`}
            autoComplete={'current-password'}
            onValueChange={handleInputChange}
            value={userDetail.password}
            required={true}
            variant={3}
          />
          <div className={`-mt-4 flex justify-between space-x-4`}>
            <div className='flex space-x-2 items-center cursor-pointer'>
              <input className='cursor-pointer' id='remember' type='checkbox' />
              <label htmlFor='remember' className={`text-gray-400 inline-block cursor-pointer`}>
                Remember me
              </label>
            </div>
          </div>
          <Button text={`Sign In`} className={`w-full`} />
        </FadeIn>
      </div>
    </form>
  );
};

export default SignIn;
