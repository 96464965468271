import React, { useEffect, useState } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useParams, Switch, Route, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { parseISO } from 'date-fns';
import { formatDate } from '../../../../util.js';

import Heading from '../../../../components/fragments/heading';
import Button from '../../../../components/fragments/button';

import StateActions from '../../../../states/actions/state';

const Groups = () => {
  const history = useHistory();
  const { retargetingGroups } = useSelector(state => state.state);

  return (
    <Switch exitBeforeEnter initial={false}>
      <Route exact path={'/retargeting/groups'}>
        <FadeIn
          className={`flex-1 flex flex-col justify-start
         h-full w-full space-y-4 overflow-auto flex-shrink-0`}
        >
          <div className='flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4 justify-between items-center'>
            <Heading className={`text-gray-700`} title={`Groups`} size={3} />
            <Button size={2} onClick={() => history.push('/retargeting/groups/create')} text='Create Group' />
          </div>
          <div className='grid grid-cols-1  sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 py-4 px-1 gap-4 '>
            {retargetingGroups &&
              retargetingGroups.map((d, idx) => {
                return (
                  <div
                    onClick={() => history.push(`/retargeting/groups/detail/${d.id}`)}
                    key={idx}
                    className='shadow p-4 cursor-pointer w-full h-full rounded-md overflow-hidden hover:shadow-lg hover:bg-gray-50 space-y-4'
                  >
                    <div>
                      <div className='text-xs text-gray-400'>Name</div>
                      <div className='text-gray-700 font-semibold text-lg capitalize'>{d?.name}</div>
                    </div>
                    <div>
                      <div className='text-xs text-gray-400'>Created</div>
                      <div className='text-gray-700 font-semibold text-lg'>{formatDate(parseISO(d?.created_at), `MMM dd, yyyy hh:mm a`)}</div>
                    </div>
                  </div>
                );
              })}
          </div>
        </FadeIn>
      </Route>
      <Route exact path={'/retargeting/groups/detail/:id'}>
        <GroupDetail />
      </Route>
    </Switch>
  );
};

const GroupDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { retargetingGroups } = useSelector(state => state.state);
  const [contacts, setContacts] = useState();
  const [group, setGroup] = useState();
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (id) {
      dispatch(StateActions.fetchGroupContacts(id)).then(res => {
        if (res) {
          setContacts(res);
        }
      });
      let g = retargetingGroups.find(d => Number(d.id) === Number(id));
      if (g) setGroup(g);
    }
    // eslint-disable-next-line
  }, [id, retargetingGroups]);

  const sendMessage = () => {
    dispatch(StateActions.sendBulkMessage({ id, message })).then(_ => setMessage({ message: '' }));
  };

  return (
    <FadeIn
      className={`flex-1 flex flex-col justify-start
    h-full w-full space-y-10 overflow-auto flex-shrink-0`}
    >
      <div>
        <Heading className={`text-gray-700`} title={`Group Name`} size={3} />
        <div className='text-gray-700 font-semibold text-xl capitalize'>{group?.name}</div>
      </div>
      <div className='space-y-2'>
        <Heading className={`text-gray-700`} title={`Contacts`} size={3} />
        <div className='grid grid-cols-1  sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 px-1 gap-4 '>
          {contacts && contacts.length > 0 ? (
            <>
              {contacts.map((d, idx) => {
                return (
                  <div className='shadow p-4 cursor-pointer w-full h-full rounded-md overflow-hidden hover:shadow-lg hover:bg-gray-50 space-y-4' key={idx}>
                    <div>
                      <div className='text-xs text-gray-400'>Name</div>
                      <div className='text-gray-700 font-semibold text-lg'>{d?.name}</div>
                    </div>
                    <div>
                      <div className='text-xs text-gray-400'>Contact</div>
                      <div className='text-gray-700 font-semibold text-lg'>{d?.phone_number}</div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div>No contacts</div>
          )}
        </div>
      </div>

      <div className={`w-full space-y-2`}>
        <Heading className={`text-gray-700 capitalize`} title={`Message Group`} size={3} />
        <textarea
          style={{ borderColor: '#D7EEFF' }}
          className='p-4 rounded-t-lg border-b-2 bg-gray-400 bg-opacity-10 text-gray-700 flex items-center space-x-4 overflow-hidden w-full ring-0 outline-none resize-none'
          value={message}
          onChange={e => setMessage(e.target.value)}
          name='message'
          placeholder='Message'
          id='message'
          cols='30'
          rows='10'
        ></textarea>
        <Button className={`${!message ? 'pointer-events-none opacity-50' : ''} w-full`} onClick={sendMessage} text={'Send Message'} />
      </div>
    </FadeIn>
  );
};

export default Groups;
