import React, { useEffect } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector, useDispatch } from 'react-redux';

import Heading from '../../../../components/fragments/heading';

import PinRequestTable from '../../../../components/fragments/data-table';
import SelectBox from '../../../../components/fragments/select-box';

import { StateActions } from '../../../../states/actions';

const PinModuleBase = () => {
  const dispatch = useDispatch();
  const { pinRequests } = useSelector(state => state.state);
  const { clients, selectedClient } = useSelector(state => state.state);

  useEffect(() => {
    if (selectedClient && selectedClient?.id) {
      dispatch(StateActions.fetchPinRequests(selectedClient.id, 1000000, 1));
    }
    // eslint-disable-next-line
  }, [selectedClient]);
  return (
    <FadeIn
      className={`flex-1 flex flex-col justify-start
      h-full w-full space-y-4 overflow-auto flex-shrink-0`}
    >
      <Heading className={`text-gray-700`} title={`Pin Request`} size={3} />
      <SelectBox
        onValueChange={e => {
          dispatch(StateActions.setSelectedClient(e.target.value));
        }}
        options={['Select option', ...clients.map(d => d.company_rep + ' ( ' + d.username + ' )')]}
        variant={2}
        value={selectedClient ? selectedClient.company_rep + ' ( ' + selectedClient.username + ' )' : ''}
        label='Client'
      />
      <PinRequestTable data={pinRequests} perPage={6} variant='pin-request' />
    </FadeIn>
  );
};

export default PinModuleBase;
