import {
  DASHBOARD_STATS,
  FETCH_CLIENTS,
  FETCH_PRODUCT_REPORTS,
  FETCH_BRANDS,
  FETCH_AGENTS,
  FETCH_SETTINGS,
  FETCH_CURRENT_CLIENT_PRODUCTS,
  FETCH_CURRENT_PRODUCT_BATCHES,
  SELECTED_CLIENT,
  SELECTED_PRODUCT,
  SELECTED_BATCH,
  FETCH_CLIENT_BATCHES,
  FETCH_CLIENT_SURVEYS,
  FETCH_CLIENT_SURVEY_REWARDS,
  SELECTED_SURVEY,
  SELECTED_SURVEY_REWARD,
  REMOVE_AGENT,
  FETCH_SURVEY_QUESTIONS,
  FETCH_BRAND_PRODUCTS,
  RESET_BRAND_PRODUCTS,
  FETCH_WINNER_LOGS,
  FETCH_LABEL_REQUEST,
  FETCH_PIN_REQUEST,
  FETCH_GROUPS,
  FETCH_ALL_NAFDAC_REPORT,
  FETCH_ALL_CHEKKIT_REPORT,
  FETCH_NAFDAC_STATS,
  FETCH_NAFDAC_REPORT,
  FETCH_NAFDAC_REPORT_NAME,
  FETCH_NAFDAC_REPORT_ID
} from '../type';

const initialState = {
  stats: '',
  clients: [],
  productReports: '',
  brands: '',
  agents: '',
  settings: '',
  currentClientProducts: [],
  currentProductBatches: [],
  selectedClient: '',
  selectedProduct: '',
  selectedBatch: '',
  currentClientBatches: [],
  currentClientSurveys: [],
  currentClientSurveyRewards: [],
  selectedSurvey: '',
  selectedSurveyReward: '',
  currentSurveyQuestions: '',
  currentBrandProducts: '',
  rewardWinnersLogs: [],
  labelRequests: [],
  retargetingGroups: [],
  all_nafdac_reports: [],
  all_chekkit_reports: [],
  nafdacStats: '',
  nafdac_reports: [],
  nafdac_report_name: [],
  nafdac_report_id: [],

};

const StateReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case DASHBOARD_STATS:
      return { ...state, stats: payload };
    case FETCH_CLIENTS:
      return { ...state, clients: payload };
    case FETCH_LABEL_REQUEST:
      return { ...state, labelRequests: payload };
    case FETCH_PIN_REQUEST:
      return { ...state, pinRequests: payload };
    case FETCH_PRODUCT_REPORTS:
      return { ...state, productReports: payload };
    case FETCH_GROUPS:
      return { ...state, retargetingGroups: payload.reverse() };
    case FETCH_BRANDS:
      return { ...state, brands: payload };
    case FETCH_AGENTS:
      return { ...state, agents: payload };
    case FETCH_SETTINGS:
      return { ...state, settings: payload };
    case FETCH_CURRENT_CLIENT_PRODUCTS:
      return { ...state, currentClientProducts: payload };
    case FETCH_CURRENT_PRODUCT_BATCHES:
      return { ...state, currentProductBatches: payload };
    case SELECTED_CLIENT:
      let client = state.clients.find(d => {
        let s = d.company_rep + ' ( ' + d.username + ' )';
        return s === payload;
      });
      return { ...state, selectedClient: client, selectedProduct: '', selectedBatch: '', currentProductBatches: [] };
    case SELECTED_PRODUCT:
      let product = state.currentClientProducts.find(d => payload.toLowerCase().includes(d.product_name.toLowerCase()));
      return { ...state, selectedProduct: product, selectedBatch: '' };
    case SELECTED_BATCH:
      let allBatch = [...state.currentProductBatches, ...state.currentClientBatches];

      let batch = allBatch.find(d => {
        if (d.batch_num) {
          let b = payload.toLowerCase().includes(d.batch_num) && payload.toLowerCase().includes(d.product_name + ' ( ' + d.batch_num + ' )'.toLowerCase());
          if (b) return b;
          return payload.toLowerCase().includes(d.batch_num);
        } else {
          return payload.toLowerCase().includes(d.product_name.toLowerCase());
        }
      });
      return { ...state, selectedBatch: batch };
    case FETCH_CLIENT_BATCHES:
      return { ...state, currentClientBatches: payload };
    case FETCH_CLIENT_SURVEYS:
      return { ...state, currentClientSurveys: payload };
    case FETCH_CLIENT_SURVEY_REWARDS:
      return { ...state, currentClientSurveyRewards: payload };
    case SELECTED_SURVEY:
      let survey = state.currentClientSurveys.find(d => payload.toLowerCase().includes(d.title.toLowerCase()));
      return { ...state, selectedSurvey: survey };
    case SELECTED_SURVEY_REWARD:
      let surveyReward = state.currentClientSurveyRewards.find(
        d => payload.toLowerCase().includes(d.reward_type.toLowerCase()) && payload.toLowerCase().includes(d.reward_value.toLowerCase()),
      );
      return { ...state, selectedSurveyReward: surveyReward };
    case REMOVE_AGENT:
      let ags = state.agents.filter(d => d.id !== payload);
      return { ...state, agents: ags };
    case FETCH_SURVEY_QUESTIONS:
      return { ...state, currentSurveyQuestions: payload };
    case FETCH_BRAND_PRODUCTS:
      return { ...state, currentBrandProducts: payload };
    case RESET_BRAND_PRODUCTS:
      return { ...state, currentBrandProducts: '' };
    case FETCH_WINNER_LOGS:
      return { ...state, rewardWinnersLogs: payload };
      case FETCH_ALL_NAFDAC_REPORT:
      return { ...state, all_nafdac_reports: payload };
      case FETCH_ALL_CHEKKIT_REPORT:
      return { ...state, all_chekkit_reports: payload };
      case FETCH_NAFDAC_STATS:
      return { ...state, nafdacStats: payload };
      case FETCH_NAFDAC_REPORT:
      return { ...state, nafdac_reports: payload };
      case FETCH_NAFDAC_REPORT_NAME:
      return { ...state, nafdac_report_name: payload };
      case FETCH_NAFDAC_REPORT_ID:
      return { ...state, nafdac_report_id: payload };
    default:
      return state;  
  }
};

export default StateReducer;
