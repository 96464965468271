import React, { useState, useEffect } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useHistory, Route, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';

import Heading from '../../../../components/fragments/heading';

import Button from '../../../../components/fragments/button';
import SelectBox from '../../../../components/fragments/select-box';
import InputBox from '../../../../components/fragments/input-box';
import QuestionList from '../../../../components/fragments/question-list';

import { StateActions } from '../../../../states/actions';

const initialData = {
  survey: { title: '', content: '', type: '1', user_id: '' },
  question: [{ content: '', choices: [{ text: '' }, { text: '' }] }],
};

const EditSurvey = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { currentClientSurveys, currentSurveyQuestions } = useSelector(state => state.state);
  const [data, setData] = useState(initialData);

  useEffect(() => {
    if (!currentClientSurveys || currentClientSurveys.length === 0) {
      history.push('/survey/all-survey');
    }
    // eslint-disable-next-line
  }, [currentClientSurveys]);

  useEffect(() => {
    if (id) {
      dispatch(StateActions.fetchSurveyQuestions(id));
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (currentClientSurveys && currentClientSurveys.length > 0 && currentSurveyQuestions) {
      const s = currentClientSurveys.find(d => Number(d.id) === Number(id));

      const st = { id: s.id, title: s.title, content: s.content, type: s.type + '', user_id: s.userId, slug: s.slug };

      let qt = currentSurveyQuestions.map(q => {
        if (typeof q.choices === 'string') {
          q.choices = JSON.parse(q.choices);
        }
        return q;
      });

      setData({ survey: st, question: qt });
    }

    // eslint-disable-next-line
  }, [currentClientSurveys, currentSurveyQuestions]);

  const handleMetaChange = e => {
    const { name, value } = e.target;
    if (name === 'type') {
      // eslint-disable-next-line
      setData({ ...data, survey: { ...data.survey, type: value == 'Yes' ? '1' : '2' } });
    } else {
      setData({ ...data, survey: { ...data.survey, [name]: value } });
    }
  };

  const handleDataChange = e => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = () => {
    let x = cloneDeep(data);

    let d = x.question.map(q => {
      q.choices = JSON.stringify(q.choices);
      return q;
    });

    let y = { survey: x.survey, question: d };

    dispatch(StateActions.updateSurvey(y.survey.slug, y)).then(_ => {
      setData(initialData);
      history.push('/survey/all-survey');
    });
  };

  return (
    <FadeIn
      className={`flex-1 flex flex-col justify-start
     h-full w-full space-y-4 overflow-auto flex-shrink-0`}
    >
      <Heading className={`text-gray-700`} title={`Edit Survey`} size={3} />

      <FadeIn className='space-y-6 mt-4'>
        <Route path={`/survey/edit-survey/step-one/${id}`}>
          <AddSurveyMeta data={data} handleInputChange={handleMetaChange} />
        </Route>

        <Route path={`/survey/edit-survey/step-two/${id}`}>
          <AddSurveyData data={data} setData={setData} handleInputChange={handleDataChange} />
        </Route>

        <Route path={`/survey/edit-survey/step-one/${id}`}>
          <Button
            className={`${
              !data.survey.title || !data.survey.content || !data.survey.type || !data.survey.user_id ? `pointer-events-none opacity-50` : ''
            } w-full relative top-8`}
            text={'Next Step'}
            onClick={() => history.push(`/survey/edit-survey/step-two/${id}`)}
          />
        </Route>

        <Route path={`/survey/edit-survey/step-two/${id}`}>
          <Button
            className={`${
              !data.question.length > 0 || !data.question.every(d => d.choices.every(d => d.text) && d.content) ? `pointer-events-none opacity-50` : ''
            } w-full relative top-8`}
            text={'Edit Survey'}
            onClick={handleSubmit}
          />
        </Route>
      </FadeIn>
    </FadeIn>
  );
};

const AddSurveyMeta = ({ data, handleInputChange }) => {
  return (
    <FadeIn className='space-y-4'>
      <InputBox
        className={'w-full flex-1'}
        value={data.survey.title}
        onValueChange={handleInputChange}
        name='title'
        variant={2}
        label='Title'
        placeholder='Title'
      />
      <SelectBox
        onValueChange={handleInputChange}
        options={['Select option', 'Yes', 'No']}
        variant={2}
        // eslint-disable-next-line
        value={data.survey.type == '1' ? 'Yes' : 'No'}
        name='type'
        label='Survey Have Label ?'
      />
      <InputBox
        className={'w-full flex-1'}
        value={data.survey.content}
        onValueChange={handleInputChange}
        name='content'
        variant={2}
        label='Content'
        placeholder='Content'
      />
    </FadeIn>
  );
};

const AddSurveyData = ({ data, setData }) => {
  return (
    <FadeIn>
      <QuestionList survey={data} setSurvey={setData} />
    </FadeIn>
  );
};

export default EditSurvey;
