import React, { useEffect, useState } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import Heading from '../../../../components/fragments/heading';

import BrandTable from '../../../../components/fragments/data-table';
import Dialog from '../../../../components/fragments/dialog';

import { StateActions } from '../../../../states/actions';

const AllBrands = () => {
  const perPage = 2;
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentBrandProducts, brands } = useSelector(state => state.state);
  const [brand, setBrand] = useState();
  const [product, setProduct] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (brands) {
      let b = brands.find(d => Number(d.id) === Number(id));
      setBrand(b);
    } else {
      history.goBack();
    }
    // eslint-disable-next-line
  }, [brands]);

  useEffect(() => {
    if (id) {
      dispatch(StateActions.fetchBrandProducts(id, perPage, activePage)).then(res => {
        setTotalCount(res?.data?.metadata?.totalItems);
      });
    }
    // eslint-disable-next-line
  }, [activePage, id]);

  const handleDialog = (toOpen, data) => {
    if (toOpen && data) {
      setProduct(data);
      setOpen(toOpen);
    }
  };

  return (
    <>
      <FadeIn
        className={`flex-1 flex flex-col justify-start
       h-full w-full space-y-4 overflow-auto flex-shrink-0`}
      >
        <Heading className={`text-gray-700 capitalize`} title={`${brand?.username} Products`} size={3} />
        <BrandTable
          data={currentBrandProducts}
          variant='brand-products'
          totalCount={totalCount}
          perPage={perPage}
          onActivePageChange={setActivePage}
          openDialog={handleDialog}
        />
      </FadeIn>
      <Dialog open={open} setOpen={setOpen} data={product} variant='message-product-authenticators' />
    </>
  );
};

export default AllBrands;
