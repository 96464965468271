import React, { useState, useEffect } from 'react';
import { MdDelete } from 'react-icons/md';
import FadeIn from 'react-fade-in/lib/FadeIn';

import InputBox from '../input-box';
import OptionList from '../option-list';

const QuestionList = ({ survey, setSurvey }) => {
  const addQuestion = () => {
    let qs = [...survey.question, { content: '', choices: [{ text: '' }, { text: '' }] }];
    setSurvey({ survey: survey.survey, question: qs });
  };

  const deleteQuestion = index => {
    let qs = survey.question.filter((q, idx) => {
      return idx !== index;
    });
    setSurvey({ survey: survey.survey, question: qs });
  };

  const onQuestionChange = (index, item) => {
    let qs = survey.question.map((q, idx) => {
      if (idx === index) {
        return item;
      }
      return q;
    });
    setSurvey({ survey: survey.survey, question: qs });
  };

  return (
    <div className={`w-full flex flex-col justify-center items-center `}>
      <FadeIn className={`w-full space-y-6`}>
        {survey.question.length > 0 &&
          survey.question.map((question, idx) => {
            return <Item key={idx} item={question} idx={idx} onDelete={deleteQuestion} onQuestionChange={onQuestionChange} />;
          })}
      </FadeIn>
      <div className={`w-full`}>
        <div onClick={addQuestion} className={`inline-flex items-center space-x-2 cursor-pointer`}>
          <span style={{ paddingBottom: '.1px' }} className={`w-4 h-4 text-white bg-brand_blue flex justify-center items-center rounded-full`}>
            +
          </span>
          <span className={`text-brand_blue font-semibold text-sm`}>Add Question</span>
        </div>
      </div>
    </div>
  );
};

const Item = ({ item, idx, onDelete, onQuestionChange }) => {
  const [question, setQuestion] = useState();

  useEffect(() => {
    if (item) {
      setQuestion(item);
    }
  }, [item]);

  useEffect(() => {
    if (question) {
      onQuestionChange(idx, question);
    } // eslint-disable-next-line
  }, [question]);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setQuestion({ ...question, [name]: value });
  };

  const onOptionsChange = data => {
    setQuestion({ ...question, choices: data });
  };

  return (
    <div className={`flex flex-col justify-center items-center pb-8 pt-2 px-2`}>
      <div className={`w-full mb-12 relative `}>
        <InputBox
          label={`Question ${idx + 1}`}
          name={`content`}
          value={item?.content}
          onValueChange={handleInputChange}
          labelColor={`text-gray-500`}
          placeholder={`Type question here...`}
          variant={2}
        />
        {idx > 0 && <MdDelete onClick={() => onDelete(idx)} className={`text-gray-300 hover:text-gray-600 cursor-pointer absolute top-0 right-0`} size={20} />}
      </div>
      <OptionList data={item.choices} onDataChange={onOptionsChange} />
    </div>
  );
};

export default QuestionList;
