import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ReactNotification from 'react-notifications-component';
import { store } from 'react-notifications-component';
import { CgSpinner } from 'react-icons/cg';

import routes from './routes';
import Auth from './pages/auth';
// import CreateAdmin from './pages/dashboard/settings/create-admin';
import Base from './components/layout/base';
import NotFound from './pages/404-page';

import { UserActions, StateActions } from './states/actions';
import { loading, notify } from './states/actions/response';

function App() {
  const user = useSelector(state => state.user);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const response = useSelector(state => state.response);
  const [userLoading, setUserLoading] = useState(true);

  useEffect(() => {
    let userDetail = JSON.parse(localStorage.getItem('user'));
    if (userDetail) {
      history.push(location.pathname);
      dispatch(UserActions.updateUser(userDetail));
      setUserLoading(false);
    } else if (location.pathname.length > 200) {
      setUserLoading(false);
    } else {
      setUserLoading(false);
      history.push('/');
    } // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user?.access_token) {
      Promise.all([
        dispatch(StateActions.fetchDashboardStats()),
        dispatch(StateActions.fetchClients()),
        dispatch(StateActions.fetchProductReports()),
        dispatch(StateActions.fetchAgents()),
        dispatch(StateActions.fetchSettings()),
        dispatch(StateActions.fetchLabelRequests(1000000, 1)),
        dispatch(StateActions.fetchGroups()),
      ]).then(_ => dispatch(loading(false)));
    }
    // eslint-disable-next-line
  }, [user?.access_token]);

  useEffect(() => {
    if (response.type) {
      let msg = '';
      if (typeof response.message === 'string') {
        msg = response.message;
      } else {
        Object.keys(response.message).forEach(key => {
          response.message[key].forEach(msg => {
            let str = `${key} Error`;
            let title = str.charAt(0).toUpperCase() + str.slice(1);
            let message = msg;
            showNotification(response, message, title);
          });
        });
        return;
      }
      showNotification(response, msg);
    } // eslint-disable-next-line
  }, [response]);

  const showNotification = (response, message, title) => {
    store.addNotification({
      message: message,
      type: response.type,
      title: title ? title : response.title,
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 4000,
        onScreen: true,
        pauseOnHover: true,
        showIcon: true,
      },
      onRemoval: () => {
        let timer;
        (() => {
          clearTimeout(timer);
          timer = setTimeout(() => {
            dispatch(notify({ title: '', message: '', type: '', loading: false }));
          }, 2000);
        })();
      },
    });
  };

  return (
    <>
      <ReactNotification isMobile={true} />
      {userLoading && (
        <div className={`absolute top-0 bottom-0 left-0 right-0 z-20 flex justify-center items-center`}>
          <CgSpinner className={`text-blue-600 animate-spin`} size={64} />
        </div>
      )}
      {!userLoading && (
        <Switch location={location}>
          <Route exact path={['/', '/signin']}>
            <Auth />
          </Route>
          {/* <Route path={'/settings/create-admin'}>
            <CreateAdmin />
          </Route> */}
          <Route
            path={[
              ...routes
                .filter(d => {
                  if (user?.membership_type === 'agent') {
                    return d.name === 'Pins';
                  } else if (user?.membership_type === 'field-agent') {
                    return d.name === 'Validate Reward';
                  } else {
                    return d.name;
                  }
                })
                .map(d => d.href),
              '/settings',
            ]}
          >
            <Base />
          </Route>
          <Route
            render={() => {
              return (
                <div className={`bg`}>
                  <NotFound />;
                </div>
              );
            }}
          />
        </Switch>
      )}
    </>
  );
}

export default App;
