import React, { useEffect, useState } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector, useDispatch } from 'react-redux';

import Heading from '../../../../components/fragments/heading';

import BrandTable from '../../../../components/fragments/data-table';
import Dialog from '../../../../components/fragments/dialog';

import { StateActions } from '../../../../states/actions';

const AllBrands = () => {
  const perPage = 6;
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const dispatch = useDispatch();
  const { brands } = useSelector(state => state.state);
  const [brand, setBrand] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(StateActions.fetchBrands(perPage, activePage)).then(res => {
      setTotalCount(res?.data?.metadata?.totalItems);
    });
    dispatch(StateActions.resetBrandProducts());
    // eslint-disable-next-line
  }, [activePage]);

  const handleDialog = (toOpen, data) => {
    if (toOpen && data) {
      setBrand(data);
      setOpen(toOpen);
    }
  };

  return (
    <>
      <FadeIn
        className={`flex-1 flex flex-col justify-start
       h-full w-full space-y-4 overflow-auto flex-shrink-0`}
      >
        <Heading className={`text-gray-700`} title={`All Brands`} size={3} />
        <BrandTable data={brands} variant='all-brands' totalCount={totalCount} perPage={perPage} onActivePageChange={setActivePage} openDialog={handleDialog} />
      </FadeIn>
      <Dialog open={open} setOpen={setOpen} data={brand} variant='message-brand-authenticators' />
    </>
  );
};

export default AllBrands;
