import React, { useEffect, useState } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, useParams } from 'react-router-dom';

import Heading from '../../../../components/fragments/heading';

import commentImage from '../../../../assets/comments.svg';

import NoImage from '../../../../assets/no-image.jpeg';
import Button from '../../../../components/fragments/button';



import { StateActions } from '../../../../states/actions';
import SelectBox from '../../../../components/fragments/select-box/select-box';

const RmsReport = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { nafdac_report_id } = useSelector(state => state.state);
  const [isEditing, setIsEditing] = useState(false);
  const [fieldValue, setFieldValue] = useState(''); 
  const [showDiv1, setShowDiv1] = useState(true);
  const [showDiv2, setShowDiv2] = useState(false);
  const comment = nafdac_report_id[0]?.comments[0]?.comment;
  const commentsId = nafdac_report_id[0]?.comments[0]?.id;
  console.log('commentsid', commentsId)

  const [editedComment, setEditedComment] = useState(comment);

  const handleReloadClick = () => {
    window.location.reload();
  };


  console.log('comments', comment)

  const handleButtonClick = () => {
    setShowDiv1(!showDiv1);
    setShowDiv2(!showDiv2);
  };

  const handleCancelClick = () => {
    setShowDiv1(true); 
    setShowDiv2(false); 
  };
  


  useEffect(() => {
    if (id) {
      dispatch(StateActions.fetchNafdacReport(id))
    }
  }, [id, dispatch]);

  const commentId = nafdac_report_id[0]?.id;


  const handleEditClick = () => {
    setIsEditing(!isEditing);
    setEditedComment(comment);
  };

  const handleInputChange = (e) => {
    setFieldValue(e.target.value);
  };

  const updatedCommentData = {
    commentId: commentsId, 
    newComment: editedComment,
  };

  const commentData = {
    nafdacReportId: commentId, 
    comment: fieldValue,
  };


  const createComment = () => {
    dispatch(StateActions.createComment(commentData))
  };

  const updateComment = () => {
    dispatch(StateActions.updateComment(updatedCommentData))
  };

  const handleReportChange = (newStatus, slug) => {
    const updateData = {
      slug: String(nafdac_report_id[0]?.slug),
      status: newStatus
    };
    // const JSONupdateData = JSON.stringify(updateData);

    dispatch(StateActions.updateNafdacReportsStatus(updateData))

  };



  return ( 
    <>
    <Switch exitBeforeEnter initial={false}> 
    <Route exact>
      <FadeIn
        className={`bg-gray-100 p-5 rounded-lg flex-1 flex flex-col justify-start
       h-full w-full space-y-4 overflow-auto flex-shrink-0`}
      >
        <div className='flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4  items-center'>
            <Heading className={`text-black font-medium capitalize`} title={`report details `} size={3} />
            <p style={{ color: '#1D2433'}} className='capitalize text-[#1D2433] text-sm font-normal'>  {new Date(nafdac_report_id[0]?.created_at).toDateString()} </p>
        </div>
       
        <div className='bg-white p-5 rounded-lg'>
          <div className='flex flex-col mb-5 space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4 justify-between items-center'> 
            <Heading className={`text-black font-medium capitalize`} title={`consumer’s report`} size={3} />
            <div className='flex flex-row space-x-4' style={{ color: '#0000FF' }}>
            <SelectBox
              value={nafdac_report_id[0]?.report_status}
              onValueChange={event => {
                const newStatus = event.target.value;
                handleReportChange(newStatus, nafdac_report_id[0]?.slug);
              }}
              options={['PENDING', 'ONGOING', 'RESOLVED']}
              variant={5}
            />
              </div>
          </div>
            
            <div className='bg-gray-100 p-5 rounded-lg'>
             <div style={{ color: '#1D2433' }} className='flex flex-row space-x-7'>
                <div className='flex flex-col'>
                    <p className='mb-2' style={{ fontSize: '14px', fontWeight: 400 }} >Product Image</p>
                    <img height="154" width="203" className='object-cover rounded-3xl ' src={nafdac_report_id[0]?.product_image ? nafdac_report_id[0]?.product_image : NoImage} alt='loading...' />

                  </div>
                  <div  className='flex flex-col'>
                    <div className='mb-4'>
                          <p className='font-normal'>Product Name</p>
                          <p className='font-bold capitalize'>{nafdac_report_id[0]?.product_name}</p>
                      </div>
                      <div className='mb-4'>
                          <p className='font-normal'>Product Store</p>
                          <p className='font-bold capitalize'>{nafdac_report_id[0]?.product_store_address}</p>
                      </div>
                      <div className='mb-4'>
                          <p className='font-normal'>Product Experience</p>
                          <p className='font-bold capitalize'>{nafdac_report_id[0]?.product_user_exprnce}</p>
                      </div>
                  </div> 
             </div>

             <div className='mt-2'>
              <p style={{ fontSize: '14px', color: '#1D2433', marginTop: '20px' }} className='font-normal' >Other Comments</p>
              <p style={{ color: '#1D2433', fontSize: '14px' }} className='font-bold capitalize'>{nafdac_report_id[0]?.comments[0]?.comment} </p>
             </div>
              
               
            </div>
        </div>

        <div>
              <div>
            {!comment ? (
      <div className='bg-white p-5 rounded-lg'>
        {showDiv1 && (
          <div className='mb-2'> 
            <Heading className={`text-black font-medium capitalize`} title={`NAFDACOP’s comment`} size={3} />    
                
            <div className='flex flex-col mx-auto items-center mt-4'>
                <img className=' object-cover' width="188" height="140" src={commentImage} alt='loading...' />
                <p className='mt-2 font-black text-lg'>Add comments here</p>
                <p className='text-sm font-normal'>Click on the button at the bottom to add a comment</p>
                <Button className={`w-80 mt-6 font-bold`} onClick={handleButtonClick} text='Add Comment' />
            </div>
          </div>
            )}
            {showDiv2 && (
              <div> 
                <div className='flex flex-col mb-5 space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4 justify-between items-center'>
                    <div>
                      <Heading className={`text-black font-medium capitalize`} title={`NAFDACOP’s comment`} size={3} />
                    </div> 
                    <div className='flex flex-row space-x-2'>
                      <button onClick={handleCancelClick} className='bg-white capitalize rounded-lg px-6 py-3 border-2'  style={{ minHeight: '24px', minWidth: '102px', borderColor: '#153853', color: '#153853' }}>Cancel</button>
                      <button onClick={createComment} className=' text-white capitalize rounded-lg px-6 py-3'  style={{ minHeight: '24px', minWidth: '102px', backgroundColor: '#153853' }}>Save Comment</button>
                    </div>         
                </div>
                <div> 
                  <input
                      type='text'
                      name='comment'
                      onChange={handleInputChange}
                      className='w-full p-2 border rounded'
                      style={{ borderColor: '#153853' }}
                  />
                </div> 
              </div>             
            )}
          </div>
            ) : (
              <div className='bg-white p-5 rounded-lg'>
          <div className='flex flex-col mb-5 space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4 justify-between items-center'> 
            <Heading className={`text-black font-medium capitalize`} title={`NAFDACOP’s comment`} size={3} />
            <div className='flex flex-row space-x-4'>
          {!isEditing ? (
            <p className='font-medium' style={{ color: '#03A662', cursor: 'pointer' }} onClick={handleEditClick}>
              EDIT
            </p>
          ) : (
            <div className='flex flex-row space-x-2'>
                      <button onClick={handleReloadClick}className='bg-white capitalize rounded-lg px-6 py-3 border-2'  style={{ minHeight: '24px', minWidth: '102px', borderColor: '#153853', color: '#153853' }}>Cancel</button>
                      <button onClick={updateComment} className=' text-white capitalize rounded-lg px-6 py-3'  style={{ minHeight: '24px', minWidth: '102px', backgroundColor: '#153853' }}>Save Comment</button>
              </div> 
          )}
        </div>
          </div>
            
            <div className='bg-gray-100 p-5 rounded-lg'>
        {isEditing ? (
          <input
            type='text'
            value={editedComment}
            onChange={(e) => setEditedComment(e.target.value)}
            className='w-full p-2 border rounded'
            style={{ borderColor: '#153853' }}
          />
        ) : (
          <p style={{ color: '#153853', fontSize: '14px' }} className='text-[#153853] text-sm font-normal'>
            {comment}
          </p>
        )}
      </div>
        </div>
            )}
          </div>
        </div>
      </FadeIn>
      </Route>
    </Switch>
      </>
  );
};

export default RmsReport;
 