import React, { useState } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import Heading from '../../../../components/fragments/heading';
import InputBox from '../../../../components/fragments/input-box';
import SelectBox from '../../../../components/fragments/select-box';
import Button from '../../../../components/fragments/button';

import { StateActions } from '../../../../states/actions';

const AddSurveyReward = () => {
  const dispatch = useDispatch();
  const { clients, selectedClient } = useSelector(state => state.state);
  const [data, setData] = useState({ reward_quant: '', reward_value: '', reward_type: '' });

  const handleInputChange = e => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = () => {
    let d = { user_id: selectedClient.id, rewards: [data] };
    dispatch(StateActions.addSurveyReward(d)).then(_ => setData({ reward_quant: '', reward_value: '', reward_type: '' }));
  };
  return (
    <FadeIn
      className={`flex-1 flex flex-col justify-start
     h-full w-full space-y-4 overflow-auto flex-shrink-0`}
    >
      <Heading className={`text-gray-700`} title={`Add Reward`} size={3} />

      <FadeIn className='space-y-6 mt-4'>
        <SelectBox
          onValueChange={e => dispatch(StateActions.setSelectedClient(e.target.value))}
          options={['Select option', ...clients.map(d => d.company_rep + ' ( ' + d.username + ' )')]}
          variant={2}
          value={selectedClient ? selectedClient.company_rep + ' ( ' + selectedClient.username + ' )' : ''}
          label='Client'
        />

        <SelectBox
          onValueChange={handleInputChange}
          options={['Select option', 'Merchandize', 'Raffle', 'Airtime', 'Loyalty']}
          variant={2}
          value={data.reward_type}
          name='reward_type'
          label='Reward Type'
        />

        <div className='flex justify-between items-center space-x-4'>
          <InputBox
            className={'w-full flex-1'}
            value={data.reward_quant}
            onValueChange={handleInputChange}
            name='reward_quant'
            variant={2}
            type='number'
            label='Reward Quantity'
            placeholder='Reward Quantity'
          />
          <div className='relative top-4'>-</div>
          <InputBox
            className={'w-full flex-1'}
            value={data.reward_value}
            onValueChange={handleInputChange}
            name='reward_value'
            variant={2}
            type='number'
            label='Reward Value'
            placeholder='Reward Value'
          />
        </div>
        <Button
          className={`${
            !selectedClient || !data.reward_type || !data.reward_quant || !data.reward_value ? `pointer-events-none opacity-50` : ''
          } w-full relative top-8`}
          text={'Add Reward'}
          onClick={handleSubmit}
        />
      </FadeIn>
    </FadeIn>
  );
};

export default AddSurveyReward;
