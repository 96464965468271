import React, { useState, useEffect } from 'react';
import { FiSearch } from 'react-icons/fi';

const InputBox = ({
  className,
  type,
  placeholder,
  label,
  labelColor,
  variant,
  name,
  value,
  defaultValue,
  onValueChange,
  required,
  readOnly,
  onClick,
  maxLength, 
}) => {
  const [typex, setTypex] = useState();
  useEffect(() => {
    setTypex(type);
  }, [type]);
  return (
    <>
      {!variant && (
        <div className={`${className} space-y-2 w-full`}>
          {label && (
            <label className={`font-semibold ${labelColor}`} htmlFor={label}>
              {label}
            </label>
          )}
          <div
            className={` p-5 rounded-t-lg border-b-2 border-brand_blue_light bg-white bg-opacity-10 text-gray-300 flex items-center space-x-4 overflow-hidden`}
          >
            <input
              id={label ? label : placeholder}
              className={` bg-transparent focus:outline-none  w-full`}
              spellCheck={false}
              type={typex}
              name={name}
              defaultValue={defaultValue ? defaultValue : ''}
              onChange={onValueChange}
              placeholder={placeholder}
              required={required}
              readOnly={readOnly || false}
              onKeyDown={
                type === 'number' ? evt => (evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault() : () => {}
              }
            />
          </div>
        </div>
      )}
      {variant === 'controlled' && (
        <div className={`${className} space-y-2 w-full`}>
          {label && (
            <label className={`font-semibold ${labelColor}`} htmlFor={label}>
              {label}
            </label>
          )}
          <div
            className={` p-5 rounded-t-lg border-b-2 border-brand_blue_light bg-white bg-opacity-10 text-gray-300 flex items-center space-x-4 overflow-hidden`}
          >
            <input
              id={label ? label : placeholder}
              className={` bg-transparent focus:outline-none  w-full`}
              spellCheck={false}
              type={typex}
              name={name}
              value={value ? value : ''}
              onChange={onValueChange}
              placeholder={placeholder}
              required={required}
              readOnly={readOnly || false}
              onKeyDown={
                type === 'number' ? evt => (evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault() : () => {}
              }
            />
          </div>
        </div>
      )}
      {variant === 1 && (
        <div className={`${className} w-full p-6 rounded-md shadow bg-white text-gray-600 flex items-center space-x-4 overflow-hidden`}>
          <input className={`bg-transparent focus:outline-none w-full h-full`} spellCheck={false} placeholder={placeholder} required={required} />
        </div>
      )}
      {variant === 2 && (
        <div className={`${className} space-y-2 w-full`}>
          {label && (
            <label className={`font-semibold ${labelColor}`} htmlFor={label}>
              {label}
            </label>
          )}
          <div
            style={{ borderColor: '#D7EEFF' }}
            className={`p-4 rounded-t-lg border-b-2 bg-gray-400 bg-opacity-10 text-gray-700 flex items-center space-x-4 overflow-hidden`}
          >
            <input
              id={label ? label : placeholder}
              className={` bg-transparent focus:outline-none  w-full`}
              spellCheck={false}
              type={typex}
              placeholder={placeholder}
              name={name}
              onChange={onValueChange}
              value={value ? value : ''}
              required={required}
              maxLength={maxLength}
              onClick={onClick ? onClick : () => {}}
              readOnly={readOnly || false}
              onKeyDown={
                type === 'number' ? evt => (evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault() : () => {}
              }
            />
          </div>
        </div>
      )}
      {variant === 3 && (
        <div className={`${className} space-y-2 w-full`}>
          {label && (
            <label className={`font-semibold ${labelColor}`} htmlFor={label}>
              {label}
            </label>
          )}
          <div className={`p-4 rounded-md border bg-white border-gray-300 text-gray-700 flex items-center space-x-4 overflow-hidden`}>
            <input
              id={label ? label : placeholder}
              className={` bg-transparent focus:outline-none  w-full`}
              spellCheck={false}
              type={typex}
              placeholder={placeholder}
              name={name}
              onChange={onValueChange}
              value={value ? value : ''}
              required={required}
              readOnly={readOnly || false}
              onKeyDown={
                type === 'number' ? evt => (evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault() : () => {}
              }
            />
            {typex === 'search' && <FiSearch className={`opacity-50 select-none flex-shrink-0 text-lg`} />}
          </div>
        </div>
      )}
      {variant === 4 && (
        <div className={`${className} space-y-2 w-full`}>
          {label && (
            <label className={`font-semibold ${labelColor}`} htmlFor={label}>
              {label}
            </label>
          )}
          <div className={`p-4 rounded-md bg-gray-100 text-gray-700 ${className} flex items-center space-x-4 overflow-hidden`}>
            <input
              id={label ? label : placeholder}
              className={` bg-transparent focus:outline-none  w-full`}
              spellCheck={false}
              type={typex}
              placeholder={placeholder}
              name={name}
              onChange={onValueChange}
              value={value ? value : ''}
              required={required}
              readOnly={readOnly || false}
              onKeyDown={
                type === 'number' ? evt => (evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault() : () => {}
              }
            />
            {typex === 'search' && <FiSearch className={`opacity-50 select-none flex-shrink-0 text-lg`} />}
          </div>
        </div>
      )}
      {variant === 5 && (
        <div className={`${className} space-y-2 w-full`}>
          {label && (
            <label className={`font-semibold ${labelColor}`} htmlFor={label}>
              {label}
            </label>
          )}
          <div className={`p-4 rounded-md border bg-white border-gray-300 text-gray-700 flex items-center space-x-4 overflow-hidden`}>
            <input
              id={label ? label : placeholder}
              className={` bg-transparent focus:outline-none  w-full`}
              spellCheck={false}
              type={typex}
              placeholder={placeholder}
              name={name}
              onChange={onValueChange}
              required={required}
              readOnly={readOnly || false}
              onKeyDown={
                type === 'number' ? evt => (evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault() : () => {}
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export default InputBox;
