import React, { useState, useEffect } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector, useDispatch } from 'react-redux';
import Heading from '../../../../components/fragments/heading';

import { Tabs } from '../../../../components/fragments/tabs';
import InputBox from '../../../../components/fragments/input-box/input-box';
import Button from '../../../../components/fragments/button/button';

import { StateActions } from '../../../../states/actions';

const SetPoints = () => {
  return (
    <FadeIn
      className={`flex-1 flex flex-col justify-start
       h-full w-full space-y-4 overflow-auto flex-shrink-0`}
    >
      <Heading className={`text-gray-700`} title={`Set Points`} size={3} />

      <div className={'mt-2'}>
        <Tabs variant={2}>
          <FadeIn title='Points Per Naira'>
            <PointsPerNaira />
          </FadeIn>
          <FadeIn title='Points Per USD'>
            <PointsPerUSD />
          </FadeIn>
        </Tabs>
      </div>
    </FadeIn>
  );
};

const PointsPerNaira = () => {
  const dispatch = useDispatch();
  const [points, setPoints] = useState('');
  const { settings } = useSelector(state => state.state);

  useEffect(() => {
    if (settings) {
      setPoints(settings.find(d => d.name === 'points_per_naira'));
    }
  }, [settings]);

  const handleInputChange = e => {
    const { value } = e.target;
    setPoints({ ...points, value });
  };

  const handleSubmit = () => {
    dispatch(StateActions.updateSettings(points.id, points));
  };

  return (
    <FadeIn className='space-y-6'>
      <div className='flex items-center space-x-2'>
        <div className='font-semibold'>Points Per Naira:</div>
        <div>{points?.value}</div>
      </div>
      <InputBox type={'number'} value={points?.value} onValueChange={handleInputChange} variant={2} placeholder='Point Value' />
      <Button
        className={`${!points?.value ? `pointer-events-none opacity-50` : ``} w-full relative top-2`}
        text={'Update Points Per Naira'}
        onClick={handleSubmit}
      />
    </FadeIn>
  );
};

const PointsPerUSD = () => {
  const dispatch = useDispatch();
  const [points, setPoints] = useState('');
  const { settings } = useSelector(state => state.state);

  useEffect(() => {
    if (settings) {
      setPoints(settings.find(d => d.name === 'points_per_usd'));
    }
  }, [settings]);

  const handleInputChange = e => {
    const { value } = e.target;
    setPoints({ ...points, value });
  };

  const handleSubmit = () => {
    dispatch(StateActions.updateSettings(points.id, points));
  };

  return (
    <FadeIn className='space-y-6'>
      <div className='flex items-center space-x-2'>
        <div className='font-semibold'>Points Per USD:</div>
        <div>{points?.value}</div>
      </div>
      <InputBox type={'number'} value={points?.value} onValueChange={handleInputChange} variant={2} placeholder='Point Value' />
      <Button
        className={`${!points?.value ? `pointer-events-none opacity-50` : ``} w-full relative top-2`}
        text={'Update Points Per USD'}
        onClick={handleSubmit}
      />
    </FadeIn>
  );
};

export default SetPoints;
