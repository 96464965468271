// user
export const CREATE_ADMIN = 'CREATE_ADMIN';
export const SIGNIN = 'SIGNIN';
export const UPDATE_USER = 'UPDATE_USER';
export const SIGNOUT = 'SIGNOUT';

// state
export const DASHBOARD_STATS = 'DASHBOARD_STATS';
export const FETCH_CLIENTS = 'FETCH_CLIENTS';
export const FETCH_PRODUCT_REPORTS = 'FETCH_PRODUCT_REPORTS';
export const FETCH_BRANDS = 'FETCH_BRANDS';
export const FETCH_AGENTS = 'FETCH_AGENTS';
export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const FETCH_CURRENT_CLIENT_PRODUCTS = 'FETCH_CURRENT_CLIENT_PRODUCTS';
export const FETCH_CURRENT_PRODUCT_BATCHES = 'FETCH_CURRENT_PRODUCT_BATCHES';
export const SELECTED_CLIENT = 'SELECTED_CLIENT';
export const SELECTED_PRODUCT = 'SELECTED_PRODUCT';
export const SELECTED_BATCH = 'SELECTED_BATCH';
export const FETCH_CLIENT_BATCHES = 'FETCH_CLIENT_BATCHES';
export const FETCH_CLIENT_SURVEYS = 'FETCH_CLIENT_SURVEYS';
export const FETCH_CLIENT_SURVEY_REWARDS = 'FETCH_CLIENT_SURVEY_REWARDS';
export const SELECTED_SURVEY = 'SELECTED_SURVEY';
export const SELECTED_SURVEY_REWARD = 'SELECTED_SURVEY_REWARD';
export const REMOVE_AGENT = 'REMOVE_AGENT';
export const FETCH_SURVEY_QUESTIONS = 'FETCH_SURVEY_QUESTIONS';
export const FETCH_BRAND_PRODUCTS = 'FETCH_BRAND_PRODUCTS';
export const RESET_BRAND_PRODUCTS = 'RESET_BRAND_PRODUCTS';
export const FETCH_WINNER_LOGS = 'FETCH_WINNER_LOGS';
export const FETCH_LABEL_REQUEST = 'FETCH_LABEL_REQUEST';
export const FETCH_PIN_REQUEST = 'FETCH_PIN_REQUEST';
export const FETCH_GROUPS = 'FETCH_GROUPS';
export const FETCH_ALL_CHEKKIT_REPORT = 'FETCH_ALL_CHEKKIT_REPORT';
export const FETCH_ALL_NAFDAC_REPORT = 'FETCH_ALL_NAFDAC_REPORT';
export const FETCH_NAFDAC_REPORT = 'FETCH_NAFDAC_REPORT';
export const FETCH_NAFDAC_STATS = 'FETCH_NAFDAC_STATS';
export const FETCH_NAFDAC_REPORT_NAME = 'FETCH_NAFDAC_REPORT_NAME';
export const FETCH_NAFDAC_REPORT_ID = 'FETCH_NAFDAC_REPORT_ID';
export const BULK_APPROVE = 'BULK_APPROVE';
export const BULK_REJECT = 'BULK_REJECT';



// response
export const NOTIFY = 'NOTIFY';
export const LOADING = 'LOADING';
