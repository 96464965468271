export const apiUrlAuth = 'https://ci-api-v2.chekkit.app/api/v2';

// export const apiUrlAuth = 'https://ci-api-staging.chekkit.app/api/v2/';

// export const apiUrl = 'https://ci-api-staging.chekkit.app/api/v2/admin';


export const apiUrl = 'https://ci-api-v2.chekkit.app/api/v2/admin';


export const apiUrlNew = 'https://chekkitcidev.azurewebsites.net/api/v2';
