import { ReactComponent as DashboardIcon } from './assets/dashboard.svg';
import { ReactComponent as ClientIcon } from './assets/clients.svg';
import { ReactComponent as PinIcon } from './assets/pins.svg';
import { ReactComponent as ProductReportIcon } from './assets/product-reports.svg';
import { ReactComponent as ProductIcon } from './assets/products.svg';
import { ReactComponent as BrandIcon } from './assets/brands.svg';
import { ReactComponent as SurveyIcon } from './assets/survey.svg';
import { ReactComponent as GenerateReportIcon } from './assets/generate-report.svg';
import { ReactComponent as AgentIcon } from './assets/agents.svg';
import { ReactComponent as RewardWinnersLogIcon } from './assets/reward-winners-log.svg';
import { ReactComponent as ValidateRewardIcon } from './assets/validate-reward.svg';
import { FiTarget } from 'react-icons/fi';
import { FiArchive } from 'react-icons/fi';
const routes = [
  { name: 'Dashboard', href: '/dashboard', icon: DashboardIcon, current: false },
  { name: 'Clients', href: '/clients', icon: ClientIcon, current: false },
  { name: 'Label Request', href: '/label-request', icon: PinIcon, current: false },
  { name: 'Pin Request', href: '/pin-request', icon: PinIcon, current: false },
  { name: 'Pins', href: '/pins', icon: PinIcon, current: false },
  { name: 'Retargeting', href: '/retargeting/groups', icon: FiTarget, current: false },
  { name: 'Product Reports', href: '/product-reports', icon: ProductReportIcon, current: false },
  { name: 'Products', href: '/products', icon: ProductIcon, current: false },
  { name: 'Brands', href: '/brands', icon: BrandIcon, current: false },
  { name: 'Survey', href: '/survey', icon: SurveyIcon, current: false },
  { name: 'Generate Report', href: '/generate-report', icon: GenerateReportIcon, current: false },
  { name: 'Agents', href: '/agents', icon: AgentIcon, current: false },
  { name: 'Reward Winner Log', href: '/reward-winner-log', icon: RewardWinnersLogIcon, current: false },
  { name: 'Validate Reward', href: '/validate-reward', icon: ValidateRewardIcon, current: false },
  { name: 'Rms', href: '/rms', icon: FiArchive, current: false }, 
];

export default routes;
