import React, { useState, useEffect, useRef } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import Heading from '../../../../components/fragments/heading';
import InputBox from '../../../../components/fragments/input-box';
import SelectBox from '../../../../components/fragments/select-box';
import Button from '../../../../components/fragments/button';

import { StateActions } from '../../../../states/actions';

import { formatDate } from '../../../../util';

const formData = {
  photo: '',
  product_name: '',
  producer_name: '',
  product_category: '',
  product_description: '',
  age_range: '',
  id_range: '',
  batch_num: '',
  expiry_date: '',
  production_date: '',
  survey_id: '',
  reward_id: '',
  user_id: '',
};

const EditProduct = () => {
  const imgRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const {
    clients,
    selectedClient,
    selectedProduct,
    selectedSurvey,
    selectedBatch,
    selectedSurveyReward,
    currentClientBatches,
    currentClientProducts,
    currentClientSurveys,
    currentClientSurveyRewards,
  } = useSelector(state => state.state);
  const [data, setData] = useState(formData);
  const [obj, setObj] = useState();

  useEffect(() => {
    if (!selectedClient) {
      history.push('/products/all-products');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedBatch) {
      setData({ ...data, batch_num: selectedBatch.batch_num });
    }
    // eslint-disable-next-line
  }, [selectedBatch]);

  useEffect(() => {
    if (id && currentClientProducts.length > 0) {
      let product = currentClientProducts.find(d => d.id === Number(id));
      setData(product);
      setObj(product);
    }
    // eslint-disable-next-line
  }, [id, currentClientProducts]);

  useEffect(() => {
    if (obj && obj.id && (currentClientBatches.length > 0 || currentClientSurveys.length > 0 || currentClientSurveyRewards.length > 0)) {
      if (!selectedBatch) {
        let batch = currentClientBatches.find(d => Number(d?.batch_num) === Number(obj.batch_num));
        if (batch) dispatch(StateActions.setSelectedBatch(batch.product_name + ' ( ' + batch.batch_num + ' )'));
      } else {
        let survey = currentClientSurveys.find(d => d?.id === Number(selectedBatch?.surveyId));
        let reward = currentClientSurveyRewards.find(d => d?.id === Number(selectedBatch?.rewardId));
        if (survey) dispatch(StateActions.setSelectedSurvey(survey?.title));
        if (reward) dispatch(StateActions.setSelectedSurveyReward(reward?.reward_type + ' ( ' + reward?.reward_value + ' )'));
      }
    }
    // eslint-disable-next-line
  }, [obj, currentClientBatches, currentClientSurveys, currentClientSurveyRewards]);

  useEffect(() => {
    if (selectedClient) {
      dispatch(StateActions.fetchClientBatches(selectedClient.id));
      dispatch(StateActions.fetchClientSurveys(selectedClient.id));
      dispatch(StateActions.fetchClientSurveyRewards(selectedClient.id));
      dispatch(StateActions.fetchClientProducts(selectedClient.id));
    }
    // eslint-disable-next-line
  }, [selectedClient]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleImageChange = e => {
    const { name, files } = e.target;
    setData({ ...data, [name]: files[0], photo_name: files[0].name });
  };

  const handleSubmit = () => {
    let d = { ...data, user_id: selectedClient.id, batch_num: selectedBatch.batch_num, survey_id: selectedSurvey.id, reward_id: selectedSurveyReward.id };
    dispatch(StateActions.updateProduct(d.slug, d)).then(_ => {
      setData(formData);
      history.push('/products/all-products');
    });
  };

  return (
    <FadeIn
      className={`flex-1 flex flex-col justify-start
     h-full w-full space-y-4 overflow-auto flex-shrink-0`}
    >
      <Heading className={`text-gray-700`} title={`Edit Product`} size={3} />

      <FadeIn className='space-y-6 mt-4'>
        <SelectBox
          onValueChange={e => dispatch(StateActions.setSelectedClient(e.target.value))}
          options={['Select option', ...clients.map(d => d.company_rep + ' ( ' + d.username + ' )')]}
          variant={2}
          value={selectedClient ? selectedClient.company_rep + ' ( ' + selectedClient.username + ' )' : ''}
          label='Client'
        />

        <SelectBox
          onValueChange={e => dispatch(StateActions.setSelectedBatch(e.target.value))}
          options={['Select option', ...currentClientBatches.map(d => d.product_name + ' ( ' + d.batch_num + ' )')]}
          variant={2}
          value={selectedBatch ? selectedBatch.product_name + ' ( ' + selectedBatch.batch_num + ' )' : ''}
          label='Client Batches'
        />

        <InputBox
          className={'w-full flex-1'}
          value={data.product_name}
          onValueChange={handleInputChange}
          name='product_name'
          variant={2}
          label='Product Name'
          placeholder='Product Name'
        />

        <InputBox
          className={'w-full flex-1'}
          value={data.producer_name}
          onValueChange={handleInputChange}
          name='producer_name'
          variant={2}
          label='Producer Name'
          placeholder='Producer Name'
        />

        <InputBox
          className={'w-full flex-1'}
          value={data.product_description}
          onValueChange={handleInputChange}
          name='product_description'
          variant={2}
          label='roduct Description'
          placeholder='Producer Name'
        />

        <InputBox
          onClick={() => imgRef.current.click()}
          className={'w-full flex-1 cursor-pointer'}
          value={data.photo_name}
          name='photo'
          variant={2}
          label='Product Image (Optional)'
          readOnly={true}
          placeholder='Product Image'
        />

        <input className='hidden' ref={imgRef} name='photo' type='file' accept='.jpg,.jpeg,.png' onChange={handleImageChange} />

        <InputBox
          className={'w-full flex-1'}
          value={formatDate(new Date(data.production_date), 'yyyy-MM-dd')}
          onValueChange={handleInputChange}
          name='production_date'
          variant={2}
          type='date'
          label='Production Date'
          placeholder='Production Date'
        />

        <InputBox
          className={'w-full flex-1'}
          value={formatDate(new Date(data.expiry_date), 'yyyy-MM-dd')}
          onValueChange={handleInputChange}
          name='expiry_date'
          variant={2}
          type='date'
          label='Expiry Date'
          placeholder='Expiry Date'
        />

        <InputBox
          className={'w-full flex-1'}
          value={data.batch_num}
          onValueChange={handleInputChange}
          name='batch_num'
          variant={2}
          type='number'
          label='Batch Number (Read Only)'
          readOnly={true}
          placeholder='Batch Number'
        />

        <SelectBox
          onValueChange={handleInputChange}
          options={['Select option', '0-2 years', '2-12 years', '13-19 years', '20-29 years', '30-40 years', '40 and above']}
          variant={2}
          value={data.age_range}
          label='Age Range'
          name='age_range'
        />

        <SelectBox
          onValueChange={handleInputChange}
          options={['Select option', 'Organic', 'Synthesized', 'Hybrid']}
          variant={2}
          value={data.product_category}
          label='Product Category'
          name='product_category'
        />

        <SelectBox
          onValueChange={handleInputChange}
          options={['Select option', '10,000 units', '50,000 units', '500,000 units']}
          variant={2}
          value={data.id_range}
          label='ID Range'
          name='id_range'
        />

        <SelectBox
          onValueChange={e => dispatch(StateActions.setSelectedSurvey(e.target.value))}
          options={['Select option', ...currentClientSurveys.map(d => d.title)]}
          variant={2}
          value={selectedSurvey ? selectedSurvey.title : ''}
          label='Surveys'
        />

        <SelectBox
          onValueChange={e => dispatch(StateActions.setSelectedSurveyReward(e.target.value))}
          options={['Select option', ...currentClientSurveyRewards.map(d => d.reward_type + ' ( ' + d.reward_value + ' )')]}
          variant={2}
          value={selectedSurveyReward ? selectedSurveyReward.reward_type + ' ( ' + selectedSurveyReward.reward_value + ' )' : ''}
          label='Survey Rewards'
        />

        <Button
          className={`${
            !selectedClient ||
            !selectedProduct ||
            !selectedSurvey ||
            !selectedSurveyReward ||
            !data.product_name ||
            !data.producer_name ||
            !data.product_description ||
            !data.product_category ||
            !data.age_range ||
            !data.id_range ||
            !data.expiry_date ||
            !data.production_date
              ? `pointer-events-none opacity-50`
              : ''
          } w-full relative top-8`}
          text={'Edit Product'}
          onClick={handleSubmit}
        />
      </FadeIn>
    </FadeIn>
  );
};

export default EditProduct;
