import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useDispatch } from 'react-redux';
import FadeIn from 'react-fade-in/lib/FadeIn';

import Button from '../button';
import Heading from '../heading';
import SelectBox from '../select-box';

import { ReactComponent as RewardClaimed } from '../../../assets/reward-claimed.svg';

import { StateActions } from '../../../states/actions';

const AppDialog = ({ open, setOpen, data, variant, callBack }) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const [filter, setFilter] = useState({
    reward_type: '',
    status: '',
  });
  const [saved, setSaved] = useState(true);

  useEffect(() => {
    if (!open && variant === 'filter') {
      setFilter({
        reward_type: '',
        status: '',
      });
    }
    if (open && variant === 'filter') {
      setFilter(data);
    }
    // eslint-disable-next-line
  }, [open]);

  const handleSelectChange = e => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
    setSaved(false);
  };

  const sendMessage = () => {
    if (variant === 'message-brand-authenticators') {
      dispatch(StateActions.smsBrandAuthenticators(data?.id, { message })).then(_ => setMessage({ message: '' }));
    }

    if (variant === 'message-product-authenticators') {
      dispatch(StateActions.smsProductAuthenticators(data?.id, { message })).then(_ => setMessage({ message: '' }));
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='fixed z-10 inset-0 overflow-y-auto' onClose={setOpen}>
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
            &#8203;
          </span> */}
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div>
              {variant === 'message-brand-authenticators' && (
                <div className='inline-block bg-white px-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all mb-8 mt-32 align-middle max-w-2xl w-full p-6'>
                  <FadeIn className={`w-full space-y-10 pb-6`}>
                    <Heading className={`text-gray-700 capitalize`} title={`message users that authenticated ${data?.username} products`} size={3} />
                    <textarea
                      style={{ borderColor: '#D7EEFF' }}
                      className='p-4 rounded-t-lg border-b-2 bg-gray-400 bg-opacity-10 text-gray-700 flex items-center space-x-4 overflow-hidden w-full ring-0 outline-none resize-none'
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                      name='message'
                      placeholder='Message'
                      id='message'
                      cols='30'
                      rows='10'
                    ></textarea>
                    <Button className={`${!message ? 'pointer-events-none opacity-50' : ''} w-full`} onClick={sendMessage} text={'Send Message'} />
                  </FadeIn>
                </div>
              )}
              {variant === 'message-product-authenticators' && (
                <div className='inline-block bg-white px-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all mb-8 mt-32 align-middle max-w-lg w-full p-6'>
                  <FadeIn className={`w-full space-y-10 pb-6`}>
                    <Heading className={`text-gray-700 capitalize`} title={`message users that authenticated ${data?.product_name}`} size={3} />
                    <textarea
                      style={{ borderColor: '#D7EEFF' }}
                      className='p-4 rounded-t-lg border-b-2 bg-gray-400 bg-opacity-10 text-gray-700 flex items-center space-x-4 overflow-hidden w-full ring-0 outline-none resize-none'
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                      name='message'
                      placeholder='Message'
                      id='message'
                      cols='30'
                      rows='10'
                    ></textarea>
                    <Button className={`${!message ? 'pointer-events-none opacity-50' : ''} w-full`} onClick={sendMessage} text={'Send Message'} />
                  </FadeIn>
                </div>
              )}
              {variant === 'filter' && (
                <div className='inline-block bg-white px-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all mb-8 mt-32 align-middle max-w-lg w-full p-6'>
                  <FadeIn className={`w-full space-y-10 pb-6`}>
                    <div className='flex items-center justify-between space-x-4'>
                      <Heading className={`text-gray-700 capitalize`} title={`Filter`} size={3} />
                      <div
                        onClick={() => {
                          setFilter({
                            reward_type: '',
                            status: '',
                          });
                          setSaved(false);
                        }}
                        className='text-gray-500 cursor-pointer text-sm'
                      >
                        Clear
                      </div>
                    </div>

                    <SelectBox
                      variant={2}
                      options={['Select option', 'Airtime', 'Merchandize', 'Loyalty', 'Raffle']}
                      name='reward_type'
                      value={filter.reward_type}
                      onValueChange={handleSelectChange}
                    />

                    <SelectBox
                      variant={2}
                      options={['Select option', 'Collected', 'Uncollected']}
                      name='status'
                      value={filter.status}
                      onValueChange={handleSelectChange}
                    />

                    <Button
                      className={`${saved ? 'pointer-events-none opacity-50' : ''} w-full`}
                      onClick={() => {
                        callBack(filter);
                        setSaved(true);
                        setOpen(false);
                      }}
                      text={'Save'}
                    />
                  </FadeIn>
                </div>
              )}
              {variant === 'reward-claimed' && (
                <div className='inline-block bg-white px-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all mb-8 mt-32 align-middle max-w-lg w-full p-6'>
                  <FadeIn className={`w-full space-y-6 pb-6 flex flex-col items-center`}>
                    <RewardClaimed />

                    <Heading className={`text-gray-700 font-semibold capitalize`} title={`Reward Redeemed`} size={3} />

                    <div className={`text-gray-600`}>Reward has been validated successfully</div>

                    <Button
                      className={`w-full`}
                      onClick={() => {
                        setOpen(false);
                        callBack(false);
                      }}
                      variant='secondary'
                      text={'Close'}
                    />
                  </FadeIn>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AppDialog;
