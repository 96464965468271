import {
  DASHBOARD_STATS,
  FETCH_CLIENTS,
  FETCH_PRODUCT_REPORTS,
  FETCH_BRANDS,
  FETCH_AGENTS,
  FETCH_CURRENT_CLIENT_PRODUCTS,
  FETCH_CURRENT_PRODUCT_BATCHES,
  SELECTED_CLIENT,
  SELECTED_PRODUCT,
  SELECTED_BATCH,
  FETCH_CLIENT_BATCHES,
  FETCH_CLIENT_SURVEYS,
  FETCH_CLIENT_SURVEY_REWARDS,
  SELECTED_SURVEY,
  SELECTED_SURVEY_REWARD,
  REMOVE_AGENT,
  FETCH_SETTINGS,
  FETCH_SURVEY_QUESTIONS,
  FETCH_BRAND_PRODUCTS,
  RESET_BRAND_PRODUCTS,
  FETCH_WINNER_LOGS,
  FETCH_LABEL_REQUEST,
  FETCH_PIN_REQUEST,
  FETCH_GROUPS,
  FETCH_ALL_CHEKKIT_REPORT,
  FETCH_ALL_NAFDAC_REPORT,
  FETCH_NAFDAC_STATS,
  FETCH_NAFDAC_REPORT_NAME,
  FETCH_NAFDAC_REPORT_ID,
  BULK_REJECT
} from '../type';
import { Service } from '../../services';
import { notify, loading } from './response';

export const fetchDashboardStats = () => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.getDashboardStats();

    if (res.data.status) {
      // dispatch(loading(false));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }

    dispatch({
      type: DASHBOARD_STATS,
      payload: res.data.data.stats,
    });
    // return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const fetchClients = () => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.getAllClients();

    if (res.data.status) {
      // dispatch(loading(false));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }

    dispatch({
      type: FETCH_CLIENTS,
      payload: res.data.data,
    });
    // return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const fetchProductReports = () => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.getProductReports();

    if (res.data.status) {
      // dispatch(loading(false));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }

    dispatch({
      type: FETCH_PRODUCT_REPORTS,
      payload: res.data.data,
    });
    // return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const fetchBrands = (perPage, page) => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.getBrands(perPage, page);

    if (res.data.status) {
      dispatch(loading(false));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }

    dispatch({
      type: FETCH_BRANDS,
      payload: res.data.data?.brands,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const fetchAgents = () => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.getAgents();

    if (res.data.status) {
      // dispatch(loading(false));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }

    dispatch({
      type: FETCH_AGENTS,
      payload: res.data.data,
    });
    // return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const fetchSettings = () => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.getSettings();

    if (res.data.status) {
      // dispatch(loading(false));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }

    dispatch({
      type: FETCH_SETTINGS,
      payload: res.data.data,
    });
    // return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const fetchClientProducts = clientId => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.getClientProducts(clientId);

    if (res.data.status) {
      dispatch(loading(false));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }

    dispatch({
      type: FETCH_CURRENT_CLIENT_PRODUCTS,
      payload: res.data.data.products,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const fetchProductBatches = productId => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.getProductBatches(productId);

    if (res.data.status) {
      dispatch(loading(false));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }

    dispatch({
      type: FETCH_CURRENT_PRODUCT_BATCHES,
      payload: res.data.data.productBatches,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const setSelectedClient = string => async dispatch => {
  dispatch({
    type: SELECTED_CLIENT,
    payload: string,
  });
};

export const setSelectedProduct = string => async dispatch => {
  dispatch({
    type: SELECTED_PRODUCT,
    payload: string,
  });
};

export const setSelectedBatch = string => async dispatch => {
  dispatch({
    type: SELECTED_BATCH,
    payload: string,
  });
};

export const reassignPins = data => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.reassignPins(data);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Pins Reassigned', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const fetchPinBySn = pin => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.getPinBySerialNumber({ serial_value: pin });

    if (res.data.status) {
      dispatch(loading(false));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const fetchPinByPart = pin => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.getPinsBySequence({ sequence_value: pin });

    if (res.data.status) {
      dispatch(loading(false));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const checkPinValidity = data => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.checkPin(data);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Pin Valid', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const updatePinStatus = data => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.updatePin(data);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Pin Status Updated', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const fetchClientBatches = clientId => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.getBatches(clientId);

    if (res.data.status) {
      dispatch(loading(false));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }

    dispatch({
      type: FETCH_CLIENT_BATCHES,
      payload: res.data.data.batch,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const fetchClientSurveys = clientId => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.getClientSurveys(clientId);

    if (res.data.status) {
      dispatch(loading(false));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }

    dispatch({
      type: FETCH_CLIENT_SURVEYS,
      payload: res.data.data.surveys,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const fetchClientSurveyRewards = clientId => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.getClientSurveyRewards(clientId);

    if (res.data.status) {
      dispatch(loading(false));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }

    dispatch({
      type: FETCH_CLIENT_SURVEY_REWARDS,
      payload: res.data.data.rewards,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const setSelectedSurvey = string => async dispatch => {
  dispatch({
    type: SELECTED_SURVEY,
    payload: string,
  });
};

export const setSelectedSurveyReward = string => async dispatch => {
  dispatch({
    type: SELECTED_SURVEY_REWARD,
    payload: string,
  });
};

export const addBatch = data => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.createBatch(data);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Batch Created', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const addProduct = data => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.addProducts(data);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Product Created', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const updateBatch = (batch_num, data) => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.updateBatch(batch_num, data);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Batch Updated', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const updateProduct = (slug, data) => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.updateProducts(slug, data);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Product Updated', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const addAgent = data => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.createAgent(data);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Agent Created', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    dispatch(fetchAgents());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const removeAgent = agentId => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.deleteAgent(agentId);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Agent Deleted', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    dispatch({
      type: REMOVE_AGENT,
      payload: agentId,
    });
    dispatch(fetchAgents());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const updateAgent = (agentId, data) => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.updateAgent(agentId, data);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Agent Updated', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    dispatch(fetchAgents());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const generateReport = (from, to, batchId) => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.generateReport(from, to, batchId);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Report Generated', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const updateSettings = (settingId, data) => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.updateSettings(settingId, data);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Settings Updated', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    dispatch(fetchSettings());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const addSurveyReward = data => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.createSurveyReward(data);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Reward Created', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const addSurvey = data => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.createSurvey(data);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Survey Created', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const updateSurvey = (slug, data) => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.updateSurvey(slug, data);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Survey Updated', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const fetchSurveyQuestions = id => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.getSurveyQuestions(id);

    if (res.data.status) {
      dispatch(loading(false));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    dispatch({
      type: FETCH_SURVEY_QUESTIONS,
      payload: res.data.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const fetchBrandProducts = (brandId, perPage, page) => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.getBrandProducts(brandId, perPage, page);

    if (res.data.status) {
      dispatch(loading(false));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }

    dispatch({
      type: FETCH_BRAND_PRODUCTS,
      payload: res.data.data?.products,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const smsBrandAuthenticators = (id, data) => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.sendSMSBrandAuthenticators(id, data);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Message Sent', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const smsProductAuthenticators = (id, data) => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.sendSMSProductAuthenticators(id, data);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Message Sent', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const resetBrandProducts = () => async dispatch => {
  dispatch({
    type: RESET_BRAND_PRODUCTS,
  });
};

export const fetchWinnerLogs = (perPage, page) => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.getRewardWinnerLogs(perPage, page);

    if (res.data.status) {
      dispatch(loading(false));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }

    dispatch({
      type: FETCH_WINNER_LOGS,
      payload: res.data.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const validateWinner = phoneNumber => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.validateWinner(phoneNumber);

    if (res.data.status && res.data.data) {
      dispatch(notify({ title: 'Success', message: 'Reward Retrieved', type: 'success', loading: false }));
    } else if (res.data.status && !res.data.data) {
      dispatch(notify({ title: '404', message: 'Reward Not Found', type: 'default', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const redeemReward = (winnerId, data) => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.redeemReward(winnerId, data);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Reward Redeemed', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const fetchLabelRequests = (perPage, page) => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.getLabelRequests(perPage, page);

    if (res.data.status) {
      dispatch(loading(false));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }

    dispatch({
      type: FETCH_LABEL_REQUEST,
      payload: res?.data?.data?.rows,
    });
    return Promise.resolve(res?.data?.data?.rows);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const updateLabelRequestStatus = (orderId, data) => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.updateLabelRequestStatus(orderId, data);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Status updated', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    dispatch(fetchLabelRequests(1000000, 1));
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const assignLabelRequest = data => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.assignLabelRequest(data);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Labels assigned', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const fetchPinRequests = (userId, perPage, page) => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.getPinRequests(userId, perPage, page);

    if (res.data.status) {
      dispatch(loading(false));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }

    dispatch({
      type: FETCH_PIN_REQUEST,
      payload: res?.data?.data?.rows,
    });
    return Promise.resolve(res?.data?.data?.rows);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const updatePinRequestStatus = (userId, orderId, status) => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.updatePinRequestStatus(orderId, status);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Status updated', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    dispatch(fetchPinRequests(userId, 1000000, 1));
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const sendBulkMessage = data => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.sendBulkMessage(data);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Message sent to contact group', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const bulkUploadContacts = data => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.bulkUploadContacts(data);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Contacts uploaded', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const createGroup = data => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.createGroup(data);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Group created', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    dispatch(fetchGroups());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const fetchGroups = () => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.getGroups();

    if (res.data.status) {
      dispatch(loading(false));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }

    dispatch({
      type: FETCH_GROUPS,
      payload: res?.data?.data,
    });
    return Promise.resolve(res?.data?.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const fetchGroupContacts = id => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.getGroupContacts(id);

    if (res.data.status) {
      dispatch(loading(false));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }

    return Promise.resolve(res?.data?.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const fetchAllNafdacReports = (perPage, page) => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.getAllNafdacReport(perPage, page);


    if (res.data.status) {
      dispatch(loading(false));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }

    dispatch({
      type: FETCH_ALL_NAFDAC_REPORT,
      payload: res.data.data?.reports,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const fetchAllChekkitReports = (page, perPage) => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.getAllChekkitReport(page, perPage);


    if (res.data.status) {
      dispatch(loading(false));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }

    dispatch({
      type: FETCH_ALL_CHEKKIT_REPORT,
      payload: res.data?.data?.reports,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const fetchNafdacReport = id => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.getNafdacReportById(id);

    if (res.data.status) {
      dispatch(loading(false));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    dispatch({
      type: FETCH_NAFDAC_REPORT_ID,
      payload: res.data.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const fetchNafdacReportByName = productname => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.getNafdacReportByName(productname);

    if (res.data.status) {
      dispatch(loading(false));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    dispatch({
      type: FETCH_NAFDAC_REPORT_NAME,
      payload: res.data.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const fetchNafdacStats = () => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.getNafdacStats();

    if (res.data.status) {
      // dispatch(loading(false));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }

    dispatch({
      type: FETCH_NAFDAC_STATS,
      payload: res.data?.data,
    });
    // return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const bulkReject = data => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.nafdacBulkReject(data);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Rejected', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    dispatch({
      type: BULK_REJECT,
      payload: data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const bulkApprove = data => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.nafdacBulkApprove(data);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Report has been submitted to NAFDAC', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const createComment =  data => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.createNafdacReportComment(data);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Comment Added', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const updateComment = (commentId, data) => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.editNafdacReportComment(commentId, data);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Comment Updated', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const updateNafdacReportsStatus = (slug, status) => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.updateNafdacReportStatus(slug, status);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Report Status Updated', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

const StateActions = {
  fetchDashboardStats,
  fetchClients,
  fetchProductReports,
  fetchBrands,
  fetchAgents,
  fetchSettings,
  fetchClientProducts,
  fetchProductBatches,
  setSelectedClient,
  setSelectedProduct,
  setSelectedBatch,
  reassignPins,
  fetchPinBySn,
  fetchPinByPart,
  checkPinValidity,
  updatePinStatus,
  fetchClientBatches,
  fetchClientSurveys,
  fetchClientSurveyRewards,
  setSelectedSurvey,
  setSelectedSurveyReward,
  addBatch,
  addProduct,
  updateBatch,
  updateProduct,
  addAgent,
  removeAgent,
  updateAgent,
  generateReport,
  updateSettings,
  addSurveyReward,
  addSurvey,
  updateSurvey,
  fetchSurveyQuestions,
  fetchBrandProducts,
  smsBrandAuthenticators,
  smsProductAuthenticators,
  resetBrandProducts,
  fetchWinnerLogs,
  validateWinner,
  redeemReward,
  fetchLabelRequests,
  updateLabelRequestStatus,
  assignLabelRequest,
  fetchPinRequests,
  updatePinRequestStatus,
  sendBulkMessage,
  bulkUploadContacts,
  createGroup,
  fetchGroups,
  fetchGroupContacts,
  fetchAllNafdacReports,
  fetchAllChekkitReports,
  fetchNafdacStats,
  fetchNafdacReport,
  fetchNafdacReportByName,
  bulkReject,
  bulkApprove,
  updateComment,
  createComment,
  updateNafdacReportsStatus
};

export default StateActions;
