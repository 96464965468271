import { combineReducers } from 'redux';
import response from './response';
import user from './user';
import state from './state';

export default combineReducers({
  response,
  user,
  state,
});
