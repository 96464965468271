import http, { httpAuth } from '../http';
import { httpRms } from '../http';

// User
export const createAdmin = async data => {
  return http().post('/superadmin/store', data);
};

export const signIn = async data => {
  return httpAuth().post('/auth/signin', data);
};

// Dashboard
export const getDashboardStats = () => {
  return http().get('/get-dashboard-statistics');
};

// Clients
export const getAllClients = () => {
  return http().get('/list-users');
};

export const getClientProducts = id => {
  return http().get(`/get-user-products?id=${id}`);
};

// Pins
export const reassignPins = data => {
  return http().post('/reassign-pins', data);
};

export const checkPin = data => {
  return http().post('/check-pin', data);
};

export const updatePin = data => {
  return http().post('/update-pin-status', data);
};
 
export const getPinsBySequence = (data, query = '') => {
  return http().get(`/pins/${data.sequence_value}?${query}`);
};

export const getPinBySerialNumber = data => {
  return http().get(`/pin/${data.serial_value}`);
};

// Product Reports
export const getProductReports = () => {
  return http().get('/get-product-reports');
};

// Batch
export const createBatch = data => {
  return http().post(`/sub-products`, data);
};

export const getBatches = clientId => {
  return http().get(`/sub-products/${clientId}`);
};

export const getProductBatches = id => {
  return http().get(`/get-product-batches?id=${id}`);
};

export const updateBatch = (batch_num, data) => {
  return http().put(`/sub-products/${batch_num}`, data);
};

// Products
export const getProductDetails = id => {
  return http().get(`/products/${id}`);
};

export const getProducts = (id, per_page, page) => {
  return http().get(`/products?page=${page}&per_page=${per_page}&user_id=${id}`);
};

export const addProducts = data => {
  return http().post('/products', data);
};

export const updateProducts = (slug, data) => {
  return http().put(`/products/${slug}`, data);
};

// Brands
export const getBrands = (per_page, page) => {
  return http().get(`/brands?per_page=${per_page}&page=${page}`);
};

export const getBrandProducts = (id, per_page, page) => {
  return http().get(`/brands/${id}/products?per_page=${per_page}&page=${page}`);
};

export const sendSMSProductAuthenticators = (id, data) => {
  return http().post(`/products/${id}/sms`, data);
};

export const sendSMSBrandAuthenticators = (id, data) => {
  return http().post(`/brands/${id}/sms`, data);
};

// Survey
export const createSurvey = data => {
  return http().post(`/surveys`, data);
};

export const getSurvey = slug => {
  return http().get(`/surveys/${slug}`);
};

export const getSurveyQuestions = surveyId => {
  return http().get(`/survey-questions/${surveyId}`);
};

export const updateSurvey = (slug, data) => {
  return http().put(`/surveys/${slug}`, data);
};

export const getClientSurveys = id => {
  return http().get(`/get-user-surveys/${id}`);
};

// Rewards
export const createSurveyReward = data => {
  return http().post(`/rewards`, data);
};

export const getClientSurveyRewards = id => {
  return http().get(`/user-survey-rewards/${id}`);
};

// Report Generation
export const generateReport = (from, to, subProductId) => {
  return http().get(`/scan-history/report/${subProductId}?from=${from}&to=${to}`);
};

// Agents
export const createAgent = data => {
  return http().post(`/agents`, data);
};

export const getAgents = () => {
  return http().get(`/agents`);
};

export const getAgent = agent_id => {
  return http().get(`/agents/${agent_id}`);
};

export const updateAgent = (agent_id, data) => {
  return http().put(`/agents/${agent_id}`, data);
};

export const deleteAgent = agent_id => {
  return http().delete(`/agents/${agent_id}`);
};

// Settings
export const getSettings = () => {
  return http().get(`/settings`);
};

export const updateSettings = (id, data) => {
  return http().put(`/settings/${id}`, data);
};

// Reward Winner Logs
export const getRewardWinnerLogs = (perPage, page) => {
  return http().get(`/reward-winners-log?page=${page}&per_page=${perPage}`);
};

export const validateWinner = phoneNumber => {
  return http().get(`/winner-check?phoneNumber=${phoneNumber}`);
};

export const redeemReward = (winnerId, data) => {
  return http().put(`/update-status/${winnerId}`, data);
};

// Label request
export const getLabelRequests = (perPage, page) => {
  return http().get(`/label-request?page=${page}&per_page=${perPage}`);
};

export const updateLabelRequestStatus = (orderId, data) => {
  return http().put(`/label-request/update-status/${orderId}`, data);
};

export const assignLabelRequest = data => {
  return http().post(`/assign-labels`, data);
};

// Pin request
export const getPinRequests = (userId, perPage, page) => {
  return http().get(`/pin-assigments-request/${userId}?page=${page}&per_page=${perPage}`);
};

export const updatePinRequestStatus = (orderId, status) => {
  return http().put(`/pin-assigments-status/${orderId}?status=${status}`);
};

// Retargeting
export const getGroups = () => {
  return http().get(`/retargeting/groups`);
};

export const getGroupContacts = id => {
  return http().get(`/retargeting/groups/contacts/${id}`);
};

export const createGroup = data => {
  return http().post(`/retargeting/groups`, data);
};

export const bulkUploadContacts = data => {
  return http().post(`/retargeting`, data);
};

export const sendBulkMessage = data => {
  return http().post(`/retargeting/groups/message`, data);
};

// Report Management System
export const getAllNafdacReport = (per_page, page) => {
  return httpRms().get(`/products/nafdac-report/all?per_page=${per_page}&page=${page}`);
};

export const getAllChekkitReport = (page, per_page) => {
  return httpRms().get(`/admin/get-product-reports?page=${page}&per_page=${per_page}`);
};

export const getNafdacStats = () => {
  return httpRms().get('/products/nafdac-report/metrics');
};

export const getNafdacReportById = id => {
  return httpRms().get(`/products/nafdac-report/${id}`);
};

export const getNafdacReportByName = productname => {
  return httpRms().get(`/products/nafdac-report/${productname}`);
};

export const nafdacBulkApprove = data => {
  return httpRms().post(`/products/report/bulk-approve`, data);
};

export const nafdacBulkReject = data => {
  return httpRms().post(`/products/report/bulk-reject`, data);
};

export const updateNafdacReportStatus = data => {
  return httpRms().put(`/products/nafdac-report`, data);
};

export const editNafdacReportComment = data => {
  return httpRms().put(`/products/nafdac-report/comment`, data);
};

export const createNafdacReportComment = data => {
  return httpRms().post(`/products/nafdac-report/comment`, data);
};



const Service = {
  createAdmin,
  signIn,
  getDashboardStats,
  getAllClients,
  getClientProducts,
  createBatch,
  updateBatch,
  getBatches,
  getProductBatches,
  getProducts,
  getProductReports,
  addProducts,
  updateProducts,
  reassignPins,
  checkPin,
  updatePin,
  getPinsBySequence,
  getPinBySerialNumber,
  getBrands,
  getBrandProducts,
  sendSMSProductAuthenticators,
  sendSMSBrandAuthenticators,
  createSurvey,
  getSurvey,
  getSurveyQuestions,
  updateSurvey,
  getClientSurveys,
  createSurveyReward,
  getClientSurveyRewards,
  generateReport,
  createAgent,
  getAgents,
  getAgent,
  updateAgent,
  deleteAgent,
  getSettings,
  updateSettings,
  getRewardWinnerLogs,
  validateWinner,
  redeemReward,
  getLabelRequests,
  updateLabelRequestStatus,
  assignLabelRequest,
  getPinRequests,
  updatePinRequestStatus,
  getGroups,
  getGroupContacts,
  createGroup,
  bulkUploadContacts,
  sendBulkMessage,
  getAllNafdacReport,
  getAllChekkitReport,
  getNafdacStats,
  getNafdacReportById,
  getNafdacReportByName,
  nafdacBulkApprove,
  nafdacBulkReject,
  updateNafdacReportStatus,
  editNafdacReportComment,
  createNafdacReportComment
};

export default Service;
