import React, { useEffect, useState } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useHistory } from 'react-router-dom';
import { FiLoader } from 'react-icons/fi';
import { useSelector } from 'react-redux';

import Heading from '../../../../components/fragments/heading';

const data = [
  { title: 'Clients', value: '0', href: '/clients' },
  { title: 'Total Signups', value: '0' },
  { title: 'Generated Pins', value: '0' },
  { title: 'Authenticated Pins', value: '0' },
  { title: 'Total Products', value: '0' },
];

const Stats = () => {
  const response = useSelector(state => state.response);
  const { stats, clients } = useSelector(state => state.state);
  const [apiStats, setApiStats] = useState(data);
  const history = useHistory();

  useEffect(() => {
    if (stats) {
      setApiStats(s =>
        s.map(d => {
          if (d.title === 'Total Signups') d.value = stats.users;
          if (d.title === 'Generated Pins') d.value = stats.pins;
          if (d.title === 'Authenticated Pins') d.value = stats.scans;
          if (d.title === 'Total Products') d.value = stats.products;
          return d;
        }),
      );
    }
  }, [stats]);
 
  useEffect(() => {
    if (clients) {
      setApiStats(s =>
        s.map(d => {
          if (d.title === 'Clients') d.value = clients.length;
          return d;
        }),
      );
    }
  }, [clients]);

  return (
    <FadeIn
      className={`flex-1 flex flex-col justify-start
     h-full w-full space-y-4 overflow-auto flex-shrink-0`}
    >
      <Heading className={`text-gray-700`} title={`Stats`} size={3} />
      <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6'>
        {apiStats.map((d, i) => {
          return (
            <div
              onClick={() => {
                if (!d.href) return;
                history.push(d.href);
              }}
              key={i}
              className={`bg-blue-50 rounded-lg text-gray-700 flex-1 w-full ${d.href && 'cursor-pointer'}`}
            >
              <div className='p-6 pb-0 flex items-center justify-between space-x-6 w-full'>
                <div className='text-gray-700 w-full'>{d.title}</div>
              </div>

              <div className='p-6 pb-0 font-semibold text-4xl my-6'>
                <span>{d?.value?.toLocaleString()}</span>
                <span>{(response.loading || !stats || !clients) && <FiLoader className='animate-spin' size={16} />}</span>
              </div>
            </div>
          );
        })}
      </div>
    </FadeIn>
  );
};

export default Stats;
