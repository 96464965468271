import { Switch, Route } from 'react-router-dom';

import Dashboard from '../../pages/dashboard/dashboard';
import Clients from '../../pages/dashboard/clients';
import Pins from '../../pages/dashboard/pins';
import Retargeting from '../../pages/dashboard/retargeting';
import LabelModuleBase from '../../pages/dashboard/label-request';
import PinModuleBase from '../../pages/dashboard/pin-request';
import ProductReports from '../../pages/dashboard/product-reports';
import Products from '../../pages/dashboard/products';
import Brands from '../../pages/dashboard/brands';
import Survey from '../../pages/dashboard/survey';
import GenerateReport from '../../pages/dashboard/generate-report';
import Agents from '../../pages/dashboard/agents';
import RewardWinnerLog from '../../pages/dashboard/reward-winner-log';
import ValidateReward from '../../pages/dashboard/validate-reward';
import Settings from '../../pages/dashboard/settings';
import Rms from '../../pages/dashboard/rms';

const routes = [
  { path: '/dashboard', component: Dashboard },
  { path: '/clients', component: Clients },
  { path: '/pins', component: Pins },
  { path: '/retargeting/groups', component: Retargeting },
  { path: '/label-request', component: LabelModuleBase },
  { path: '/pin-request', component: PinModuleBase },
  { path: '/product-reports', component: ProductReports },
  { path: '/products', component: Products },
  { path: '/brands', component: Brands },
  { path: '/survey', component: Survey },
  { path: '/generate-report', component: GenerateReport },
  { path: '/agents', component: Agents },
  { path: '/reward-winner-log', component: RewardWinnerLog },
  { path: '/validate-reward', component: ValidateReward },
  { path: '/settings', component: Settings },
  { path: '/rms', component: Rms },
];

function Content() {
  return (
    <Switch exitBeforeEnter initial={false}>
      {routes.map((route, i) => {
        return (
          <Route key={i} path={route.path}>
            <route.component />
          </Route>
        );
      })}
    </Switch>
  );
}

export default Content;
