import React, { useState, useEffect } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { Route, Switch, Redirect, useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FiLoader } from 'react-icons/fi';

import SetPoints from './set-points';
import AppInfo from './app-info';

const routes = [
  { name: 'App Info', path: '/settings/app-info', component: AppInfo, current: false },
  { name: 'Set Points', path: '/settings/set-points', component: SetPoints, current: false },
];

const Settings = () => {
  const response = useSelector(state => state.response);
  const location = useLocation();
  const history = useHistory();
  const [items, setItems] = useState(routes);
  const user = useSelector(state => state.user);

  useEffect(() => {
    if (routes.length > 0 && location.pathname.split('/').length <= 2) {
      history.push(routes[0].path);
    }
    if (user?.membership_type !== 'admin') {
      setItems(items =>
        items
          .filter(d => d.name === 'App Info')
          .map(item => {
            item.current = item.name
              .replace(' ', '-')
              .replace('&', '')
              .replace(' ', '')
              .toLocaleLowerCase()
              .includes(location.pathname.split('/')[location.pathname.split('/').length - 1].toLocaleLowerCase());
            return item;
          }),
      );
    } else {
      setItems(
        items.map(item => {
          item.current = item.name
            .replace(' ', '-')
            .replace('&', '')
            .replace(' ', '')
            .toLocaleLowerCase()
            .includes(location.pathname.split('/')[location.pathname.split('/').length - 1].toLocaleLowerCase());
          return item;
        }),
      );
    }
    // eslint-disable-next-line
  }, [location]);

  return (
    <>
      {response.loading && (
        <div className='flex justify-center p-4'>
          <FiLoader className='animate-spin' size={16} />
        </div>
      )}
      <div className='flex justify-between h-full w-full items-center space-x-12 px-4 md:px-12 py-6 min-h-screen'>
        <Switch>
          {items.map((route, i) => {
            return (
              <Route exact key={i} path={route.path}>
                <route.component />
              </Route>
            );
          })}
          <Redirect to='/404' />
        </Switch>
        {items.length > 1 && (
          <FadeIn
            className={`hidden w-48 md:flex flex-col justify-between items-start
        py-4 h-full border-l border-gray-200`}
          >
            <div className='space-y-6'>
              {items.map((item, idx) => {
                return (
                  <div
                    onClick={() => history.push(typeof item.path === 'string' ? item.path : item.path[0])}
                    key={idx}
                    style={{ marginLeft: '-1px' }}
                    className={`${item.current ? `border-l-2 border-gray-800` : ``} text-gray-600 px-4 text-sm cursor-pointer`}
                  >
                    {item.name}
                  </div>
                );
              })}
            </div>
          </FadeIn>
        )}
      </div>
    </>
  );
};

export default Settings;
