import React from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';

import { useSelector } from 'react-redux';

import Heading from '../../../../components/fragments/heading';
import AgentTable from '../../../../components/fragments/data-table';

const AllAgents = () => {
  const { agents } = useSelector(state => state.state);
  return (
    <FadeIn
      className={`flex-1 flex flex-col justify-start
     h-full w-full space-y-4 overflow-auto flex-shrink-0`}
    >
      <Heading className={`text-gray-700`} title={`All Agents`} size={3} />

      <AgentTable data={agents} variant='all-agents' perPage={6} />
    </FadeIn>
  );
};

export default AllAgents;
