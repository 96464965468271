import { CREATE_ADMIN, SIGNIN, UPDATE_USER, SIGNOUT } from '../type';
import { Service } from '../../services';
import { notify, loading } from './response';

export const createAdmin = data => async dispatch => {
  dispatch(loading(true));
  try {
    const res = await Service.createAdmin(data);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Admin Created', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }

    dispatch({
      type: CREATE_ADMIN,
      // payload: data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const signIn = data => async dispatch => {
  dispatch(loading(true));

  try {
    const res = await Service.signIn(data);

    if (res.data.status) {
      dispatch(notify({ title: 'Success', message: 'Login successful', type: 'success', loading: false }));
    } else {
      dispatch(notify({ title: 'Error', message: res?.data?.message || 'An Error Occurred', type: 'danger', loading: false }));
    }

    let user = { ...res.data.data.user, access_token: res.data.data.token };

    dispatch({
      type: SIGNIN,
      payload: user,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(
      notify({
        title: err.name,
        message: err.response?.data?.error || err.response?.data?.message || err.message || 'An Error Occurred',
        type: 'danger',
        loading: false,
      }),
    );
    return Promise.reject(err);
  }
};

export const updateUser = data => async dispatch => {
  dispatch({
    type: UPDATE_USER,
    payload: data,
  });
  return Promise.resolve();
};

export const signOut = () => async dispatch => {
  dispatch({
    type: SIGNOUT,
  });
};

const UserActions = {
  createAdmin,
  signIn,
  updateUser,
  signOut,
};

export default UserActions;
