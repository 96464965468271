import React, { useEffect, useState } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BiSlider } from 'react-icons/bi';

import Heading from '../../../../components/fragments/heading';
import Button from '../../../../components/fragments/button';
import Dialog from '../../../../components/fragments/dialog';

import RewardWinnerTable from '../../../../components/fragments/data-table';

import { StateActions } from '../../../../states/actions';

const RewardWinnerLog = () => {
  const dispatch = useDispatch();
  const [perPage, setPerPage] = useState(6);
  const [num, setNum] = useState(6);
  // eslint-disable-next-line
  const [totalCount, setTotalCount] = useState(0);
  // eslint-disable-next-line
  const [activePage, setActivePage] = useState(1);
  const { rewardWinnersLogs } = useSelector(state => state.state);
  const user = useSelector(state => state.user);
  const history = useHistory();
  const [greeting, setGreeting] = useState('Good Day,');
  const [open, setOpen] = useState(false);
  const [rewardWinners, setRewardWinners] = useState('');
  const [filter, setFilter] = useState({
    reward_type: '',
    status: '',
  });

  useEffect(() => {
    if (rewardWinnersLogs && rewardWinnersLogs?.winners?.length > 0) {
      if (rewardWinnersLogs.winners.length <= num) {
        setNum(rewardWinnersLogs.winners.length);
        setPerPage(rewardWinnersLogs.winners.length);
      }
      let data;
      if (!filter.reward_type && !filter.status) {
        data = [...rewardWinnersLogs.winners];
      }
      if (filter.reward_type) {
        data = rewardWinnersLogs.winners.filter(d => d.reward_type === filter.reward_type);
      }
      if (filter.status) {
        data = rewardWinnersLogs.winners.filter(d => {
          if (filter.status === 'Collected') return d.status === 1;
          if (filter.status === 'Uncollected') return d.status === 0;
          return false;
        });
      }
      if (filter.reward_type && filter.status) {
        data = rewardWinnersLogs.winners.filter(d => {
          if (filter.status === 'Collected') return d.status === 1 && d.reward_type === filter.reward_type;
          if (filter.status === 'Uncollected') return d.status === 0 && d.reward_type === filter.reward_type;
          return false;
        });
      }
      setRewardWinners(data);
    }
    // eslint-disable-next-line
  }, [rewardWinnersLogs, filter]);

  useEffect(() => {
    dispatch(StateActions.fetchWinnerLogs(perPage, activePage)).then(res => {
      setTotalCount(res?.data?.totalRewards);
    });
    // eslint-disable-next-line
  }, [activePage, perPage]);

  useEffect(() => {
    let date = new Date();
    let hours = date.getHours();

    let message;

    if (hours < 12) {
      message = 'Good Morning,';
    } else if (hours < 18) {
      message = 'Good Afternoon,';
    } else {
      message = 'Good Evening,';
    }
    if (message) {
      setGreeting(message);
    }
  }, []);

  const onPerPageChange = () => {
    if (num) {
      setPerPage(Number(num));
    }
  };

  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million
    } else if (num < 900) {
      return num; // if value < 1000, nothing to do
    }
  }

  return (
    <FadeIn
      className={`flex-1 flex flex-col justify-start
      h-full w-full space-y-4 overflow-auto flex-shrink-0 `}
    >
      <div className='flex flex-col space-y-6 xl:flex-row xl:space-y-0 justify-between items-start xl:space-x-6'>
        <Heading className={`text-gray-700 font-semibold text-brand_blue`} title={`${greeting} ${user?.first_name}`} size={2} />
        <Button text='Validate Reward' onClick={() => history.push('/validate-reward')} />
      </div>
      <FadeIn className='inline-flex p-6 border bg-white rounded-md divide-x h-48 mb-6'>
        <div className='px-6 flex-1 h-full flex flex-col justify-center'>
          <div className='font-medium'>Total Rewards</div>
          <div className='font-bold text-lg text-brand_blue'>{numFormatter(rewardWinnersLogs?.totalRewards)}</div>
        </div>
        <div className='px-6 flex-1 h-full flex flex-col justify-center'>
          <div className='font-medium'>Total Validations</div>
          <div className='font-bold text-lg text-brand_blue'>{numFormatter(rewardWinnersLogs?.validated)}</div>
        </div>
      </FadeIn>
      <div className='flex items-center justify-between space-x-6'>
        <Heading className={`text-gray-700`} title={`Reward Winners`} size={3} />
        <div className='flex items-center space-x-4'>
          <div onClick={() => setOpen(true)} className='flex space-x-2 items-center p-2 px-4 rounded-md border cursor-pointer'>
            <span>Filter</span>
            {filter.reward_type && <span className='font-medium text-xs'>{filter.reward_type}</span>}
            {filter.status && <span className='font-medium text-xs'>{filter.status}</span>}
            <BiSlider />
          </div>
          <div className='flex items-center space-x-2'>
            <span className='text-gray-500'>Showing</span>
            <div className='w-8 h-8'>
              <input
                type='number'
                placeholder='0'
                className='w-full h-full bg-gray-100 rounded-md right-0 outline-none text-center'
                value={num}
                onChange={e => setNum(e.target.value)}
                onBlur={onPerPageChange}
                min={0}
              />
            </div>
            <span className='text-gray-500'>Of</span>
            <span className='text-gray-700'>{totalCount}</span>
          </div>
        </div>
      </div>
      <RewardWinnerTable data={rewardWinners} variant='all-reward-log' totalCount={totalCount} perPage={perPage} onActivePageChange={setActivePage} />

      <Dialog open={open} setOpen={setOpen} variant='filter' data={filter} callBack={setFilter} />
    </FadeIn>
  );
};

export default RewardWinnerLog;
