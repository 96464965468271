import React, { useEffect, useState } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector, useDispatch } from 'react-redux';

import Heading from '../../../../components/fragments/heading';
import { BiSlider } from 'react-icons/bi'; 

import {  Route, Switch } from 'react-router-dom';

import BrandTable from '../../../../components/fragments/data-table';
import Total from '../../../../assets/Frame 313.svg';
import Open from '../../../../assets/Frame 314 (1).svg';
import Closed from '../../../../assets/Frame 314.svg';
import { StateActions } from '../../../../states/actions';

const NafdacRms = () => {
  const perPage = 6;
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(1); 
  const dispatch = useDispatch();
  const { nafdacStats } = useSelector(state => state.state);
  const { all_nafdac_reports } = useSelector(state => state.state);
  const [searchInput, setSearchInput] = useState("");
  const [selectedRowIds, setSelectedRowIds] = useState([]); 

  const handleSelectedRowIdsChange = (newSelectedRowIds) => {
    setSelectedRowIds(newSelectedRowIds); 
  };

  console.log('totalCount', totalCount)
  console.log('selectedRowIds', selectedRowIds)
  console.log('setActivePage', setActivePage)
  console.log('handleSelectedRowIdsChange', handleSelectedRowIdsChange)
  



  const handleChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };
  
  if (searchInput.length > 0) {
    all_nafdac_reports.filter((report) => {
      return report.product_name.match(searchInput);
  });
  }
 
    const filteredReports =
    searchInput === ""
      ? all_nafdac_reports
      : all_nafdac_reports.filter((item) =>
          item.product_name.toLowerCase().includes(searchInput.toLowerCase().replace(/\s+/g, ''))
      );

    const total = filteredReports.length 

      useEffect(() => {
        dispatch(StateActions.fetchAllNafdacReports(perPage, activePage)).then(res => {
          setTotalCount(res?.data?.metadata?.totalItems);
        });
      }, [activePage, dispatch]);


        useEffect(() => {
          dispatch(StateActions.fetchNafdacStats())
        }, [activePage, dispatch]);



  return ( 
    <> 
    <Switch exitBeforeEnter initial={false}> 
    <Route exact>
      <FadeIn 
        className={`flex-1 flex flex-col justify-start
       h-full w-full space-y-4 overflow-auto flex-shrink-0`}
      >
        <div className='flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4 justify-between items-center'>
            <Heading className={`text-black font-medium capitalize`} title={`report Management system`} size={3} />
        </div>
        <div>
          <p>Overview of all report that you are currently managing.</p>
        </div>
        <div className='flex flex-row space-x-6'>
          <div className="bg-white flex flex-row space-x-5" style={{ width: '424px', height: '110px', border: '1px solid #EFEFEF', borderRadius: '16px', padding: '30px' }} >
            <div>
              <img src={Total} alt="logo" /> 
            </div>
            <div>
              <p className="text-xs font-medium" style={{ color:'#979797' }} >Total Report</p>
              <p className="font-semibold text-base">{nafdacStats?.totalReport}</p>
            </div>
          </div>
          <div className="bg-white flex flex-row space-x-5" style={{ width: '424px', height: '110px', border: '1px solid #EFEFEF', borderRadius: '16px', padding: '30px' }} >
            <div>
              <img src={Open} alt="logo" /> 
            </div>
            <div>
              <p className="text-xs font-medium" style={{ color:'#979797' }} >Open Report</p>
              <p className="font-semibold text-base">{nafdacStats?.openReport}</p>
            </div>
          </div>
          <div className="bg-white flex flex-row space-x-5" style={{ width: '424px', height: '110px', border: '1px solid #EFEFEF', borderRadius: '16px', padding: '30px' }} >
            <div>
              <img src={Closed} alt="logo" /> 
            </div>
            <div>
              <p className="text-xs font-medium" style={{ color:'#979797' }} >Closed Report</p>
              <p className="font-semibold text-base">{nafdacStats?.closeReport}</p>
            </div> 
          </div>
        </div> 

        
        <div className='flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4 justify-between items-center'>
            <div className={`space-y-2 w-full`}>
                <div className={`w-full p-6 rounded-md shadow bg-white text-gray-600 flex items-center space-x-4 overflow-hidden`}>
                    <input
                      type="text"
                      className={'bg-transparent focus:outline-none w-full h-full'}
                      placeholder="Search for Name"
                      onChange={handleChange}
                      value={searchInput} 
                    />
                </div>
            </div>
            <div className='flex space-x-2 items-center p-2 px-4 rounded-md border cursor-pointer'>
                  <span>Filter</span>
                  <BiSlider />
            </div>
        </div>
        <div className='my-3' style={{ color: '#1D2433A6', fontSize: '14px', fontWeight: 400 }}>
          Total {total} report
        </div>
        <BrandTable 
        data={filteredReports}  
        variant='nafdac-r-m-s'  perPage={10}
        />
      </FadeIn>
      </Route>
    </Switch> 
      </>
  );

 
};




export default NafdacRms;
