import React, { useState, useEffect } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { Pagination } from 'rsuite';
import { useHistory, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ReactComponent as Checked } from '../../../assets/radio-checked.svg';
import { ReactComponent as Unchecked } from '../../../assets/radio-unchecked.svg';
import { MdClose } from 'react-icons/md';

import NoImage from '../../../assets/no-image.jpeg';
import SelectBox from '../select-box';
import InputBox from '../input-box/input-box';
import Button from '../button/button';
import Heading from '../heading/heading';

import { StateActions } from '../../../states/actions';

const  DataTable = ({ data, variant, perPage, totalCount, onActivePageChange, openDialog, onSelected, onSelectedRowIdsChange }) => {
  const [activePage, setActivePage] = React.useState(1);
  const [dataList, setDataList] = useState();
  const [selectedRowIds, setSelectedRowIds] = useState('');


  const toggleRowSelection = (idx) => {
    if (selectedRowIds.includes(idx)) {
      setSelectedRowIds(selectedRowIds.filter(slug => slug !== idx));
    } else {
      setSelectedRowIds([...selectedRowIds, idx]);
    }
    onSelectedRowIdsChange([...selectedRowIds, idx]);
  };

  const toggleSelectAll = () => {
    if (selectedRowIds.length === dataList.length) {
      setSelectedRowIds([]);
    } else {
      setSelectedRowIds(dataList.map(item => item.id));
    }
  };

  const toggleSelectAllChekkit = () => {
    if (selectedRowIds.length === dataList.length) {
      setSelectedRowIds([]);
    } else {
      setSelectedRowIds(dataList.map(item => item.slug));
    }
  };

  useEffect(() => {
    if (data) {
      let allData = data.filter((t) => t.username || t.product_name || t.title || t.pin || t.id);
  
      if (onActivePageChange) {
        onActivePageChange(activePage);
      } else {
        const indexOfLast = activePage * perPage;
        const indexOfFirst = indexOfLast - perPage;
        const activeData = allData.slice(indexOfFirst, indexOfLast);
        setDataList(activeData)
      }
  
      setSelectedRowIds([]); // Clear selectedRowIds here
    }
  }, [data, activePage, perPage, onActivePageChange]);

  const selectAsset = id => {
    // setAllSelected(false);
    let items = dataList.map(item => {
      if (id === item.id) {
        item.selected = true;
        if (onSelected) onSelected(item);
      } else {
        item.selected = false;
      }
      return item;
    });
    setDataList(items);
  };

  return (
    <>
      {!variant && (
        <div className='overflow-auto' style={{ color: '#1F4173' }}>
          <div className={`min-w-max`}>
            <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 border-b border-t border-gray-200`}>
              <div className={`w-40 flex-shrink-0 flex-1`}>Username</div>
              <div className={`w-64 flex-shrink-0 flex-1`}>Email</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Company Rep</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Membership Type</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Verified</div>
            </div>
          </div>
          <FadeIn className={`min-w-max flex-shrink-0 border-b border-gray-100`}>
            {dataList && dataList?.length > 0 ? (
              dataList.map((item, idx) => {
                return <Item key={idx} data={item} />;
              })
            ) : (
              <div className='text-center p-6'>No items to display</div>
            )}
          </FadeIn>
          {data && data?.length > 0 && (
            <div className='my-6'>
              <Pagination
                prev
                last
                next
                first
                ellipsis={true}
                size='sm'
                total={data.length}
                limit={perPage}
                activePage={activePage}
                onChangePage={setActivePage}
              />
            </div>
          )}
        </div>
      )}
      {variant === 'label-request' && (
        <div className='overflow-auto' style={{ color: '#1F4173' }}>
          <div className={`min-w-max`}>
            <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 border-b border-t border-gray-200`}>
              <div className={`w-40 flex-shrink-0 flex-1`}></div>
              <div className={`w-64 flex-shrink-0 flex-1`}>Address</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Country</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Label Type</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>With Taggant</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Quantity</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Date Created</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Date Updated</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Status</div>
              <div className={`w-40 flex-shrink-0 flex-1`}></div>
            </div>
          </div>
          <FadeIn className={`min-w-max flex-shrink-0 border-b border-gray-100`}>
            {dataList && dataList?.length > 0 ? (
              dataList.map((item, idx) => {
                return <ItemLabelRequest key={idx} data={item} />;
              })
            ) : (
              <div className='text-center p-6'>No items to display</div>
            )}
          </FadeIn>
          {data && data?.length > 0 && (
            <div className='my-6'>
              <Pagination
                prev
                last
                next
                first
                ellipsis={true}
                size='sm'
                total={data.length}
                limit={perPage}
                activePage={activePage}
                onChangePage={setActivePage}
              />
            </div>
          )}
        </div>
      )}
      {variant === 'pin-request' && (
        <div className='overflow-auto' style={{ color: '#1F4173' }}>
          <div className={`min-w-max`}>
            <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 border-b border-t border-gray-200`}>
              <div className={`w-64 flex-shrink-0 flex-1`}>Product ID</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Quantity</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Date Created</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Date Updated</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Status</div>
              <div className={`w-40 flex-shrink-0 flex-1`}></div>
            </div>
          </div>
          <FadeIn className={`min-w-max flex-shrink-0 border-b border-gray-100`}>
            {dataList && dataList?.length > 0 ? (
              dataList.map((item, idx) => {
                return <ItemPinRequest key={idx} data={item} />;
              })
            ) : (
              <div className='text-center p-6'>No items to display</div>
            )}
          </FadeIn>
          {data && data?.length > 0 && (
            <div className='my-6'>
              <Pagination
                prev
                last
                next
                first
                ellipsis={true}
                size='sm'
                total={data.length}
                limit={perPage}
                activePage={activePage}
                onChangePage={setActivePage}
              />
            </div>
          )}
        </div>
      )}
      {variant === 'product-reports' && (
        <div className='overflow-auto' style={{ color: '#1F4173' }}>
          <div className={`min-w-max`}>
            <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 border-b border-t border-gray-200`}>
              <div className={`w-40 flex-shrink-0 flex-1`}>Product Name</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>User Experience</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Store Address</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Product Image</div>
            </div>
          </div>
          <FadeIn className={`min-w-max flex-shrink-0 border-b border-gray-100`}>
            {dataList && dataList?.length > 0 ? (
              dataList.map((item, idx) => {
                return <ItemProductReports key={idx} data={item} />;
              })
            ) : (
              <div className='text-center p-6'>No items to display</div>
            )}
          </FadeIn>
          {data && data?.length > 0 && (
            <div className='my-6'>
              <Pagination
                prev
                last
                next
                first
                ellipsis={true}
                size='sm'
                total={data.length}
                limit={perPage}
                activePage={activePage}
                onChangePage={setActivePage}
              />
            </div>
          )}
        </div>
      )}

      {variant === 'all-batches' && (
        <div className='overflow-auto' style={{ color: '#1F4173' }}>
          <div className={`min-w-max`}>
            <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 border-b border-t border-gray-200`}>
              <div className={`w-40 flex-shrink-0 flex-1`}>Product Name</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Batch Number</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Production Date</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Expiry Date</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Featured</div>
              <div className={`w-20 flex-shrink-0`}></div>
            </div>
          </div>
          <FadeIn className={`min-w-max flex-shrink-0 border-b border-gray-100`}>
            {dataList && dataList?.length > 0 ? (
              dataList.map((item, idx) => {
                return <ItemBatches key={idx} data={item} />;
              })
            ) : (
              <div className='text-center p-6'>No items to display</div>
            )}
          </FadeIn>
          {data && data?.length > 0 && (
            <div className='my-6'>
              <Pagination
                prev
                last
                next
                first
                ellipsis={true}
                size='sm'
                total={data.length}
                limit={perPage}
                activePage={activePage}
                onChangePage={setActivePage}
              />
            </div>
          )}
        </div>
      )}

      {variant === 'all-products' && (
        <div className='overflow-auto' style={{ color: '#1F4173' }}>
          <div className={`min-w-max`}>
            <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 border-b border-t border-gray-200`}>
              <div className={`w-40 flex-shrink-0 flex-1`}>Product Image</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Product Name</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Producer Name</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Batch Number</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Category</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Description</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Age Range</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Production Date</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Expiry Date</div>
              <div className={`w-20 flex-shrink-0`}></div>
            </div>
          </div>
          <FadeIn className={`min-w-max flex-shrink-0 border-b border-gray-100`}>
            {dataList && dataList?.length > 0 ? (
              dataList.map((item, idx) => {
                return <ItemProducts key={idx} data={item} />;
              })
            ) : (
              <div className='text-center p-6'>No items to display</div>
            )}
          </FadeIn>
          {data && data?.length > 0 && (
            <div className='my-6'>
              <Pagination
                prev
                last
                next
                first
                ellipsis={true}
                size='sm'
                total={data.length}
                limit={perPage}
                activePage={activePage}
                onChangePage={setActivePage}
              />
            </div>
          )}
        </div>
      )}

      {variant === 'all-agents' && (
        <div className='overflow-auto' style={{ color: '#1F4173' }}>
          <div className={`min-w-max`}>
            <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 border-b border-t border-gray-200`}>
              <div className={`w-40 flex-shrink-0 flex-1`}>Membership Type</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>First Name</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Last Name</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Username</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Email</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Country</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Phone Number</div>
              <div className={`w-20 flex-shrink-0`}></div>
              <div className={`w-20 flex-shrink-0`}></div>
            </div>
          </div>
          <FadeIn className={`min-w-max flex-shrink-0 border-b border-gray-100`}>
            {dataList && dataList?.length > 0 ? (
              dataList.map((item, idx) => {
                return <ItemAgents key={idx} data={item} />;
              })
            ) : (
              <div className='text-center p-6'>No items to display</div>
            )}
          </FadeIn>
          {data && data?.length > 0 && (
            <div className='my-6'>
              <Pagination
                prev
                last
                next
                first
                ellipsis={true}
                size='sm'
                total={data.length}
                limit={perPage}
                activePage={activePage}
                onChangePage={setActivePage}
              />
            </div>
          )}
        </div>
      )}

      {variant === 'all-survey' && (
        <div className='overflow-auto' style={{ color: '#1F4173' }}>
          <div className={`min-w-max`}>
            <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 border-b border-t border-gray-200`}>
              <div className={`w-40 flex-shrink-0 flex-1`}>Title</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Description</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Slug</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Created</div>
              <div className={`w-20 flex-shrink-0`}></div>
            </div>
          </div>
          <FadeIn className={`min-w-max flex-shrink-0 border-b border-gray-100`}>
            {dataList && dataList?.length > 0 ? (
              dataList.map((item, idx) => {
                return <ItemSurvey key={idx} data={item} />;
              })
            ) : (
              <div className='text-center p-6'>No items to display</div>
            )}
          </FadeIn>
          {data && data?.length > 0 && (
            <div className='my-6'>
              <Pagination
                prev
                last
                next
                first
                ellipsis={true}
                size='sm'
                total={data.length}
                limit={perPage}
                activePage={activePage}
                onChangePage={setActivePage}
              />
            </div>
          )}
        </div>
      )}

      {variant === 'all-brands' && (
        <div className='overflow-auto' style={{ color: '#1F4173' }}>
          <div className={`min-w-max`}>
            <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 border-b border-t border-gray-200`}>
              <div className={`w-40 flex-shrink-0 flex-1`}>Username</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Membership Type</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Company Rep</div>
              <div className={`w-40 flex-shrink-0 flex-1`}></div>
              <div className={`w-42 flex-shrink-0 flex-1`}></div>
            </div>
          </div>
          <FadeIn className={`min-w-max flex-shrink-0 border-b border-gray-100`}>
            {dataList && dataList?.length > 0 ? (
              dataList.map((item, idx) => {
                return <ItemBrands key={idx} data={item} openDialog={openDialog} />;
              })
            ) : (
              <div className='text-center p-6'>No items to display</div>
            )}
          </FadeIn>
          {data && data?.length > 0 && (
            <div className='my-6'>
              <Pagination
                prev
                last
                next
                first
                ellipsis={true}
                size='sm'
                total={totalCount}
                limit={perPage}
                activePage={activePage}
                onChangePage={setActivePage}
              />
            </div>
          )}
        </div>
      )}

      {variant === 'brand-products' && (
        <div className='overflow-auto' style={{ color: '#1F4173' }}>
          <div className={`min-w-max`}>
            <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 border-b border-t border-gray-200`}>
              <div className={`w-40  flex-shrink-0 flex-1`}>Product Image</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Product Name</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Producer Name</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Product Description</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Product Category</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Age Range</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Expiry Date</div>
              <div className={`w-42 flex-shrink-0`}></div>
            </div>
          </div>
          <FadeIn className={`min-w-max flex-shrink-0 border-b border-gray-100`}>
            {dataList && dataList?.length > 0 ? (
              dataList.map((item, idx) => {
                return <ItemBrandProducts key={idx} data={item} openDialog={openDialog} />;
              })
            ) : (
              <div className='text-center p-6'>No items to display</div>
            )}
          </FadeIn>
          {data && data?.length > 0 && (
            <div className='my-6'>
              <Pagination
                prev
                last
                next
                first
                ellipsis={true}
                size='sm'
                total={totalCount}
                limit={perPage}
                activePage={activePage}
                onChangePage={setActivePage}
              />
            </div>
          )}
        </div>
      )}

      {variant === 'all-reward-log' && (
        <div className='overflow-auto' style={{ color: '#1F4173' }}>
          <div className={`min-w-max`}>
            <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 border-b border-t border-gray-200`}>
              <Route exact path='/validate-reward/reward-found'>
                <div className={`w-6`}></div>
              </Route>
              <div className={`w-40 flex-shrink-0 flex-1`}>Phone Number</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Pin</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Reward Type</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Status</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Date Won</div>
              <div className={`w-40 flex-shrink-0 flex-1`}>Time</div>
            </div>
          </div>
          <FadeIn className={`min-w-max flex-shrink-0 border-b border-gray-100`}>
            {dataList && dataList?.length > 0 ? (
              dataList.map((item, idx) => {
                return <ItemRewardLog key={idx} data={item} selectAsset={selectAsset} />;
              })
            ) : (
              <div className='text-center p-6'>No items to display</div>
            )}
          </FadeIn>
          {data && data?.length > 0 && (
            <div className='my-6'>
              <Pagination
                prev
                last
                next
                first
                ellipsis={true}
                size='sm'
                total={totalCount}
                limit={perPage}
                activePage={activePage}
                onChangePage={setActivePage}
              />
            </div>
          )}
        </div>
      )}

      {variant === 'chekkit-r-m-s' && (
              <div className='overflow-auto' style={{ color: '#1F4173' }}>
                <div className={`min-w-max`}>
                  <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 border-b border-t border-gray-200`}>
                  <div className={`w-4`}> <input type="checkbox" onChange={toggleSelectAllChekkit} checked={selectedRowIds?.length === dataList?.length} /> </div>
                    <div className={`w-40 flex-shrink-0 flex-1`}>Date Reported</div>
                    <div className={`w-40 flex-shrink-0 flex-1`}>Product Name</div>
                    <div className={`w-40 flex-shrink-0 flex-1`}>Product Experience</div>
                    <div className={`w-40 flex-shrink-0 flex-1`}>Product Store</div>
                    <div className={`w-40 flex-shrink-0 tex flex-1`}>Status</div>
                    <div className={`w-40 flex-shrink-0 flex-1`}>Product Image</div>
                    <div className={`w-40 flex-shrink-0 flex-1`}>Action</div>
                  </div>
                </div>
                <FadeIn className={`min-w-max flex-shrink-0 border-b border-gray-100`}>
                  {dataList && dataList?.length > 0 ? (
                    dataList.map((item, idx) => {
                      return <ItemChekkitRms key={idx} data={item} openDialog={openDialog} selectedRowIds={selectedRowIds}  toggleSelectAll={toggleSelectAll} toggleRowSelection={toggleRowSelection}  />;
                    })
                  ) : (
                    <div className='text-center p-6'>No items to display</div>
                  )}
                </FadeIn>
                {data && data?.length > 0 && (
                  <div className='my-6'>
                    <Pagination
                      prev
                      last
                      next
                      first
                      ellipsis={true}
                      size='sm'
                      total={totalCount}
                      limit={perPage}
                      activePage={activePage}
                      onChangePage={setActivePage}
                    />
                  </div>
                )}
              </div>
            )}

      {variant === 'nafdac-r-m-s' && (
                    <div className='overflow-auto' style={{ color: '#1F4173' }}>
                      <div className={`min-w-max`}>
                        <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 border-b border-t border-gray-200`}>
                          {/* <div className={`w-4`}> <input type="checkbox" onChange={toggleSelectAll} checked={selectedRowIds?.length === dataList?.length} /> </div> */}
                          <div className={`w-40 flex-shrink-0 flex-1`}>Date Submitted</div>
                          <div className={`w-40 flex-shrink-0 flex-1`}>Product Name</div>
                          <div className={`w-40 flex-shrink-0 flex-1`}>Product Store</div>
                          <div className={`w-40 flex-shrink-0 flex-1`}>Product Image</div>
                          <div className={`w-40 flex-shrink-0 flex-1 text-center`}>Status</div>
                          <div className={`w-40 flex-shrink-0 flex-1 text-center`}>Action</div>
                        </div>
                      </div>
                      <FadeIn className={`min-w-max flex-shrink-0 border-b border-gray-100`}>
                        {dataList && dataList?.length > 0 ? (
                          dataList.map((item, idx) => {
                            return <ItemNafdacRms key={idx}  data={item} openDialog={openDialog} selectedRowIds={selectedRowIds}  toggleSelectAll={toggleSelectAll} toggleRowSelection={toggleRowSelection} />;
                          })
                        ) : (
                          <div className='text-center p-6'>No items to display</div>
                        )}
                      </FadeIn>
                      {data && data?.length > 0 && (
                        <div className='my-6'>
                          <Pagination
                            prev
                            last
                            next
                            first
                            ellipsis={true}
                            size='sm'
                            total={totalCount}
                            limit={perPage}
                            activePage={activePage}
                            onChangePage={setActivePage}
                          />
                        </div>
                      )}
                    </div>
                  )}
    </>
  );
};

const Item = ({ data }) => {
  const [item, setItem] = useState();

  useEffect(() => {
    if (data) {
      setItem(data);
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <>
      <div className={`min-w-max flex-shrink-0 border-b border-gray-100`}>
        <div className={`w-full flex flex-shrink-0 items-center justify-start space-x-6 py-6`}>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.username}</div>
          <div className={`w-64 flex-shrink-0 overflow-auto flex-1`}>{item?.email}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.company_rep}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.membership_type}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1`}>
            <div className='bg-blue-50 inline-flex py-1 px-6 rounded-full items-center space-x-2'>
              <span className={`inline-block ${item?.verified ? `bg-green-500` : `bg-red-500`} w-2 h-2 rounded-full`}></span>
              <span>{item?.verified ? 'Verified' : 'Not verified'}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ItemLabelRequest = ({ data }) => {
  const [item, setItem] = useState();
  const [pinStatus, setPinStatus] = useState('');
  const dispatch = useDispatch();
  const [showAssignLabel, setShowAssignLabel] = useState(false);
  const [labelData, setLabelData] = useState([
    {
      boxNumber: '',
      startIndex: '',
      endIndex: '',
    },
  ]);

  useEffect(() => {
    if (!showAssignLabel) {
      setLabelData([
        {
          boxNumber: '',
          startIndex: '',
          endIndex: '',
        },
      ]);
    }
    // eslint-disable-next-line
  }, [showAssignLabel]);

  useEffect(() => {
    if (item) {
      setPinStatus(item.status);
    }
    // eslint-disable-next-line
  }, [item]);

  useEffect(() => {
    if (data) {
      setItem(data);
    }
    // eslint-disable-next-line
  }, [data]);

  const handlePinStatusChange = e => {
    e?.preventDefault();
    e?.stopPropagation();
    dispatch(StateActions.updateLabelRequestStatus(item.id, { status: e.target.value }));
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setLabelData(dx =>
      dx.map((d, idx) => {
        if (idx === index) {
          d[name] = value;
        }
        return d;
      }),
    );
  };

  const handleSubmit = currentData => {
    let submitData = { requestId: currentData?.id, userId: currentData?.userId, labels: labelData };
    console.log('submitData', submitData);
    dispatch(StateActions.assignLabelRequest(submitData)).then(res => {
      if (res) {
        setShowAssignLabel(false);
      }
    });
  };

  return (
    <>
      <div className={`min-w-max flex-shrink-0 border-b border-gray-100`}>
        <div className={`w-full flex flex-shrink-0 items-center justify-start space-x-6 py-6`}>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1`}>
            {item?.status === 'processing' && (
              <Button
                onClick={e => {
                  setShowAssignLabel(!showAssignLabel);
                }}
                text={'Assign Label'}
                className=''
                size={2}
              />
            )}
          </div>
          <div className={`w-64 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.address}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1`}>{item?.country}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.labelType}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.withTaggant}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.quantity}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{new Date(item?.created_at).toDateString()}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{new Date(item?.updated_at).toDateString()}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1`}>
            <div className='bg-blue-50 inline-flex py-1 px-6 rounded-full items-center space-x-2'>
              <span
                className={`inline-block ${
                  item?.status === 'completed' ? `bg-green-500` : item?.status === 'processing' ? 'bg-yellow-500' : `bg-gray-500`
                } w-2 h-2 rounded-full`}
              ></span>
              <span>{item?.status}</span>
            </div>
          </div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>
            <SelectBox
              value={pinStatus}
              onValueChange={e => {
                handlePinStatusChange(e);
              }}
              options={['pending', 'processing', 'completed']}
              variant={2}
            />
          </div>
        </div>
      </div>
      {showAssignLabel && (
        <div
          style={{ height: '600px', width: '800px' }}
          className={`flex-1 flex flex-col justify-start
       h-full w-full space-y-4 overflow-auto flex-shrink-0 p-4 `}
        >
          <div className='flex items-center justify-between space-x-4'>
            <Heading className={`text-brand_blue mb-6`} title={`Assign Label${labelData.length > 1 ? 's' : ''}`} size={3} />
            <button onClick={() => setShowAssignLabel(false)} className='text-brand_blue font-semibold'>
              Close
            </button>
          </div>
          <FadeIn className='space-y-12'>
            {labelData.map((d, idx) => {
              return (
                <FadeIn key={idx} className='space-y-6 mt-4'>
                  <div className='flex justify-between items-center space-x-6'>
                    <div className='-mb-3 text-white bg-brand_blue w-4 h-4 font-semibold flex justify-center items-center p-3 rounded-md'>{idx + 1}</div>
                    {idx !== 0 && (
                      <MdClose
                        onClick={() => {
                          setLabelData(dx => dx.filter((d, index) => index !== idx));
                        }}
                        size={20}
                      />
                    )}
                  </div>
                  <InputBox
                    className={'w-full flex-1'}
                    value={d.boxNumber}
                    onValueChange={e => handleInputChange(e, idx)}
                    name='boxNumber'
                    variant={2}
                    type='number'
                    label='Box Number'
                    placeholder='Box Number'
                  />
                  <div className='flex justify-between items-center space-x-4'>
                    <InputBox
                      className={'w-full flex-1'}
                      value={d.startIndex}
                      onValueChange={e => handleInputChange(e, idx)}
                      name='startIndex'
                      variant={2}
                      type='number'
                      label='Start Index'
                      placeholder='Start Index'
                    />
                    <div className='relative top-4'>-</div>
                    <InputBox
                      className={'w-full flex-1'}
                      value={d.endIndex}
                      onValueChange={e => handleInputChange(e, idx)}
                      name='endIndex'
                      variant={2}
                      type='number'
                      label='End Index'
                      placeholder='End Index'
                    />
                  </div>
                </FadeIn>
              );
            })}
          </FadeIn>

          <div className='flex justify-start'>
            <button onClick={() => setLabelData(dx => [...dx, { boxNumber: '', startIndex: '', endIndex: '' }])} className='text-brand_blue font-semibold'>
              Add More
            </button>
          </div>
          <Button
            className={`${
              !labelData.every(x => x.boxNumber) || !labelData.every(x => x.startIndex) || !labelData.every(x => x.endIndex)
                ? `pointer-events-none opacity-50`
                : ''
            } w-full relative top-8`}
            text={`Assign Label${labelData.length > 1 ? 's' : ''}`}
            onClick={() => handleSubmit(item)}
          />
        </div>
      )}
    </>
  );
};

const ItemPinRequest = ({ data }) => {
  const [item, setItem] = useState();
  const [pinStatus, setPinStatus] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (item) {
      setPinStatus(item.status);
    }
    // eslint-disable-next-line
  }, [item]);

  useEffect(() => {
    if (data) {
      setItem(data);
    }
    // eslint-disable-next-line
  }, [data]);

  const handlePinStatusChange = e => {
    e?.preventDefault();
    e?.stopPropagation();
    dispatch(StateActions.updatePinRequestStatus(item.user_id, item.id, e.target.value));
  };

  return (
    <>
      <div className={`min-w-max flex-shrink-0 border-b border-gray-100`}>
        <div className={`w-full flex flex-shrink-0 items-center justify-start space-x-6 py-6`}>
          <div className={`w-64 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.productId}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.quantity}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{new Date(item?.created_at).toDateString()}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{new Date(item?.updated_at).toDateString()}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1`}>
            <div className='bg-blue-50 inline-flex py-1 px-6 rounded-full items-center space-x-2'>
              <span
                className={`inline-block ${
                  item?.status === 'completed'
                    ? `bg-green-500`
                    : item?.status === 'processing'
                    ? 'bg-yellow-500'
                    : item?.status === 'declined'
                    ? 'bg-red-500'
                    : `bg-gray-500`
                } w-2 h-2 rounded-full`}
              ></span>
              <span>{item?.status}</span>
            </div>
          </div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>
            <SelectBox
              value={pinStatus}
              onValueChange={e => {
                handlePinStatusChange(e);
              }}
              options={['requested', 'processing', 'declined', 'completed']}
              variant={2}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const ItemProductReports = ({ data }) => {
  const [item, setItem] = useState();
  const [viewImage, setViewImage] = useState(false);

  useEffect(() => {
    if (data) {
      setItem(data);
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <>
      <div className={`min-w-max flex-shrink-0 border-b border-gray-100`}>
        <div className={`w-full flex flex-shrink-0 items-center justify-start space-x-6 py-6`}>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.product_name}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1`}>{item?.product_user_exprnce}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.product_store_address}</div>
          <div
            onClick={() => setViewImage(!viewImage)}
            className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize text-brand_blue cursor-pointer underline`}
          >
            {viewImage ? 'Hide Product Image' : 'View Product Image'}
          </div>
        </div>
      </div>
      {viewImage && (
        <FadeIn className='py-6'>
          <div className='max-w-md mx-auto bg-gray-100'>
            <img className='w-full h-full object-cover' src={item?.product_image ? item?.product_image : NoImage} alt='loading...' />
          </div>
        </FadeIn>
      )}
    </>
  );
};

const ItemBatches = ({ data }) => {
  const [item, setItem] = useState();
  const history = useHistory();

  useEffect(() => {
    if (data) {
      setItem(data);
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <>
      <div className={`min-w-max flex-shrink-0 border-b border-gray-100`}>
        <div className={`w-full flex flex-shrink-0 items-center justify-start space-x-6 py-6`}>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.product_name}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1`}>{item?.batch_num}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{new Date(item?.production_date).toDateString()}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{new Date(item?.expiry_date).toDateString()}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.featured + ''}</div>
          <div
            onClick={() => history.push(`/products/edit-batch/${item?.id}`)}
            className={`w-20 flex-shrink-0 overflow-auto capitalize text-brand_blue cursor-pointer underline`}
          >
            Edit
          </div>
        </div>
      </div>
    </>
  );
};

const ItemProducts = ({ data }) => {
  const [item, setItem] = useState();
  const history = useHistory();

  useEffect(() => {
    if (data) {
      setItem(data);
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <>
      <div className={`min-w-max flex-shrink-0 border-b border-gray-100`}>
        <div className={`w-full flex flex-shrink-0 items-center justify-start space-x-6 py-6`}>
          <div className={`w-40 h-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>
            <img className='w-full h-full object-cover' src={item?.product_image ? item?.product_image : NoImage} alt='loading...' />
          </div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.product_name}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.producer_name}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1`}>{item?.batch_num}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1`}>{item?.product_category}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1`}>{item?.product_description}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1`}>{item?.age_range}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{new Date(item?.production_date).toDateString()}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{new Date(item?.expiry_date).toDateString()}</div>
          <div
            onClick={() => history.push(`/products/edit-product/${item?.id}`)}
            className={`w-20 flex-shrink-0 overflow-auto capitalize text-brand_blue cursor-pointer underline`}
          >
            Edit
          </div>
        </div>
      </div>
    </>
  );
};

const ItemAgents = ({ data }) => {
  const [item, setItem] = useState();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      setItem(data);
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <>
      <div className={`min-w-max flex-shrink-0 border-b border-gray-100`}>
        <div className={`w-full flex flex-shrink-0 items-center justify-start space-x-6 py-6`}>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.membership_type.replaceAll('-', ' ')}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.first_name}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.last_name}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1`}>{item?.username}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1`}>{item?.email}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1`}>{item?.country}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1`}>{item?.call_code + item?.phone_number}</div>
          <div
            onClick={() => history.push(`/agents/edit-agent/${item?.id}`)}
            className={`w-20 flex-shrink-0 overflow-auto capitalize text-brand_blue cursor-pointer underline`}
          >
            Edit
          </div>
          <div
            onClick={() => {
              dispatch(StateActions.removeAgent(item?.id));
            }}
            className={`w-20 flex-shrink-0 overflow-auto capitalize text-red-500 cursor-pointer underline`}
          >
            Delete
          </div>
        </div>
      </div>
    </>
  );
};

const ItemSurvey = ({ data }) => {
  const [item, setItem] = useState();
  const history = useHistory();

  useEffect(() => {
    if (data) {
      setItem(data);
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <>
      <div className={`min-w-max flex-shrink-0 border-b border-gray-100`}>
        <div className={`w-full flex flex-shrink-0 items-center justify-start space-x-6 py-6`}>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.title}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.content}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1`}>{item?.slug}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1`}>{new Date(item?.created_at)?.toDateString()}</div>
          <div
            onClick={() => history.push(`/survey/edit-survey/step-one/${item?.id}`)}
            className={`w-20 flex-shrink-0 overflow-auto capitalize text-brand_blue cursor-pointer underline`}
          >
            Edit
          </div>
        </div>
      </div>
    </>
  );
};

const ItemBrands = ({ data, openDialog }) => {
  const [item, setItem] = useState();
  const history = useHistory();

  useEffect(() => {
    if (data) {
      setItem(data);
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <>
      <div className={`min-w-max flex-shrink-0 border-b border-gray-100`}>
        <div className={`w-full flex flex-shrink-0 items-center justify-start space-x-6 py-6`}>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.username}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.membership_type}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1`}>{item?.company_rep}</div>
          <div
            onClick={() => history.push(`/brands/brand-products/${item?.id}`)}
            className={`w-40 flex-shrink-0 overflow-auto capitalize text-brand_blue cursor-pointer underline flex-1`}
          >
            View Products
          </div>
          <div onClick={() => openDialog(true, item)} className={`w-42 flex-shrink-0 overflow-auto capitalize text-blue-500 cursor-pointer underline flex-1`}>
            Message Authenticators
          </div>
        </div>
      </div>
    </>
  );
};

const ItemBrandProducts = ({ data, openDialog }) => {
  const [item, setItem] = useState();

  useEffect(() => {
    if (data) {
      setItem(data);
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <>
      <div className={`min-w-max flex-shrink-0 border-b border-gray-100`}>
        <div className={`w-full flex flex-shrink-0 items-center justify-start space-x-6 py-6`}>
          <div className={`w-40 h-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>
            <img className='w-full h-full object-cover' src={item?.product_image ? item?.product_image : NoImage} alt='loading...' />
          </div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.product_name}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.producer_name}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.product_description}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.product_category}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.age_range}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1`}>{new Date(item?.expiry_date).toDateString()}</div>
          <div onClick={() => openDialog(true, item)} className={`w-42 flex-shrink-0 overflow-auto capitalize text-blue-500 cursor-pointer underline`}>
            Message Authenticators
          </div>
        </div>
      </div>
    </>
  );
};

const ItemRewardLog = ({ data, selectAsset }) => {
  const [item, setItem] = useState();

  useEffect(() => {
    if (data) {
      setItem(data);
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <>
      <div className={`min-w-max flex-shrink-0 border-b border-gray-100`}>
        <div className={`w-full flex flex-shrink-0 items-center justify-start space-x-6 py-6`}>
          <Route exact path='/validate-reward/reward-found'>
            <div onClick={() => selectAsset(item?.id)} className={`w-6 cursor-pointer`}>
              {item?.selected ? <Checked /> : <Unchecked />}
            </div>
          </Route>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.phone_number}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.pin}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1`}>{item?.reward_type}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1`}>{item?.status === 1 ? 'Collected' : 'Uncollected'}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{new Date(item?.created_at).toDateString()}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{new Date(item?.created_at).toLocaleTimeString()}</div>
        </div>
      </div>
    </>
  );
};

const ItemChekkitRms = ({ data, query, toggleRowSelection, selectedRowIds, idx }) => {
  const [item, setItem] = useState();
  const dispatch = useDispatch();
  const isBulkActionsActive = selectedRowIds.length > 0;
  console.log('isBulkActionsActive', isBulkActionsActive)


  useEffect(() => {
    if (data) {
      setItem(data);
    }
    // eslint-disable-next-line
  }, [data]);

  const handleRowCheckboxChange = () => {
    toggleRowSelection(item.slug);
  };


  const bulkData = {
    slug: selectedRowIds[0], 
    comment: '',
  };

  const JSONBulkData = JSON.stringify(bulkData);

  

  const handleBulkApprove = () => {
    dispatch(StateActions.bulkApprove(JSONBulkData))
  };

  const handleBulkReject = () => {
    dispatch(StateActions.bulkReject(JSONBulkData))
  };


  if (!query || item?.product_name.toLowerCase().includes(query.toLowerCase())) {
  return (
    <>
      <div key={idx} className={`min-w-max flex-shrink-0 border-b border-gray-100`}>
        <div className={`w-full flex flex-shrink-0 items-center justify-start space-x-6 py-6`}>
        <div className={`w-4`}> 
              <input
                type="checkbox"
                onChange={handleRowCheckboxChange}
                checked={selectedRowIds.includes(item?.slug)}
                /> </div>          
            <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{new Date(item?.created_at).toDateString()}</div>
            <div className={`w-40 flex-shrink-0 overflow-auto flex-1`}>{item?.product_name}</div>
            <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.product_user_exprnce}</div>
            <div className={`w-40 flex-shrink-0 overflow-auto flex-1 text-center capitalize`}>{item?.product_store_address}</div>
            <div className={`w-40 flex-shrink-0 overflow-auto flex-1  uppercase `}>
                <span className={`${item?.report_status === 'PENDING' ? `text-yellow-400` : item?.report_status === 'RESOLVED' ?  `text-green-500`: `text-green-500`} w-2 h-2 rounded-3xl p-4 bg-gray-100 `}>{item?.report_status}</span>
            </div>
            <div className={`w-40 h-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>
              <img className=' object-cover' src={item?.product_image ? item?.product_image : NoImage} alt='loading...' />
            </div>
            <div className={`w-40 flex-shrink-0 overflow-auto flex-1 inline-flex py-1 px-6  space-x-2 `}>
              <span  onClick={handleBulkApprove} className={`inline-block} text-green-500 cursor-pointer hover:underline `}>Approve </span> <span className={`space-x-2 text-gray-500 `}>|</span> <span onClick={handleBulkReject} className={`text-red-500 cursor-pointer hover:underline`}> Reject</span>
            </div>
        </div>
      </div>
      </>
  );
    } else {
      return null; 
    }
};

const ItemNafdacRms = ({ data, query, toggleRowSelection, selectedRowIds, idx }) => {
  const [item, setItem] = useState();
  const history = useHistory();
  const dispatch = useDispatch();

  

  const handleReportChange = (newStatus, slug) => {
    const updateData = {
      slug: String(item.slug),
      status: newStatus
    };

    dispatch(StateActions.updateNafdacReportsStatus(updateData))

  };
  

  useEffect(() => {
    if (data) {
      setItem(data);
    }
    // eslint-disable-next-linfe
  }, [data]); 

  const handleRowCheckboxChange = () => {
    toggleRowSelection(item.id);
  };

  console.log('handleRowCheckboxChange', handleRowCheckboxChange)

  if (!query || item?.product_name.toLowerCase().includes(query.toLowerCase())) {
  return (
    <>
      <div key={idx} className={`min-w-max flex-shrink-0 border-b border-gray-100`}>
        <div className={`w-full flex flex-shrink-0 items-center justify-start space-x-6 py-6`}>
        {/* <div className={`w-4`}> 
              <input
                type="checkbox"
                onChange={handleRowCheckboxChange}
                checked={selectedRowIds.includes(item?.id)}
                /> </div> */}
        <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{new Date(item?.created_at).toDateString()}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1`}>{item?.product_name}</div>
          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>{item?.product_store_name}</div>
          <div className={`w-40 h-40 flex-shrink-0 overflow-auto flex-1 capitalize`}>
            <img className=' object-cover' src={item?.product_image ? item?.product_image : NoImage} alt='loading...' />
          </div>

          <div className={`w-40 flex-shrink-0 overflow-auto flex-1 capitalize p-2`}>
            <SelectBox
              value={item?.report_status}
              onValueChange={event => {
                const newStatus = event.target.value;
                handleReportChange(newStatus, item.slug);
              }}
              options={['PENDING', 'ONGOING', 'RESOLVED']}
              variant={7}
     
       
            />
          </div>
         
          <div 
          onClick={() => history.push(`/rms/rms-report/${item?.id}`)} 
          className={`w-40 flex-shrink-0 overflow-auto capitalize text-blue-900 cursor-pointer underline text-center`}>
            View Details
          </div>        
        </div>
      </div>
    </>
  );
    } else {
      return null; 
    }
};

export default DataTable;