import React from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector } from 'react-redux';

import Heading from '../../../../components/fragments/heading';
import ProductReportTable from '../../../../components/fragments/data-table/data-table';

const Reports = () => {
  const { productReports } = useSelector(state => state.state);
  return (
    <FadeIn
      className={`flex-1 flex flex-col justify-start
     h-full w-full space-y-4 overflow-auto flex-shrink-0`}
    >
      <Heading className={`text-gray-700`} title={`Reports`} size={3} />
      <ProductReportTable data={productReports} variant='product-reports' perPage={6} />
    </FadeIn>
  );
};

export default Reports;
