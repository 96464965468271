import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import appRoutes from '../../routes';
import SignIn from './sign-in';

const routes = [{ path: ['/', '/signin'], component: SignIn }];

const Auth = () => {
  const location = useLocation();
  const history = useHistory();
  const user = useSelector(state => state.user);

  useEffect(() => {
    if (user?.access_token) {
      if (user?.membership_type === 'agent') {
        let r = appRoutes.filter(d => d.name === 'Pins');
        history.push(r[0].href);
      } else if (user?.membership_type === 'field-agent') {
        let r = appRoutes.filter(d => d.name === 'Validate Reward');
        history.push(r[0].href);
      } else {
        history.push(appRoutes[0].href);
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className='min-h-screen flex overflow-hidden bg'>
      <Switch location={location}>
        {routes.map((route, i) => {
          return (
            <Route exact key={i} path={route.path}>
              <route.component />
            </Route>
          );
        })}
      </Switch>
    </div>
  );
};

export default Auth;
