import React, { useEffect } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector, useDispatch } from 'react-redux';

import Heading from '../../../../components/fragments/heading';
import SelectBox from '../../../../components/fragments/select-box';

import SurveyTable from '../../../../components/fragments/data-table';

import { StateActions } from '../../../../states/actions';

const AllSurvey = () => {
  const dispatch = useDispatch();
  const { clients, selectedClient, currentClientSurveys } = useSelector(state => state.state);

  useEffect(() => {
    if (selectedClient) {
      dispatch(StateActions.fetchClientSurveys(selectedClient.id));
    }
    // eslint-disable-next-line
  }, [selectedClient]);
  return (
    <FadeIn
      className={`flex-1 flex flex-col justify-start
     h-full w-full space-y-4 overflow-auto flex-shrink-0`}
    >
      <Heading className={`text-gray-700`} title={`All Survey`} size={3} />

      <FadeIn className='space-y-6 mt-4'>
        <SelectBox
          onValueChange={e => dispatch(StateActions.setSelectedClient(e.target.value))}
          options={['Select option', ...clients.map(d => d.company_rep + ' ( ' + d.username + ' )')]}
          variant={2}
          value={selectedClient ? selectedClient.company_rep + ' ( ' + selectedClient.username + ' )' : ''}
          label='Client'
        />
        <SurveyTable data={currentClientSurveys} variant='all-survey' perPage={6} />
      </FadeIn>
    </FadeIn>
  );
};

export default AllSurvey;
