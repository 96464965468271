import React, { useState } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Heading from '../../../../components/fragments/heading';
import InputBox from '../../../../components/fragments/input-box';
import SelectBox from '../../../../components/fragments/select-box/select-box';
import Button from '../../../../components/fragments/button';

import { StateActions } from '../../../../states/actions';

const formData = {
  full_name: '',
  first_name: 'first_name',
  last_name: 'first_name',
  username: '',
  email: '',
  location: '',
  membership_type: '',
  phone_number: new Date().getTime().toString().slice(0, 11),
  password: '',
};

const AddAgent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [data, setData] = useState(formData);

  const handleInputChange = e => {
    const { name, value } = e.target;
    if (name === 'full_name') {
      let v = value.replaceAll(' ', '-').toLowerCase();
      setData({ ...data, [name]: value, email: `${v}@yopmail.com`, username: v, first_name: v, last_name: v });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const handleSubmit = () => {
    dispatch(StateActions.addAgent(data)).then(_ => {
      setData(formData);
      history.push('/agents/all-agents');
    });
  };
  return (
    <FadeIn
      className={`flex-1 flex flex-col justify-start
     h-full w-full space-y-4 overflow-auto flex-shrink-0`}
    >
      <Heading className={`text-gray-700`} title={`Add Agent`} size={3} />

      <FadeIn className='space-y-6 mt-4'>
        <InputBox
          className={'w-full flex-1'}
          value={data.full_name}
          onValueChange={handleInputChange}
          name='full_name'
          variant={2}
          label='Full Name'
          placeholder='Full Name'
        />

        <InputBox
          className={'w-full flex-1'}
          value={data.first_name}
          onValueChange={handleInputChange}
          name='first_name'
          variant={2}
          label='First Name'
          placeholder='First Name'
        />

        <InputBox
          className={'w-full flex-1'}
          value={data.last_name}
          onValueChange={handleInputChange}
          name='last_name'
          variant={2}
          label='Last Name'
          placeholder='Last Name'
          readOnly={true}
        />

        <InputBox
          className={'w-full flex-1'}
          value={data.username}
          onValueChange={handleInputChange}
          name='username'
          variant={2}
          label='Username (Read Only)'
          placeholder='Username'
          readOnly={true}
        />

        <InputBox
          className={'w-full flex-1'}
          value={data.email}
          onValueChange={handleInputChange}
          name='email'
          type={'email'}
          variant={2}
          label='Email (Read Only)'
          placeholder='Email'
          readOnly={true}
        />

        <InputBox
          className={'w-full flex-1'}
          value={data.phone_number}
          onValueChange={handleInputChange}
          name='phone_number'
          variant={2}
          label='Phone Number (Read Only)'
          placeholder='Phone Number'
          readOnly={true}
        />

        <InputBox
          className={'w-full flex-1'}
          value={data.location}
          onValueChange={handleInputChange}
          name='location'
          variant={2}
          label='Location'
          placeholder='Location'
        />

        <SelectBox
          className={'w-full flex-1'}
          value={data.membership_type}
          options={['Select option', 'agent', 'field-agent']}
          onValueChange={handleInputChange}
          name='membership_type'
          variant={2}
          label='Membership Type'
        />

        <InputBox
          className={'w-full flex-1'}
          value={data.password}
          onValueChange={handleInputChange}
          name='password'
          type={'password'}
          variant={2}
          label='Password'
          placeholder='Password'
        />

        <Button
          className={`${
            !data.first_name || !data.last_name || !data.username || !data.email || !data.phone_number || !data.password || !data.full_name || !data.location
              ? `pointer-events-none opacity-50`
              : ''
          } w-full relative top-8`}
          text={'Add Agent'}
          onClick={handleSubmit}
        />
      </FadeIn>
    </FadeIn>
  );
};

export default AddAgent;
